import * as Sentry from '@sentry/browser';
import Utils from 'core-helpers/utils.js';

// Private vars
const filename = 'exception-service.js';

/**
 * Indicates whether or not Sentry has been initialised first.
 * @type {Boolean}
 * @private
 */
let initialised = false;

/**
 * Returns a Sentry Integration instance
 * Doc: https://docs.sentry.io/platforms/javascript/#sdk-integrations
 * @param {String} integrationName 
 * @param {Object} options
 * @public
 */
const getSentryIntegrationInstance = (integrationName, options = {}) => {
    return new Sentry.Integrations[integrationName](options);
};

/**
 * Returns global config for Sentry
 * @param {String} key
 * @param {Boolean} enableLocal
 * @param {Object} options
 * @return {Object}
 * @private
 */
const getConfig = (key, enableLocal, options) => {
    const opts = Utils.deepClone(options);
    const config = {
        dsn: key,
        allowUrls: [/cdn\.busuu\.com/], // production
        ...opts
    };
    if (enableLocal) {
        delete config.allowUrls;
    }
    return config;
};

/**
 * @private
 */
const isSentryInitialised = () => {
    if (!initialised) {
        Utils.log(filename, 'Sentry is not initialised.');
    }
    return initialised;
};

/**
 * Write down into a textarea all the logs that happened
 * on the page. This is for QA purpose.
 * @param {String} value
 * @private
 */
const setHTMLtoAppConsole = (value) => {
    const consoleElement = Utils.getById('console-output');
    if (consoleElement) {
        consoleElement.value = consoleElement.value + JSON.stringify(value) + '\n\n';
    }
};

/**
 * Returns the user info config for Sentry
 * @return {Object} userConfig
 * @private
 */
const getUserContext = (user) => {
    return {
        id: user.uid,
        username: user.name,
        email: user.email,
        countryCode: user.country_code
    };
};

/**
 * We only manually capture exceptions as not to overload
 * Sentry with all kinds of errors and go over our allowance.
 * @param  {Error}
 * @param  {Object} additional data
 * @private
 */
const captureException = (error, data) => {
    if (!isSentryInitialised()) {
        return false;
    }
    Sentry.withScope(function(scope) {
        if (Utils.isObject(data)) {
            scope.setExtras(data);
        }
        Sentry.captureException(error);
    });
};

/**
 * Logs an exception in the console
 * Also optionally sends an error to Sentry (this is enabled by default
 * for errors)
 *
 * Can be used as such:
 *
 * ExceptionService.handle('error', {
 *     filename: 'my.component.js',
 *     message: 'myFunction() - Something went wrong',
 *     data: {...},
 *     sendToSentry: false
 * });
 *
 * @param  {String} type - 'warning' or 'error'
 * @param  {Object} parameters
 * @param  {String} parameters.filename
 * @param  {String} parameters.message
 * @param  {Error|Object} parameters.data? - Error instance or additional object data
 * @param  {Boolean} parameters.sendToSentry?
 * @public
 */
const handle = (type = 'warning', {
    filename = 'Filename unknown',
    message = 'An exception has occurred.',
    data = null,
    sendToSentry = Boolean(type === 'error')
} = {}) => {

    const getExtras = (error) => {
        const obj = {
            filename,
            message,
            errorMessage: error.message || 'Unknown error'
        };
        if (Utils.isObject(data)) {
            obj.extras = { ...data };
        }
        return obj;
    };

    const error = data instanceof Error ? data : new Error(message);
    const extras = getExtras(error);

    // Log to Sentry
    if (sendToSentry) {
        captureException(error, extras);
    }

    // Busuu logs
    Utils.log(filename, message, data);
    setHTMLtoAppConsole(extras.errorMessage);

};

/**
 * Add additional user context to Sentry
 * tracking.
 * @param {Object} user
 * @public
 */
const identifyUser = (user) => {
    if (!user) {
        throw new Error(`[File: ${filename}] User is missing.`);
    }
    if (isSentryInitialised()) {
        const userContext = getUserContext(user);
        Sentry.setUser(userContext);
    }
};

/**
 * Initialise Sentry
 * @param {Object} params
 * @param {String} params.key - Sentry key
 * @param {Boolean} params.enableLocal - Enable Sentry in dev mode
 * @param {Boolean} params.sentryOptions - https://docs.sentry.io/error-reporting/configuration/?platform=browsernpm 
 */
const init = ({ key = null, enableLocal = false, sentryOptions = {} } = {}) => {
    if (!key) {
        throw new Error(`[File: ${filename}] Key is missing.`);
    }
    const config = getConfig(key, enableLocal, sentryOptions);
    Sentry.init(config);
    initialised = true;
};

export default {
    handle,
    identifyUser,
    getSentryIntegrationInstance,
    init
};
