export const PARTNER_XING = 'xing';
export const PARTNER_WINDTRE = 'windtre';

export const PARTNER_DATA = {
    [PARTNER_XING]: {
        planType: 'ft_partnership_xing',
        name: 'XING',
        avatar: `${process.env.staticRoot}/img/design/referral/partners/xing-avatar.jpg`
    },
    [PARTNER_WINDTRE]: {
        planType: 'ft_partnership_windtre',
        name: 'Windtre',
        avatar: `${process.env.staticRoot}/img/design/referral/partners/windtre-avatar.jpg`
    }
};
