import Bowser from 'bowser';

const browser = Bowser.getParser(window.navigator.userAgent);

/**
 * Specifically checks for iOS devices.
 *
 * @return {Boolean}
 */
const isiOSDevice = () => {
    if (!browser) {
        return false;
    }
    const deviceOS = browser.getOS();
    return Boolean(deviceOS && deviceOS.name && deviceOS.name === 'iOS');
};

/**
 * Specifically checks for Android devices.
 *
 * @return {Boolean}
 */
const isAndroidDevice = () => {
    if (!browser) {
        return false;
    }
    const deviceOS = browser.getOS();
    return Boolean(deviceOS && deviceOS.name && deviceOS.name === 'Android');
};

const DeviceDetection = {
    isiOSDevice,
    isAndroidDevice
};

export default DeviceDetection;
