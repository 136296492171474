import Utils from 'core-helpers/utils.js';
import BreakPointDetection from 'helpers/breakpoint-detection.js';
import _ from 'underscore';
import PubSub from 'pubsub-js/src/pubsub.js';

(function(root, doc, undefined) {

    let allTabElements = null;
    let tabLoopInterval = null;
    const INTERVAL_LOOP_TIMER = 8000; // 8s

    const onTabClick = function(e) {
        e.preventDefault();
        clearInterval(tabLoopInterval);

        const targetTab = Utils.getById(this.getAttribute('data-target-id'));

        activateSpecificTab(targetTab);
        startInterval(INTERVAL_LOOP_TIMER);
    };

    const activateSpecificTab = (tabElement) => {
        // 'Hide' all tabs
        deactivateAllTabs();
        // 'Show' target tab
        setActiveTab(tabElement);
    };

    const deactivateAllTabs = () => {
        allTabElements.forEach((tab) => setInactiveTab(tab));
    };

    const setActiveTab = (tabElement) => {
        Utils.addClass(tabElement, 'tab-carousel__tab--active');
    };

    const setInactiveTab = (tabElement) => {
        Utils.removeClass(tabElement, 'tab-carousel__tab--active');
    };

    const loopToNextActiveTab = (tabCarousel) => {
        // Finds the index that needs to be activated
        const getIndex = () => {
            const activeTab = Utils.getElementByClass('tab-carousel__tab--active', tabCarousel);
            const activeTabIndex = allTabElements.indexOf(activeTab);
            return (activeTabIndex < allTabElements.length - 1) ? activeTabIndex + 1 : 0;
        };

        const tabElement = allTabElements[getIndex()];
        activateSpecificTab(tabElement);
    };

    const canTabIntervalBeSet = () => {
        const hasMoreThanOneTab = Boolean(allTabElements.length > 1);
        const hasLargeScreen = Boolean(BreakPointDetection.getState() > 2);
        return (hasMoreThanOneTab && hasLargeScreen);
    };

    const startInterval = () => {
        if (canTabIntervalBeSet()) {
            tabLoopInterval = setInterval(loopToNextActiveTab, INTERVAL_LOOP_TIMER);
        } else {
            clearInterval(tabLoopInterval);
        }
    };

    const init = () => {

        const tabCarousel = Utils.getById('tab-carousel');

        if (tabCarousel) {

            allTabElements = Utils.getElementsByClass('js-tab-carousel-tab', tabCarousel);

            // By default - set first tab as active and show first image
            const firstTab = allTabElements[0];
            setActiveTab(firstTab);

            // Setup single event listener on tab carousel
            Utils.delegate(tabCarousel, 'js-tab-carousel-tab-text', 'click', onTabClick);

            // Initialize with looping to the next tab every 8 seconds
            startInterval();

            // Listen to the breakpoint detection events and check if interval can be set
            PubSub.subscribe('BREAKPOINT_DETECTION__CHANGED', startInterval);
        }

    };

    init();

})(window, document);
