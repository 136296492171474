import endpointConstant from 'endpoint/endpoint-constant.js';
import SharedDataService from 'data/shared-data-service.js';

// Endpoints that require captcha
const captchaBypassedEndpoints = ['login', 'forgottenPassword', 'forgottenPasswordVerification', 'resetPassword', 'register'];

/**
 * Returns a host for our API base
 * NB: Build time only. Can't be dynamic (runtime).
 * @param  {String} apiType
 * @return {String}
 * @private
 */
const generateHost = (apiType) => {
    switch (apiType) {
        case 'paymentAPI':
            return process.env.paymentAPI;
        case 'liveAPI':
            return process.env.liveAPI;
        case 'baseAPI':
        default:
            return process.env.baseAPI;
    }
};

/**
 * Returns an endpoint
 * @param {String} endpoint
 * @param {String} apiType - optional
 */
const generateEndpoint = (endpoint, apiType = null) => {
    const host = generateHost(apiType);
    const relativeEndpointURL = endpointConstant.endpoints[endpoint];
    const absoluteEndpointURL = `${host}${relativeEndpointURL}`;
    return absoluteEndpointURL;
};

/**
 * Returns an endpoint without considering the current baseAPI, hardcoded.
 * @param {String} endpoint
 * @return String
 */
const getHardCodedEndpoint = (endpoint) => {
    return endpointConstant.absoluteEndpoints[endpoint];
};

/**
 * This method compares two endpoints and will
 * add a coupon parameter if available.
 * @param {String} endpoint
 * @param {String} endpointWithCoupon
 * @param {String} apiType - optional
 * @return {String}
 */
const getDiscountedEndpoint = (endpoint, endpointWithCoupon, apiType = 'paymentAPI') => {

    // Default endpoint, there is no discount
    let url = generateEndpoint(endpoint, apiType);

    // Discount coupon has been found
    let discount = SharedDataService.getDiscountCoupon();
    if (endpointWithCoupon && discount) {
        url = generateEndpoint(endpointWithCoupon, apiType);
        url = url.replace('{coupon}', discount);
    }

    return url;
};

/**
 * Generates an endpoint with a captcha bypass token
 * as a query parameter if found in the session storage
 * @param {String} endpoint
 * @return {String}
 */
const getCaptchaBypassedEndpoint = (endpoint) => {
    const captchaBypassToken = SharedDataService.getQACaptchaToken();
    const generatedEndpoint = generateEndpoint(endpoint);

    if (endpoint === 'captcha') {
        return `${generatedEndpoint}&captcha_token=${captchaBypassToken}`;
    }

    if (captchaBypassToken && captchaBypassedEndpoints.includes(endpoint)) {
        return `${generatedEndpoint}?captcha_token=${captchaBypassToken}`;
    }

    return generatedEndpoint;
};

const EndpointService = {
    generateEndpoint,
    getHardCodedEndpoint,
    getDiscountedEndpoint,
    getCaptchaBypassedEndpoint
};

export default EndpointService;
