// TODO: To be moved into shared-js by creating a languages package

/**
 * Fallback hardcoded list of languages
 * Used when fetching the available languages fails
 */
export const DEFAULT_COURSE_LANGS = ['en', 'es', 'fr', 'de', 'ja', 'it', 'nl', 'ru', 'zh', 'ar', 'tr', 'pt', 'pl'];

/**
 * Marketing reorder list of flags based on course popularity
 * Used on the flag modules within the main landing pages
 * N.B: hardcoded since backend returns a single order of courses
 * N.B 2: NL exists only on certain interfaces (for now)
 */
export const COURSES_LANGS_BY_INTERFACE_LANGS = {
    'en': ['en', 'es', 'fr', 'de', 'ja', 'it', 'nl', 'ru', 'zh', 'ar', 'tr', 'pt', 'pl'],
    'fr': ['en', 'fr', 'es', 'ja', 'de', 'it', 'nl', 'ar', 'ru', 'pt', 'tr', 'zh', 'pl'],
    'de': ['en', 'es', 'de', 'ja', 'fr', 'it', 'nl', 'ru', 'ar', 'tr', 'pl', 'zh', 'pt'],
    'pt': ['en', 'es', 'fr', 'ja', 'it', 'de', 'ru', 'pt', 'zh', 'ar', 'tr', 'pl'],
    'zh': ['en', 'ja', 'fr', 'es', 'de', 'it', 'ru', 'zh', 'pt', 'ar', 'tr', 'pl']
};
