import RunningExperimentsService from 'experiments/experiments-service.ts';
import { ALL_USER_FREE_TRIAL, ALL_USER_FREE_TRIAL_VARIATION } from 'experiments/experiments-service.constants.ts';

const isInExperiment = () => {
    return RunningExperimentsService.isInExperimentBucket(ALL_USER_FREE_TRIAL, ALL_USER_FREE_TRIAL_VARIATION);
};

const FreeTrialExperiment = {
    isInExperiment
};

export default FreeTrialExperiment;
