import ThirdPartyService from 'core-services/third-party-service.js';

/**
 * Adjust SDK Version
 * Use latest for automatic updates
 */
const ADJUST_SDK_VERSION = '5.0.0'; 

const isVendorDisabled = () => {
    return ThirdPartyService.isVendorDisabled();
};

/**
 * Adjust Script
 * Source: https://github.com/adjust/web_sdk/tree/master/dist
 */
const injectScript = () => {

	!(function (t, a, e, r, s, l, d, n, o) {
		(t.Adjust = t.Adjust || {}), (t.Adjust_q = t.Adjust_q || []);
		for (var c = 0; c < l.length; c++) d(t.Adjust, t.Adjust_q, l[c]);
		(n = a.createElement("script")),
			(o = a.getElementsByTagName("script")[0]),
			(n.async = !0),
			(n.src = `https://cdn.adjust.com/adjust-${ADJUST_SDK_VERSION}.min.js`),
			(n.onload = function () {
				for (var a = 0; a < t.Adjust_q.length; a++) {
					t.Adjust[t.Adjust_q[a][0]].apply(t.Adjust, t.Adjust_q[a][1])
				};
				t.Adjust_q = [];
			}),
			o.parentNode.insertBefore(n, o);
	})(
		window,
		document,
		0,
		0,
		0,
		[
			"initSdk",
			"trackEvent",
			"addGlobalCallbackParameters",
			"addGlobalPartnerParameters",
			"removeGlobalCallbackParameter",
			"removeGlobalPartnerParameter",
			"clearGlobalCallbackParameters",
			"clearGlobalPartnerParameters",
			"switchToOfflineMode",
			"switchBackToOnlineMode",
			"stop",
			"restart",
			"gdprForgetMe",
			"disableThirdPartySharing",
		],
		function (t, a, e) {
			t[e] = function () {
				a.push([e, arguments]);
			};
		}
	);

};

const init = (callback) => {

    if (isVendorDisabled()) {
        return false;
    }

    injectScript();

    if (typeof callback === 'function') {
        callback();
    }

};

export default {
    init
};
