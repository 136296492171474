import APIRequestsService from 'endpoint/api-requests-service.js';
import ExceptionService from 'core-services/exceptions/exception-service.js';
import endpoints from 'endpoint/endpoint-service.js';
import PlansHelper from 'core-helpers/plans-helper.js';
import SharedDataService from 'data/shared-data-service.js';
import Utils from 'core-helpers/utils.js';
import { decomposeAxiosError, composeAxiosError } from 'endpoint/api-requests-service.helpers.js';
import { ERROR_BAD_RESPONSE_FORMAT, ERROR_PARAMS_MISSING } from 'endpoint/api-requests-service.constants.js';

/**
 * All the service APIs related to payments should
 * be accessible from here
 */

// Private vars
const filename = 'purchase-service.js';

const logError = (message, data) => {
    ExceptionService.handle('warning', { filename, message, data });
};

/**
 * Returns tracking information based on a
 * given Busuu transactionId
 * @return {Promise}
 * @public
 */
const getTracking = (transactionId) => {
    const onError = (error) => {
        const { applicationCode, message } = decomposeAxiosError(error);

        logError(`getTracking() error: ${applicationCode} - ${message}`, error);

        return Promise.reject({
            type: 'PAYMENT_TRACKING_FAILURE',
            issue: applicationCode,
            message
        });
    };

    const onSuccess = (response) => {
        if (!response || !response.data) {
            return onError(composeAxiosError(ERROR_BAD_RESPONSE_FORMAT));
        }

        return response.data;
    };

    if (!transactionId) {
        return onError(composeAxiosError(ERROR_PARAMS_MISSING));
    }

    const url = endpoints.generateEndpoint('paymentTracking')
        .replace('{transactionId}', transactionId);

    return APIRequestsService.get(url)
        .then(onSuccess)
        .catch(onError);
};

/**
 * Stores a given plan in SessionStorage that can be used on the payment page
 * @param  {Object} plansData
 * @param  {Object} selectedPlan
 * @public
 */
const storePlanForPayment = (plansData, selectedPlan) => {
    const data = PlansHelper.getValidStoragePlan(plansData, selectedPlan);
    SharedDataService.storeSelectedPlan(data);
};

/**
 * Helper to generate checkout related endpoints.
 * We use this method as we can deliberately skip membership in non-production environments.
 * This means the user can subscribe multiple times and is used for testing only.
 * @return  {String}
 * @private
 */
const generateCheckoutEndpoint = (apiName, apiType) => {
    let url = endpoints.generateEndpoint(apiName, apiType);
    if (canMultiSubscribe()) {
        url += getMultiSubscriptionsParam(url);
    }
    return url;
};

/**
 * For debugging *only in non-production environments* - allow multiple subscriptions
 * If the URL parameter `multi_subscribe` is set as true, we allow
 * the payment to be processed by passing a parameter to the backend on the checkout endpoint.
 * @return  {Boolean}
 * @private
 */
const canMultiSubscribe = () => {
    return Boolean(Utils.getParameter('multi_subscribe'));
};

/**
 * Generates a parameter that can be added to an endpoint
 * to bypass existing subscription.
 * @return  {String}
 * @private
 */
const getMultiSubscriptionsParam = (url = '') => {
    const separator = url.includes('?') ? '&' : '?';
    const param = 'skip=membership';
    return `${separator}${param}`;
};

const PurchaseService = {
    getTracking,
    storePlanForPayment,
    generateCheckoutEndpoint
};

export default PurchaseService;
