import Utils from 'core-helpers/utils.js';
import VerticalScrollTransition from 'components/vertical-scroll-transition-class.js';
import SectionObserver from 'components/section-observer-class.js';

let mainNavElement = null;
let mainNavCtaElement = null;

let scrollElems = null;
const smoothScrollSelectors = [
    { mod: 'js-faq-module', cta: 'live-plans-faq' },
    { mod: 'live-plans-how-it-works-module', cta: 'live-plans-how-it-works' }
];

const NAV_HEIGHT = 70;
// Offset the inner space of the sections to be observed
// so they have more separation between them.
const SECTION_OFFSET = -150;

const DEFAULT_NAV_CLASS = 'main-nav--transparent';
const SCROLLED_NAV_CLASS = 'main-nav--scrolled';

const LINK_BRAND_CLASS = 'btn--link-light--base';
const LINK_DEFAULT_CLASS = 'btn--link-dark';
const BTN_OUTLINE_LIGHT_CLASS = 'btn--outline-light';
const BTN_OUTLINE_DEFAULT_CLASS = 'btn--outline-default';
const HIGHLIGHT_CLASS = 'btn--highlight';

/**
 * Toggles classes on a given element
 * @param {object} element
 * @param {string} classToAdd
 * @param {string} classToRemove
 * @private
 */
const toggleElementClass = (element, classToAdd, classToRemove) => {
    Utils.removeClass(element, classToRemove);
    Utils.addClass(element, classToAdd);
};

/**
 * Toggles the nav & CTA classes depending on the scroll position of the page
 * @param {Object} scrolled flag
 * @private
 */
const updateNavScrollState = ({ scrolled }) => {
    if (scrolled) {
        toggleElementClass(mainNavElement, SCROLLED_NAV_CLASS, DEFAULT_NAV_CLASS);

        if (Boolean(mainNavCtaElement)) {
            toggleElementClass(mainNavCtaElement, BTN_OUTLINE_DEFAULT_CLASS, BTN_OUTLINE_LIGHT_CLASS);
        }

        if (Boolean(scrollElems.length)) {
            scrollElems.forEach(({ module, element }) => {
                toggleElementClass(element, LINK_DEFAULT_CLASS, LINK_BRAND_CLASS);
                new SectionObserver(module, element, HIGHLIGHT_CLASS, SECTION_OFFSET);
            });
        }
    } else {
        toggleElementClass(mainNavElement, DEFAULT_NAV_CLASS, SCROLLED_NAV_CLASS);

        if (Boolean(mainNavCtaElement)) {
            toggleElementClass(mainNavCtaElement, BTN_OUTLINE_LIGHT_CLASS, BTN_OUTLINE_DEFAULT_CLASS);
        }

        if (Boolean(scrollElems.length)) {
            scrollElems.forEach(({ element }) => {
                toggleElementClass(element, LINK_BRAND_CLASS, LINK_DEFAULT_CLASS);
            });
        }
    }
};

/**
 * Adapts the nav styling depending on the vertical scroll distance on the page
 */
const initNavScrollTransition = () => {
    if (mainNavElement) {
        Utils.addClass(mainNavElement, DEFAULT_NAV_CLASS);
        new VerticalScrollTransition(NAV_HEIGHT, updateNavScrollState);
    }
};

const scrollToElement = (element) => {
    element.scrollIntoView({ behavior: 'smooth', block: 'center' });
    element.blur();
};

/**
 * Allows a smooth scroll to a given element
 */
const initSmoothScroll = () => {
    scrollElems = smoothScrollSelectors.reduce((seed, { mod, cta }) => {
        const section = Utils.getById(mod);
        const btn = Utils.getById(cta);
        if (section && btn) {
            seed.push({ module: section, element: btn });
        }

        return seed;
    }, []);

    if (Boolean(scrollElems.length)) {
        scrollElems.forEach(({ module, element }) => {
            element.addEventListener('click', scrollToElement.bind(null, module));
        });
    }
};

export const initLiveHeaderNav = () => {
    mainNavElement = Utils.getElementByClass('js-live-nav-scroll');
    mainNavCtaElement = Utils.getElementByClass('js-live-nav-cta-scroll');

    initSmoothScroll();
    initNavScrollTransition();
};
