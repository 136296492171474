import Utils from 'core-helpers/utils.js';
import { throttle } from 'underscore';
import ItemRotator from 'components/item-rotator-class.js';

const ITEM_INDICATOR_CLASS = 'js-item-rotator__indicator';
const ITEM_INDICATOR_ACTIVE_CLASS = 'js-item-rotator__indicator--active';
const ITEM_INDICATOR_INDEX_ATTR = 'data-index';

const ITEM_INDICATOR_BACKWARDS_CLASS = 'js-item-rotator--backwards';
const ITEM_INDICATOR_FORWARDS_CLASS = 'js-item-rotator--forwards';
const BACKWARDS = 'backwards';
const FORWARDS = 'forwards';

/**
 * A class that extends `ItemRotator`. It handles showing the progression as the items rotate.
 * `data-index` is used to map the indicator to the item index.
 *
 * @example
 * const instance = new ItemRotator(element, {
 *     ...
 *     indicatorActiveClass: 'class-when-indicator-active',
 * });
 *
 * <div class="js-item-rotator">
 *     <div class="js-item-rotator__item js-item-rotator__item--active">
 *         ...
 *     </div>
 *     <div class="js-item-rotator__item">
 *         ...
 *     </div>
 *     <div class="js-item-rotator__item">
 *         ...
 *     </div>
 *     <div>
 *         <div class="js-item-rotator__indicator js-item-rotator__indicator--active" data-index="0">
 *             ...
 *         </div>
 *         <div class="js-item-rotator__indicator js-item-rotator__indicator--active" data-index="1">
 *             ...
 *         </div>
 *         <div class="js-item-rotator__indicator js-item-rotator__indicator--active" data-index="2">
 *             ...
 *         </div>
 *     </div>
 * </div>
 */
class ItemRotatorWithProgress extends ItemRotator {
    /**
     * Initialize an item rotator with progress indicator
     * @constructor
     * @augments ItemRotator
     * @param {Element} wrapperElement
     * @param {Object} options
     * @param {string} options.indicatorActiveClass - Class to add to the active indicator item
     */
    constructor(wrapperElement, options = {}) {
        super(wrapperElement, options);

        this.indicators = [];
        this.classes = {
            ...this.classes,
            indicatorActiveClass: this.getItemClass(ITEM_INDICATOR_ACTIVE_CLASS, options.indicatorActiveClass)
        };

        if (wrapperElement) {
            this.initIndicators();
            this.initArrows();
        }
    }

    initIndicators() {
        this.indicators = Utils.getElementsByClass(ITEM_INDICATOR_CLASS, this.wrapperElement);

        this.indicators.forEach((indicator) => {
            indicator.addEventListener('click', this.onClickIndicator.bind(this));
        });
    }

    /**
     *  Establishes the forwards & backwards CTA elements - if they exist - and attaches the click event listeners
     */
    initArrows() {
        this.backwardsArrow = Utils.getElementByClass(ITEM_INDICATOR_BACKWARDS_CLASS, this.wrapperElement);
        if (this.backwardsArrow) {
            this.backwardsArrow.addEventListener('click', throttle(this.onClickArrow.bind(this, BACKWARDS), 300));
        }

        this.forwardsArrow = Utils.getElementByClass(ITEM_INDICATOR_FORWARDS_CLASS, this.wrapperElement);
        if (this.forwardsArrow) {
            this.forwardsArrow.addEventListener('click', throttle(this.onClickArrow.bind(this, FORWARDS), 300));
        }
    }

    onClickIndicator(e) {
        if (e) { e.preventDefault(); }

        const index = Number(e.target.getAttribute(ITEM_INDICATOR_INDEX_ATTR));

        // change item and reset the interval so we don't get double transitions
        this.changeItem(index, true);
    }

    /**
     * On arrow click, it moves the index forwards or backwards
     * @param {String} direction - forwards|backwards
     * @param {Object} event
     */
    onClickArrow(direction, e) {
        if (e) { e.preventDefault(); }

        if (direction === FORWARDS) {
            this.changeItem(this.currentIndex + 1, true);
        }

        if (direction === BACKWARDS) {
            this.changeItem(this.currentIndex - 1, true);
        }
    }

    getIndicatorFromDataIndex(index) {
        return this.indicators.find((ind) => Number(ind.getAttribute(ITEM_INDICATOR_INDEX_ATTR)) === index);
    }

    onChangeItem(prevIndex, curIndex) {
        const prevIndicator = this.getIndicatorFromDataIndex(prevIndex);
        const currentIndicator = this.getIndicatorFromDataIndex(curIndex);

        Utils.removeClass(prevIndicator, this.classes.indicatorActiveClass);
        Utils.addClass(currentIndicator, this.classes.indicatorActiveClass);
    }
}

export default ItemRotatorWithProgress;
