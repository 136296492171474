export const FT_TYPE_BASIC = 'free-trial-basic';
export const FT_TYPE_REFERRAL = 'free-trial-referral';
export const FT_TYPES = [FT_TYPE_BASIC, FT_TYPE_REFERRAL];

export const FT_PLAN_TYPE_BASIC = 'ft_free_users';
export const FT_PLAN_TYPE_REFERRAL = 'ft_referral_premium';
export const FT_PLAN_TYPES = [FT_PLAN_TYPE_BASIC, FT_PLAN_TYPE_REFERRAL];

export const FT_MONTHS = 12;
export const FT_TIER = 'premium_plus';
export const FT_EXPIRY_SECONDS = 86400;
