import DeviceDetection from 'helpers/device-detection.js';
import Utils from 'core-helpers/utils.js';
import Tracking from 'tracking/tracking-controller.ts';

const AppLinks = (function(root, doc, undefined) {
    const onBadgeClick = function(platform, e) {
        if (e && e.target) {
            e.preventDefault();

            Tracking.send('APP_STORE_BADGE_SELECTED', { platform });

            window.open(this.href, '_blank');
        }
    };

    const setupBadge = function(badgeClass, isDevice, badgeOnClick, platform) {
        const badges = Utils.getElementsByClass(badgeClass, document);

        badges.forEach((badge) => {
            const badgeLink = Utils.getElementByClass('app-links__item-link', badge);

            if (badgeLink) {
                badgeLink.addEventListener('click', badgeOnClick.bind(badgeLink, platform));
            }

            if (isDevice) {
                Utils.addClass(badge, 'app-links__item--active');
            }
        });
    };

    const setupBadges = function() {
        const isIOSDevice = DeviceDetection.isiOSDevice();
        const isAndroidDevice = DeviceDetection.isAndroidDevice();

        setupBadge('app-links__item--app-store', isIOSDevice, onBadgeClick, 'ios');
        setupBadge('app-links__item--play-store', isAndroidDevice, onBadgeClick, 'android');
    };

    setupBadges();

})(window, document);
