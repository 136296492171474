import Utils from 'core-helpers/utils.js';
const BANNER_OPEN_CLASS = 'banner--open';
/**
 * A reusable class that implements showing and hiding of a banner element
 */
class DefaultBaseBanner {
    constructor(modal) {
        /**
         * Show the banner
         */
        this.show = () => {
            Utils.addClass(this.bannerEl, BANNER_OPEN_CLASS);
        };
        /**
         * Hide the banner
         */
        this.hide = () => {
            Utils.removeClass(this.bannerEl, BANNER_OPEN_CLASS);
        };
        this.bannerEl = modal;
    }
}
export default DefaultBaseBanner;
