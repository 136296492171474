import CountriesConstants from 'core-constants/countries-constants.js';
import Utils from 'core-helpers/utils.js';
import { GDPR_COUNTRY_CODES } from '../core-constants/countries-constants';

const filename = 'countries-service.js';

/**
 * Returns the country object for the selected country code
 * @param {String} 2 letter country code
 * @returns {Object} Country details { name, code, callingCode }
 */
const getCountryDataByCode = (countryCode) => {
    const data = CountriesConstants[countryCode];

    if (!data) {
        throw new Error(`[File: ${filename}] Could not get country details for the following code: ${countryCode}`);
    }

    return data;
};

/**
 * Returns the phone country code for the selected country in the view
 */
const getSelectedCountryPhoneCode = (countryCode) => {
    const selectedCountry = getCountryDataByCode(countryCode);
    return selectedCountry ? `+${selectedCountry.callingCode}` : '';
};

/**
 * Gets the shared common country list with label, country code and calling code
 * @returns {Object} Countries object
 */
const getCountryList = () => Utils.deepClone(CountriesConstants);

/**
 * @param {Array} Unsorted country list with translated labels
 * NB: Sample country object within the given array: { code: "GR", label: "Grèce", phoneLabel: "Grèce (+30)" }
 * @param {String} Interface language code
 * @returns {Object} Sorted by the object's label key
 * NB: We use `localeCompare` to ensure that letters with
 * accents aren't ignored and pushed to the end of the array
 * during sorting as `sort` only supports English characters.
 * https://www.jstips.co/en/javascript/sorting-strings-with-accented-characters/
 */
const sortByLabel = (countryList, interfaceLang) => {
    return countryList.sort((a, b) => a.label.localeCompare(b.label, [interfaceLang]));
};

/**
 * Returns if a country is GDPR protected. Defaults to `true`.
 * @param {string} countryCode
 * @returns {boolean}
 */
const isCountryGDPRProtected = (countryCode) => {
    return countryCode ? GDPR_COUNTRY_CODES.includes(countryCode) : true;
};

export default {
    getCountryDataByCode,
    getSelectedCountryPhoneCode,
    getCountryList,
    sortByLabel,
    isCountryGDPRProtected
};
