import Utils from 'core-helpers/utils.js';
import TranslationsService from 'common/translations.service.ts';
import PlansHelper from 'core-helpers/plans-helper.js';
import PlansService from 'premium/plans-service.js';
import PurchaseService from 'purchase/purchase-service.js';
import ECommerceService from 'core-services/ecommerce-origin-service.js';
import CurrentUser from 'user/current-user-service.js';
import FreeTrialService from 'core-services/free-trial/free-trial.service.js';
import WindowLocationService from 'common/window-location.service.js';
import { FT_MONTHS, FT_TIER, FT_PLAN_TYPE_BASIC } from 'core-services/free-trial/free-trial.constants.js';
import { PARTNER_DATA } from 'free-trial/free-trial.constants.js';

const filename = 'free-trial-benefits.js';

// Elements
const claimBtn = Utils.getElementByClass('js-ft-button');
let ftWrapper = null;

// UI Classnames
const UI_LOADING_CLASSNAME = 'ft--loading';

let isEligibleForTrial = false;
let freeTrialDays = null;
let plansData = null;
let selectedPlan = null;
let isAuthenticated = null;

const updateTitleText = () => {
    const elem = Utils.getElementByClass('js-ft-title');

    Utils.setText(elem, TranslationsService.getAndReplaceTranslationMulti(
        freeTrialDays === 30 ? 'YOUR_FIRST_MONTH_IS_ON_US' : 'YOUR_FIRST_WEEK_IS_ON_US'
    ));
};

const updateSubtitleText = () => {
    const elem = Utils.getElementByClass('js-ft-subtitle');
    const text = TranslationsService.getAndReplaceTranslationMulti('TRY_ALL_OF_BUSUU_UNLOCKED_FOR_X_DAYS', [freeTrialDays]);
    elem.innerHTML = text;
};

const updateButtonText = () => {
    const text = TranslationsService.getAndReplaceTranslationMulti('START_YOUR_X_DAY_FREE_TRIAL', [freeTrialDays]);
    Utils.setText(claimBtn, text);
};

const onFetchPlansSuccess = (planType, response) => {
    plansData = Utils.deepClone(response);
    isAuthenticated = PlansHelper.isUserAuthenticated(plansData);
    selectedPlan = PlansHelper.findFreeTrialPlanByMonth(plansData[planType][FT_TIER], FT_MONTHS);

    if (!selectedPlan) {
        throw new Error(`${filename} onFetchPlansSuccess() - 'selectedPlan' does not exist.`);
    }

    freeTrialDays = selectedPlan.free_trial_strategy_days;
};

const fetchPlans = () => {
    const partner = Utils.getParameter('partner');
    let planType = FT_PLAN_TYPE_BASIC;

    if (partner && PARTNER_DATA[partner]) {
        planType = PARTNER_DATA[partner].planType;
    }

    return PlansService.getTieredPlansCustom([planType])
        .then(onFetchPlansSuccess.bind(null, planType));
};

/**
 * In case of an error, we redirect the user to login page
 * or dashboard if they are already logged in
 */
const errorRedirect = () => {
    const URL = ftWrapper.getAttribute('data-error-redirect');
    WindowLocationService.redirect(URL);
};

const onComplete = () => {
    Utils.removeClass(ftWrapper, UI_LOADING_CLASSNAME);
};

const onTrialSelection = function(e) {

    e.preventDefault();

    const formattedPlan = PlansHelper.getFormattedPlan(selectedPlan);
    const storagePlan = PlansHelper.getValidStoragePlan(plansData, formattedPlan);

    if (storagePlan.plan.hasFreeTrial) {
        claimBtn.href = claimBtn.getAttribute('data-success');
        PurchaseService.storePlanForPayment(plansData, storagePlan.plan);
        ECommerceService.store('FREE_TRIAL_ONBOARDING');
        WindowLocationService.redirect(this.href);
    }
};

const fetchUser = () => {
    if (isAuthenticated) {
        return CurrentUser.get({ force: true });
    }
};

/**
 * We throw an error on load if the user exists and
 * they are not eligible for trial
 */
const checkIfUserIsEligible = (user) => {
    if (user) {
        isEligibleForTrial = FreeTrialService.isEligibleForFreeTrial(user);
    }
    if (user && !isEligibleForTrial) {
        throw new Error(`${filename} checkIfUserIsEligible() - User is not eligible for free trial.`);
    }
};

const updateTrialButtonBehaviour = () => {
    if (isEligibleForTrial) {
        claimBtn.addEventListener('click', onTrialSelection);
    }
};

const init = () => {
    ftWrapper = Utils.getById('js-ft-landing');

    if (ftWrapper) {
        return fetchPlans()
            .then(fetchUser)
            .then(checkIfUserIsEligible)
            .then(updateTitleText)
            .then(updateSubtitleText)
            .then(updateButtonText)
            .then(updateTrialButtonBehaviour)
            .then(onComplete)
            .catch(errorRedirect);
    }
};

init();
