import Utils from 'core-helpers/utils.js';
import FlagInteractor from 'components/flag-interactor-class.js';
import ItemRotator from 'components/item-rotator-class.js';
import ItemChildHeightCalculator from 'components/item-child-height-calculator-class.js';
import TranslationsService from 'common/translations.service.ts';
import { initBGHeader } from 'components/main-header-wave-background.js';
import Tracking from 'tracking/tracking-controller.ts';

const HEADER_ROTATION_INTERVAL = 3000;

const onMouseEnterFlag = function(headerCta, value) {
    const lang = TranslationsService.getTranslation(`LANGUAGE_${value}`);
    Utils.setText(headerCta, TranslationsService.getAndReplaceTranslation('START_LEARNING', lang));
};

const onMouseLeaveFlag = function(headerCta) {
    Utils.setText(headerCta, TranslationsService.getTranslation('START_LEARNING_NOW'));
};

const initFlags = () => {
    const flagWrapper = Utils.getElementByClass('choose-language-scrollable');
    const headerCta = Utils.getById('main-header-cta');

    if (flagWrapper && headerCta) {
        new FlagInteractor(flagWrapper, {
            flagClass: 'js-flag',
            onMouseEnter: onMouseEnterFlag.bind(null, headerCta),
            onMouseLeave: onMouseLeaveFlag.bind(null, headerCta)
        });
    }
};

const initTitleTextRotator = (wrapperClass) => {
    const titlesWrapper = Utils.getElementByClass(wrapperClass);

    if (titlesWrapper) {
        new ItemChildHeightCalculator(titlesWrapper, {
            calculateClass: 'main-header-wave__title-rotator-item'
        });

        new ItemRotator(titlesWrapper, {
            interval: HEADER_ROTATION_INTERVAL,
            initializedClass: 'main-header-wave__title-rotator--initialized',
            itemPrevClass: 'main-header-wave__title-rotator-item--prev',
            itemActiveClass: 'main-header-wave__title-rotator-item--active',
            itemNextClass: 'main-header-wave__title-rotator-item--next'
        });
    }
};

const init = () => {
    initFlags();
    initBGHeader()
        .catch(() => {})
        .then(initTitleTextRotator.bind(null, 'js-title-rotator'))
        .then(initTitleTextRotator.bind(null, 'js-subtitle-rotator'));

    /**
     * LIVE_GROUP_NAV_LINK experiment
     * - 50% of EN/DE Homepage users see the Live Group link
     * - 50% of EN/DE Homepage users do not see the Live Group link
     */
    const MAIN_NAV_CLASS = 'nav-dropdown';
    const LIVE_LINK_JS_CLASS = 'js-live-group-link';
    const GENERIC_HIDDEN_CLASS = 'hidden';
    const mainNavElement = Utils.getElementByClass(MAIN_NAV_CLASS);

    if (mainNavElement) {
        const liveGroupLinkElements = Utils.getElementsByClass(LIVE_LINK_JS_CLASS, mainNavElement);
        if (liveGroupLinkElements && liveGroupLinkElements.length) {
            liveGroupLinkElements.forEach((link) => {
                Utils.removeClass(link, GENERIC_HIDDEN_CLASS);
                link.addEventListener('click', () => Tracking.sendAndWait('NAVBAR_GROUP_LINK_SELECTED'));
            });
        }
    }
};

init();

export { init };
