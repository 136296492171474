import Utils from 'core-helpers/utils.js';
import CourseConfigService from 'course-config/course-config-service.js';
import Tracking from 'tracking/tracking-controller.ts';
import { DEFAULT_COURSE_LANGS } from 'course-config/course-config-constants.js';
import FlagsListClass from 'components/flags-list-class.ts';

let loaderElement = null;
let flagsContainerElement = null;

/**
 * Fetches the course languages available for the current interface.
 * Fallback to a full list of languages if the call fails.
 */
const populateFlags = async() => {
    let languages = null;

    try {
        languages = await CourseConfigService.getAvailableCourseLanguages();
    } catch (e) {
        languages = DEFAULT_COURSE_LANGS;
    }

    new FlagsListClass({ languages, loader: loaderElement, container: flagsContainerElement });
};

const flagClickHandler = (flag, event) => {
    if (event) {
        event.preventDefault();
    }

    const targetValue = flag.getAttribute('data-value');
    if (targetValue) {
        Tracking.sendAndWait('COURSE_SELECTED', {
            language_selected: targetValue,
            source_page: 'onboarding',
            course: 'none - web onboarding'
        });
    }

    window.location.href = flag.href;
};

const init = () => {
    loaderElement = Utils.getById('flags-list-dynamic-loader');
    flagsContainerElement = Utils.getById('flags-list-dynamic-container');

    // Load and display the flags
    if (loaderElement && flagsContainerElement) {
        populateFlags();
    }

    // Add tracking for all existing flags
    const allFlags = Utils.getElementsByClass('js-flag');
    if (allFlags.length) {
        allFlags.forEach((flag) => flag.addEventListener('click', flagClickHandler.bind(this, flag)));
    }
};

init();
