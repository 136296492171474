import Utils from 'core-helpers/utils.js';
/**
 * Private vars
 */
const mainWrapper = Utils.getById('main-wrapper');
const menuButton = Utils.getById('js-menu-toggle');
const navElement = Utils.getById('js-responsive-nav');
const activeClass = 'responsive-nav--active';
/**
 * If the nav is open and the user clicked outside of his area, hide it.
 */
const hideNav = (e) => {
    const targetIsChild = Utils.hasChild(navElement, e.target);
    const navIsActive = Utils.hasClass(navElement, activeClass);
    if (!targetIsChild && navIsActive) {
        Utils.removeClass(navElement, activeClass);
    }
};
/**
 * Show/Hide the nav when the user clicks on the menu button
 */
const toggleNav = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (document.activeElement instanceof HTMLElement) {
        document.activeElement.blur(); // Remove focus from menu to be able to hide it
    }
    Utils.toggleClass(navElement, activeClass);
};
const init = () => {
    if (!menuButton || !navElement) {
        return null;
    }
    /**
     * By default we remove the activeClass on Init because we want to hide
     * the menu for Safari users when they click the back button of the
     * browser
     */
    Utils.removeClass(navElement, activeClass);
    /**
     * Add click event to the body because we want to hide the nav when
     * user clicks outside of it
     */
    menuButton.addEventListener('touchstart', toggleNav);
    menuButton.addEventListener('click', toggleNav);
    mainWrapper.addEventListener('touchstart', hideNav);
    mainWrapper.addEventListener('click', hideNav);
};
init();
