import _ from 'underscore';
import Utils from 'core-helpers/utils.js';
import Tracking from 'tracking/tracking-controller.ts';
import CurrentUser from 'user/current-user-service.js';
import PlansHelper from 'core-helpers/plans-helper.js';
import PlansService from 'premium/plans-service.js';
import SharedDataService from 'data/shared-data-service.js';
import eCommerceService from 'core-services/ecommerce-origin-service.js';

/**
 * Private vars
 */
let plansData = null;
let user = null;

const isPremiumAccess = () => {
    const pageIdentifier = document.body.getAttribute('data-page-identifier');
    return ['premium', 'premium-new'].includes(pageIdentifier);
};

const getCurrentLanguage = () => {
    return BUSUU_GLOBALS.LANG_INTERFACE || 'en';
};

const sendTracking = (key, data) => {
    Tracking.send(key, data);
};

/**
 * We store the current page view.
 * This will be used to send an event from the webapp
 * if the user has seen or not the /premium page.
 * Backend will then use that to add a discount or not
 * the next time user will see that page.
 */
const storePageView = () => {
    SharedDataService.storePremiumPageView();
};

const clickHandler = function() {
    const position = this.getAttribute('data-position');
    if (position) {
        sendTracking('PAYWALL_SEEPLANS_CLICKED', {
            position: position
        });
    }
};

const attachTracking = () => {
    Utils.getElementsByClass('js-tracking').forEach((element) => {
        element.addEventListener('click', clickHandler);
    });
};

const sendEvent = () => {

    let data = {
        paywall_locale: getCurrentLanguage(),
        free_trial: false,
        paywall_type: 'regular'
    };

    if (user) {
        data.paywall_country_code = user.country_code;
        data.paywall_cohort = user.country_code + '_normal';
    }
    if (plansData) {
        const allPlans = PlansHelper.getAllProductsPlans(plansData);
        data.discount_amount = PlansHelper.getHighestDiscount(allPlans);
    }

    const origin = eCommerceService.retrieve();
    if (origin) {
        data.ecommerce_origin = origin;
    }

    const coupon = SharedDataService.getDiscountCoupon();
    if (coupon) {
        data.discount_coupon = coupon;
    }

    sendTracking('PAGE_VIEW_PREMIUM', data);

};

const getUser = () => {
    const onUserSuccess = (userResponse) => {
        user = Utils.deepClone(userResponse);
    };
    return CurrentUser.get().then(onUserSuccess);
};

const fetchPlans = () => {
    const onPlansSuccess = (plansResponse) => {
        plansData = Utils.deepClone(plansResponse);
    };
    return PlansService.getTieredPlans().then(onPlansSuccess);
};

const init = () => {

    if (!isPremiumAccess()) {
        return false;
    }

    /**
     * If user is available, add extra datas, if not it means user is not recognize
     * and we will send the minimum datas we can find. Same for plans.
     */
    getUser()
        .catch(() => {})
        .then(fetchPlans)
        .catch(() => {})
        .then(sendEvent);

    // Store current page view
    storePageView();

    // Attach buttons events
    attachTracking();

};

init();
