import Utils from 'core-helpers/utils.js';
import _ from 'underscore';
import ExceptionService from 'core-services/exceptions/exception-service.js';

/**
 * Manage to load iframe dynamically after a click
 */
(function(root, doc, undefined) {

    /**
     * Create an iframe and inject it
     * @param  {String}  - src: Iframe URL
     * @param  {Element} - where: Where to replace content from
     */
    var loadIframe = function(src, where) {
        var iframe = '<iframe class="fluid-content__item" width="300" height="170" src="' + src + '" allowfullscreen></iframe>';
        where.innerHTML = iframe;
    };

    var onClickHandler = function(e) {
        e.preventDefault();
        var src = this.getAttribute('data-src');
        if (src) {
            return loadIframe(src, this);
        }
        ExceptionService.handle('error', {
            filename: filename,
            message: 'data-src for iframe not found on:',
            data: this
        });
    };

    /**
     * Get all the iframe loaders and
     * load an iframe after click
     */
    var init = function() {
        var iframeLoaders = Utils.getElementsByClass('js-iframe-loader');
        _.each(iframeLoaders, function(iframeLoader) {
            iframeLoader.addEventListener('click', onClickHandler);
        });
    };

    /**
     * Private vars
     */
    var filename = 'iframe-loader.js';

    init();

})(window, document);
