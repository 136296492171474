import Utils from 'core-helpers/utils.js';
import ConfigService from 'config/config-service.js';
import SharedDataService from 'data/shared-data-service.js';
import DefaultBaseModal from 'components/modals/default-base-modal';
const ALT_COUNTRY_MODAL_ID = 'alt-country-modal';
const COUNTRY_CODE_CHINA = 'CN';
let modalEl;
const onClickDismissCountryModal = () => {
    SharedDataService.setAltCountryModalDismissed(true);
};
const initCountryModal = (countryCode) => {
    if (countryCode === COUNTRY_CODE_CHINA) {
        const modal = new DefaultBaseModal(modalEl, {
            onClickDismiss: onClickDismissCountryModal
        });
        modal.show();
    }
};
const init = () => {
    const modalDismissed = SharedDataService.getAltCountryModalDismissed();
    modalEl = Utils.getById(ALT_COUNTRY_MODAL_ID);
    if (modalEl && !modalDismissed) {
        ConfigService.getCountryCode()
            .then(initCountryModal)
            .catch(() => null);
    }
};
export { init };
