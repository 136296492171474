import RunningExperimentsService from 'experiments/experiments-service.ts';
import { PAYWALL_CUSTOMIZED_BY_LANGUAGE_LEARNT, PAYWALL_CUSTOMIZED_BY_LANGUAGE_LEARNT_VARIATION } from 'experiments/experiments-service.constants.ts';

const isInExperiment = () => {
    return RunningExperimentsService.isInExperimentBucket(PAYWALL_CUSTOMIZED_BY_LANGUAGE_LEARNT, PAYWALL_CUSTOMIZED_BY_LANGUAGE_LEARNT_VARIATION);
};

const PaywallCustomizedByLanguageLearntExperiment = {
    isInExperiment
};

export default PaywallCustomizedByLanguageLearntExperiment;
