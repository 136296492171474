import Utils from 'core-helpers/utils.js';

const isVendorDisabled = () => {
    const htmlPresent = Boolean(document.documentElement.getAttribute('data-vendor') === 'false');
    const queryParamPresent = Boolean(Utils.getParameter('vendor') === 'false');
    return htmlPresent || queryParamPresent;
};

export default {
    isVendorDisabled
};
