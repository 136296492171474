import Utils from 'core-helpers/utils.js';
import ItemRotatorProgress from 'components/item-rotator-progress-class.js';

const ITEM_TEXT_CLASS = 'js-item-rotator__text';
const ITEM_TEXT_ACTIVE_CLASS = 'js-item-rotator__text--active';

/**
 * A class that extends `ItemRotatorProgress`. It handles showing the text description as the items rotate.
 *
 * @example
 * const instance = new ItemRotator(element, {
 *     ...
 *     textActiveClass: 'class-when-text-active',
 * });
 *
 * <div class="js-item-rotator">
 *    <div>
 *        <div class="js-item-rotator__item js-item-rotator__item--active">
 *            ...
 *        </div>
 *        <div class="js-item-rotator__item">
 *            ...
 *        </div>
 *        <div class="js-item-rotator__item">
 *            ...
 *        </div>
 *    </div>
 *    <div>
 *        <div class="js-item-rotator__text js-item-rotator__text--active">
 *            ...
 *        </div>
 *        <div class="js-item-rotator__text">
 *            ...
 *        </div>
 *        <div class="js-item-rotator__text">
 *            ...
 *        </div>
 *         <div>
 *     </div>
 *     <div>
 *        <div class="js-item-rotator__indicator js-item-rotator__indicator--active" data-index="0">
 *            ...
 *        </div>
 *        <div class="js-item-rotator__indicator js-item-rotator__indicator--active" data-index="1">
 *            ...
 *        </div>
 *        <div class="js-item-rotator__indicator js-item-rotator__indicator--active" data-index="2">
 *            ...
 *        </div>
 *     </div>
 * </div>
 */

class ItemRotatorWithText extends ItemRotatorProgress {
    /**
     * Initialize an item rotator with text
     * @constructor
     * @augments ItemRotatorProgress
     * @param {Element} wrapperElement
     * @param {Object} options
     * @param {string} options.textActiveClass - Class to add to the next text
     */
    constructor(wrapperElement, options = {}) {
        super(wrapperElement, options);

        this.texts = [];
        this.classes = {
            ...this.classes,
            textActiveClass: this.getItemClass(ITEM_TEXT_ACTIVE_CLASS, options.textActiveClass)
        };

        if (wrapperElement) {
            this.initTexts();
        }
    }

    initTexts() {
        this.texts = Utils.getElementsByClass(ITEM_TEXT_CLASS, this.wrapperElement);
    }

    onChangeItem(prevIndex, currentIndex) {
        super.onChangeItem(prevIndex, currentIndex);
        const oldCurrentText = this.texts[prevIndex];
        const currentText = this.texts[currentIndex];

        Utils.removeClass(oldCurrentText, this.classes.textActiveClass);
        Utils.addClass(currentText, this.classes.textActiveClass);

    }
}

export default ItemRotatorWithText;
