import endpoints from 'endpoint/endpoint-service.js';
import APIRequestsService from 'endpoint/api-requests-service.js';
import ExceptionService from 'core-services/exceptions/exception-service.js';
import { decomposeAxiosError, composeAxiosError } from 'endpoint/api-requests-service.helpers.js';
import { ERROR_BAD_RESPONSE_FORMAT } from 'endpoint/api-requests-service.constants.js';

const filename = 'config-service.js';
let captchaDefer = null;
let twoFactorDefer = null;
let countryCodeDefer = null;

const logError = (message, data) => {
    ExceptionService.handle('warning', { filename, message, data });
};

/**
 * Check if 2fa is required. Will return false if the endpoint errors.
 * @returns {Promise}
 */
const getTwoFactorAuthConfig = () => {
    if (twoFactorDefer) {
        return twoFactorDefer;
    }

    /**
     * We never reject this promise.
     * If the config endpoint is down, we will treat the flow as normal.
     * The backend will return an error if 2FA is required and the user uses an email
     * and we will proceed to the sms screen if they use a phone.
     */
    const onError = (error) => {
        const { applicationCode, message } = decomposeAxiosError(error);

        logError(`getTwoFactorAuthConfig() error: ${applicationCode} - ${message}`, error);

        return {
            twoFactorAuthenticationEnabled: false,
            countryCode: null
        };
    };

    const onSuccess = (response) => {
        if (!response || !response.data) {
            return onError(composeAxiosError(ERROR_BAD_RESPONSE_FORMAT));
        }

        return {
            twoFactorAuthenticationEnabled: response.data.two_factor_authentication_enabled,
            countryCode: response.data.country_code
        };
    };

    const url = endpoints.generateEndpoint('captcha');

    twoFactorDefer = APIRequestsService.get(url)
        .then(onSuccess)
        .catch(onError);

    return twoFactorDefer;
};

/**
 * Call backend to get the captcha config. They decide if a user need to
 * have catpcha test (depending on country, platform...)
 * @return {Promise}
 */
const getCaptchaConfig = () => {
    if (captchaDefer) {
        return captchaDefer;
    }

    /**
     * We never reject this promise.
     * If the config endpoint is down, the user should continue the normal Busuu flow.
     * The recaptcha will not be loaded and/or called as if captcha was not active.
     * Then we will try to authenticate/register the user on Busuu.
     * Backend will decide if a captcha token was mandatory.
     */
    const onError = (error) => {
        const { applicationCode, message } = decomposeAxiosError(error);

        logError(`getCaptchaConfig() error: ${applicationCode} - ${message}`, error);

        return {
            captcha_enabled_endpoints: [],
            captcha_url: 'default'
        };
    };

    const onSuccess = (response) => {
        if (!response || !response.data) {
            return onError(composeAxiosError(ERROR_BAD_RESPONSE_FORMAT));
        }

        return response.data;
    };

    const url = endpoints.getCaptchaBypassedEndpoint('captcha');

    captchaDefer = APIRequestsService.get(url)
        .then(onSuccess)
        .catch(onError);

    return captchaDefer;
};

/**
 * Call /config endpoint
 * to get the country code
 * @return {Promise}
 */
const getCountryCode = () => {
    if (countryCodeDefer) {
        return countryCodeDefer;
    }

    const onError = (error) => {
        const { applicationCode, message } = decomposeAxiosError(error);

        logError(`getCountryCode() error: ${applicationCode} - ${message}`, error);

        throw {
            type: applicationCode,
            message: message
        };
    };

    const onSuccess = (response) => {
        if (!response || !response.data) {
            return onError(composeAxiosError(ERROR_BAD_RESPONSE_FORMAT));
        }

        return response.data.country_code;
    };

    const url = endpoints.generateEndpoint('captcha');

    countryCodeDefer = APIRequestsService.get(url)
        .then(onSuccess)
        .catch(onError);

    return countryCodeDefer;
};

const ConfigService = {
    getCaptchaConfig,
    getTwoFactorAuthConfig,
    getCountryCode
};

export default ConfigService;
