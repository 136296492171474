import Utils from 'core-helpers/utils.js';
import ExceptionService from 'core-services/exceptions/exception-service.js';
import PlansService from 'premium/plans-service.js';
import PlansHelper from 'core-helpers/plans-helper.js';

(function(root, doc, undefined) {

    // Private vars
    const filename = 'premium-header-controller.js';
    const headerPriceWrap = Utils.getById('premium-header-price-wrap');
    const headerPrice = Utils.getById('premium-header-price');
    const headerPriceWrapClass = 'main-header__price--hide';
    const headerPriceStrikeClass = 'main-header__price--strike';
    const headerPriceBigClass = 'main-header__price-impact';

    const onCodeFailure = (error) => {
        ExceptionService.handle('error', {
            filename: filename,
            data: error
        });
    };

    const getTranslation = (key) => {
        return BUSUU_TRANSLATIONS[key] || 'TRS_NOT_FOUND';
    };

    const showHeaderPrice = () => {
        Utils.removeClass(headerPriceWrap, headerPriceWrapClass);
    };

    /**
     * Returns a constructed header string of 'From $x'
     * @param {Object} monthly details of plan
     * @return {String}
     */
    const getHeaderPriceString = (data) => {
        let priceText = getTranslation('FROM_PRICE');
        let priceHTML = '';
        let finalPrice = data.monthly.price_human;

        // If discounted, display original price and add strike through
        if (data.discount_percent) {
            finalPrice = data.monthly.price_discounted_human;
            const originalPrice = data.monthly.price_human;
            priceHTML = `<span class="${headerPriceStrikeClass}">${originalPrice}</span>`;
        }

        priceHTML += `<span class="${headerPriceBigClass}">${finalPrice}</span>`;

        return priceText.replace('%s', priceHTML);
    };

    /**
     * Inject the correct string into the header div
     * Call showHeaderPrice to fade in div
     * @param {Object} plan data
     */
    const appendHeaderPrice = (data) => {
        const headerPriceString = getHeaderPriceString(data);
        if (headerPriceString) {
            headerPrice.innerHTML = headerPriceString;
            showHeaderPrice();
        }
    };

    /**
     * On API call succes, we want to do 2 things:
     * - Filter the plans to keep the cheapest
     * - Append the correct string to the header
     * @param {Object} API response
     */
    const onSuccess = (response) => {
        const allPlans = PlansHelper.getAllProductsPlans(response);
        const cheapestPlan = PlansHelper.getCheapestPlan(allPlans);
        if (cheapestPlan) {
            appendHeaderPrice(cheapestPlan);
        } else {
            onCodeFailure({
                reason: 'Can\'t find cheapest plan',
                plans: data
            });
        }
    };

    // Get available plans
    const fetchPlans = () => {
        PlansService.getTieredPlans()
            .then(onSuccess);
    };

    const init = () => {

        // Get all the plans if element is found on page
        if (headerPriceWrap && headerPrice) {
            fetchPlans();
        }
    };

    init();

})(window, document);
