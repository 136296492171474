import Utils from 'core-helpers/utils.js';
import VoucherService from 'voucher/voucher-service.js';
import DiscountVoucherUI from 'forms/discount-voucher-class.js';

/**
 * The voucher form actually make a user premium
 * when the request to Backend is success. It's different from
 * the discount form where the user is suitable for a discount.
 * We redirect the user to the /purchase-success page when succeed.
 */

let voucherForm = Utils.getById('voucher-form');
let voucherInput = Utils.getById('voucher-input');
let voucherUI = null;

/**
 * XHR
 */
const onSuccess = () => {
    window.location.href = voucherForm.getAttribute('action');
};

const onError = (error = {}) => {
    let trs = null;
    switch (error.type) {
        case 'VOUCHER_NOT_FOUND':
            trs = 'INVALID_VOUCHER_CODE';
            break;
        case 'VOUCHER_ALREADY_USED_OR_EXPIRED':
        case 'INSTITUTION_CODE_ALREADY_USED_OR_EXPIRED':
            trs = 'VOUCHER_ALREADY_USED';
            break;
        case 'USER_ALREADY_PREMIUM':
            trs = 'VOUCHER_USER_ALREADY_PREMIUM';
            break;
        case 'INSTITUTION_NO_LICENCE_AVAILABLE':
            trs = 'ORGANISATION_RUN_OUT_LICENCES';
            break;
        default:
            trs = null;
    }
    voucherUI.showErrorAndEnableForm(trs);
};

const onRequestStart = () => {
    voucherUI.hideErrorsAndDisableForm();
};

const onFormSubmit = (e) => {
    e.preventDefault();
    onRequestStart();
    VoucherService.send(voucherInput.value)
        .then(onSuccess)
        .catch(onError);
};

const init = () => {

    if (!voucherForm) {
        return false;
    }

    voucherUI = new DiscountVoucherUI(Utils.getById('voucher-submit'), Utils.getById('voucher-error'));
    voucherForm.addEventListener('submit', onFormSubmit);
};

init();
