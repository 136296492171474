import Utils from 'core-helpers/utils.js';
import ItemChildHeightCalculator from 'components/item-child-height-calculator-class.js';
import ItemRotatorWithText from 'components/item-rotator-text-class.js';

const initImages = () => {
    const imagesWrapper = Utils.getById('preview');

    if (imagesWrapper) {
        const images = new ItemRotatorWithText(imagesWrapper, {
            interval: 4000,
            itemPrevClass: 'preview__image--overlaid',
            itemActiveClass: 'preview__image--active',
            itemNextClass: 'preview__image--next',
            indicatorActiveClass: 'preview__indicator--active',
            textActiveClass: 'preview__reason--active'
        });
        return images;
    }
};
const initHeight = () => {
    const textWrapper = Utils.getElementByClass('preview__reasons');

    if (textWrapper) {
        const newHeight = new ItemChildHeightCalculator(textWrapper, {
            calculateClass: 'preview__reason'
        });
        return newHeight;
    }
};

initImages();
initHeight();
