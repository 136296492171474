import Utils from 'core-helpers/utils.js';
import ConfigService from 'config/config-service.js';

// List of countries where the 'see on tv' banner must be
const COUNTRY_CODES_ALLOWED = ['DE', 'AT'];

const init = async() => {
    const cheggAnnouncement = Utils.getById('nav-pill-chegg');
    const seenOnTv = Utils.getById('nav-pill-tv');

    const showNavPill = (element) => {
        Utils.removeClass(element, 'hidden');
    };

    const determinePill = (countryCode) => {
        if (countryCode && COUNTRY_CODES_ALLOWED.includes(countryCode)) {
            showNavPill(seenOnTv);
        } else {
            showNavPill(cheggAnnouncement);
        }
    };

    if (seenOnTv) {
        const countryCode = await ConfigService.getCountryCode();
        determinePill(countryCode);
    } else if (cheggAnnouncement) {
        showNavPill(cheggAnnouncement);
    }
};

init();
