import Utils from 'core-helpers/utils.js';
import PlansHelper from 'core-helpers/plans-helper.js';
import endpoints from 'endpoint/endpoint-service.js';
import ExceptionService from 'core-services/exceptions/exception-service.js';
import APIRequestsService from 'endpoint/api-requests-service.js';
import { decomposeAxiosError, composeAxiosError } from 'endpoint/api-requests-service.helpers.js';
import { ERROR_BAD_RESPONSE_FORMAT, ERROR_PARAMS_MISSING, ERROR_TECHNICAL_ISSUE } from 'endpoint/api-requests-service.constants.js';

// Private vars
const filename = 'plans-service.js';

const ERROR_EMPTY_PLANS_LIST = {
    applicationCode: 'EMPTY_PLANS_LIST',
    message: ''
};

const ERROR_COUPON_UNAVAILABLE = {
    applicationCode: 'COUPON_UNAVAILABLE_OR_NOT_FOUND',
    message: ''
};

const logError = (message, error, type = 'warning') => {
    ExceptionService.handle(type, {
        filename,
        message,
        data: error
    });
};

/**
 * Removes plans products based on some conditions.
 * @param {Object} plansData
 * @return {Object}
 */
const filterOutPlans = (plansData) => {
    const data = Utils.deepClone(plansData);

    // Hide 6 month plan as no longer needed
    const planLengthToHide = 6;

    ['premium_standard', 'premium_plus'].forEach((tierType) => {
        data.plans[tierType] = PlansHelper.removePlanByCountry(data.plans[tierType], data.user.country);
        data.plans[tierType] = PlansHelper.removePlanByMonth(data.plans[tierType], planLengthToHide);
    });

    return data;
};

/**
 * Removes plans products based on some conditions and formats the response to match normal
 * tiered plans.
 * @param {Object} plansData
 * @return {Object}
 */
const filterOutAndFormatCustomPlans = (plansData, requestedPlans) => {
    const data = Utils.deepClone(plansData);

    // Hide 6 month plan as no longer needed
    const planLengthToHide = 6;

    requestedPlans.forEach((requestedPlan) => {
        ['premium_standard', 'premium_plus'].forEach((tierType) => {
            data.plans[requestedPlan][tierType] = PlansHelper.removePlanByCountry(data.plans[requestedPlan][tierType], data.user.country);
            data.plans[requestedPlan][tierType] = PlansHelper.removePlanByMonth(data.plans[requestedPlan][tierType], planLengthToHide);
        });
    });

    const { plans, ...restOfData } = data;
    return { ...plans, ...restOfData };
};

let getTieredPlansDeferred = null;

/**
 * Returns Busuu plans from `/api/plans`
 * @return {Promise}
 * @public
 */
const getTieredPlans = () => {
    if (getTieredPlansDeferred) {
        return getTieredPlansDeferred;
    }

    const onError = (error) => {
        const { applicationCode, message } = decomposeAxiosError(error);

        logError(`getTieredPlans() error: ${applicationCode} - ${message}`, error);

        throw {
            type: applicationCode,
            message: message
        };
    };

    const onSuccess = (response) => {
        if (!response || !response.data) {
            return onError(composeAxiosError(ERROR_BAD_RESPONSE_FORMAT));
        }

        const { premium_plus: premiumPlus, premium_standard: premiumStandard } = response.data.plans;

        if (!premiumPlus || !premiumStandard) {
            return onError(composeAxiosError(ERROR_EMPTY_PLANS_LIST));
        }

        return filterOutPlans(response.data);
    };

    const url = endpoints.getDiscountedEndpoint('availablePlans', 'availablePlansCoupon');

    getTieredPlansDeferred = APIRequestsService.get(url)
        .then(onSuccess)
        .catch(onError);

    return getTieredPlansDeferred;
};

const getTieredPlansCustomDeferred = {};

/**
 * Returns Busuu plans from `/api/plans/custom`
 * @return {Promise}
 * @public
 */
const getTieredPlansCustom = (requestedPlans = []) => {
    const onError = (error) => {
        logError('getTieredPlansCustom(): Failed to retrieve tiered plans', error);
        return Promise.reject(error);
    };

    const onSuccess = (response) => {
        if (!response || !response.data) {
            return onError({ type: 'BAD_JSON_FORMAT' });
        }

        const customPlanData = response.data.plans;

        for (let i = 0; i < requestedPlans.length; i += 1) {
            const { premium_plus: premiumPlus, premium_standard: premiumStandard } = customPlanData[requestedPlans[i]] || {};

            if (Boolean(!premiumPlus || !premiumStandard)) {
                return onError({ type: 'EMPTY_PLANS_LIST' });
            }
        }

        return Promise.resolve(filterOutAndFormatCustomPlans(response.data, requestedPlans));
    };

    if (!requestedPlans || !requestedPlans.length) {
        return onError({ type: 'MISSING_PARAMS' });
    }

    const planKey = requestedPlans.join('|');

    if (getTieredPlansCustomDeferred[planKey]) {
        return getTieredPlansCustomDeferred[planKey];
    }

    const url = endpoints.getDiscountedEndpoint('availablePlansCustom', 'availablePlansCustomCoupon');
    const data = { requested_plans: requestedPlans };

    getTieredPlansCustomDeferred[planKey] = APIRequestsService.post(url, data)
        .then(onSuccess)
        .catch(onError);

    return getTieredPlansCustomDeferred[planKey];
};

/**
 * Returns a discount for a given coupon and its validity
 * @param  {String} inputValue - value from the text input
 * @return {Promise}
 * @public
 */
const getCouponValidity = (inputValue) => {
    const coupon = inputValue ? Utils.clean(inputValue) : null;

    const onError = (error = {}) => {
        const { applicationCode, message } = decomposeAxiosError(error, ERROR_TECHNICAL_ISSUE);

        logError(`getCouponValidity() error: ${applicationCode} - ${message}`, error);

        return Promise.reject({
            type: applicationCode,
            message: message
        });
    };

    const onSuccess = (response) => {
        if (!response || !response.data) {
            return onError(composeAxiosError(ERROR_BAD_RESPONSE_FORMAT));
        }

        if (!response.data.can_i_use) {
            return onError(composeAxiosError(ERROR_COUPON_UNAVAILABLE));
        }

        return {
            ...response.data,
            coupon
        };
    };

    if (!coupon) {
        return onError(composeAxiosError(ERROR_PARAMS_MISSING));
    }

    const url = endpoints.generateEndpoint('discountCoupon', 'paymentAPI')
        .replace('{coupon}', coupon);

    return APIRequestsService.get(url)
        .then(onSuccess)
        .catch(onError);
};

const PlansService = {
    __test: {
        filterOutPlans,
        filterOutAndFormatCustomPlans
    },
    getTieredPlans,
    getTieredPlansCustom,
    getCouponValidity
};

export default PlansService;
