import Utils from 'core-helpers/utils.js';
import PlansService from 'premium/plans-service.js';
import SharedDataService from 'data/shared-data-service.js';
import DiscountVoucherUI from 'forms/discount-voucher-class.js';
import eCommerceService from 'core-services/ecommerce-origin-service.js';

/**
 * The discount form request a discount value
 * to the Backend using the plans API.
 * If success, we store the discount and redirect
 * the user to the /n/plans page (/premium).
 * It's different from the voucher form where
 * it actually make a user premium straight after it.
 */

let discountForm = Utils.getById('discountForm');
let discountInput = Utils.getById('discountInput');
let discountUI = null;

/**
 * XHR
 */
const onSuccess = (response) => {
    SharedDataService.storeDiscountCoupon(response.coupon);
    eCommerceService.store('DISCOUNT_COUPON_FORM');
    window.location.href = discountForm.getAttribute('action');
};

const onError = (error = {}) => {
    let trs = null;
    switch (error.type) {
        case 'COUPON_MISSING':
            trs = 'FIELD_REQUIRED';
            break;
        case 'COUPON_API_RESPONSE_MALFORMED':
        case 'COUPON_UNAVAILABLE_OR_NOT_FOUND':
        case 'COUPON_API_CALL_FAILED':
        default:
            trs = 'NOT_VALID_DISCOUNT_CODE';
            break;
    }
    discountUI.showErrorAndEnableForm(trs);
};

const onRequestStart = () => {
    discountUI.hideErrorsAndDisableForm();
};

const onFormSubmit = (e) => {
    e.preventDefault();
    onRequestStart();
    PlansService.getCouponValidity(discountInput.value)
        .then(onSuccess)
        .catch(onError);
};

const init = () => {

    if (!discountForm) {
        return false;
    }

    discountUI = new DiscountVoucherUI(Utils.getById('discountSubmit'), Utils.getById('discountError'));
    discountForm.addEventListener('submit', onFormSubmit);
};

init();
