/**
 * Common Scripts
 */
import 'common/common-scripts.js';

/**
 * Modular Specific Scripts
 */
import 'components/sliders-init.js';
import 'components/video-loader.js';
import 'components/discount-banner.js';
import 'components/tab-carousel.js';
import 'modules/choose-your-language.js';
import 'iframes/iframe-loader.js';
import 'premium/premium-header-controller.js';
import 'third-party/script-trustpilot.js';
import 'third-party/script-zendesk.js';
import 'modules/fade-in.js';
import 'modules/faq-module.js';
import 'modules/referral-header.js';
import 'tracking/pages/tracking-pages.js';
import 'tracking/pages/tracking-premium.js';
import 'tracking/pages/tracking-claim-trial.js';
import 'live-lessons-table/live-lessons-table.js';
import 'components/app-links.js';
import 'modules/months-free-landing-header.js';
import 'modules/main-header-wave.js';
import 'modules/app-preview.js';
import 'modules/app-preview-2.js';
import 'modules/reviews.js';
import 'modules/free-trial-benefits.js';
import 'voucher/voucher-controller.js';
import 'discount/discount-controller.js';
import 'modules/thin-info-banner.js';
import 'components/navigation-pill-announcement.js';
import { init as initTieredPlans } from 'premium/tiered-plans-controller.js';
import { initLiveHeaderNav } from 'live/landing-page/live-header/live-header-nav.js';

// Init the tiered plans
initTieredPlans();

// Init Live nav
initLiveHeaderNav();
