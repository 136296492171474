import Utils from 'core-helpers/utils.js';
import TranslationsService from 'common/translations.service.ts';
const GENERIC_HIDDEN_CLASS = 'hidden';
/**
 * Class providing a dynamic flags list
 * Responsible for fetching available languages and toggling the loader once ready
 */
export default class FlagsListClass {
    constructor(args) {
        this.initFlagsList = this.initFlagsList.bind(this);
        this.createFlagElement = this.createFlagElement.bind(this);
        this.hideElement = this.hideElement.bind(this);
        this.showElement = this.showElement.bind(this);
        this.initFlagsList(args);
    }
    hideElement(elem) {
        Utils.addClass(elem, GENERIC_HIDDEN_CLASS);
    }
    showElement(elem) {
        Utils.removeClass(elem, GENERIC_HIDDEN_CLASS);
    }
    createFlagElement(lang, baseRegisterUrl) {
        const registerUrl = baseRegisterUrl.replace('{LANGUAGE_CODE}', lang);
        const langTrs = TranslationsService.getTranslation(`LANGUAGE_${lang}`);
        const flagSrc = `${process.env.staticRoot}/img/icons/flags/icon_flag-${lang}.svg`;
        const chevronSrc = `${process.env.staticRoot}/img/icons/icon_chevron-quaternary.svg`;
        return (`<a href="${registerUrl}" class="flag-text js-flag" data-value="${lang}">
                <span class="icon icon--circle flag-text__icon">
                    <img src="${flagSrc}" alt="${langTrs}"/>
                </span>
                <p class="flag-text__text">${langTrs}</p>
                <span class="icon flag-text__chevron icon--quaternary-base">
                    <img src="${chevronSrc}" />
                </span>
            </a>`);
    }
    initFlagsList({ languages, loader, container }) {
        const baseRegisterUrl = container.getAttribute('data-register-url');
        languages.forEach((lang) => {
            const flagElem = this.createFlagElement(lang, baseRegisterUrl);
            container.insertAdjacentHTML('beforeend', flagElem);
        });
        setTimeout(() => {
            this.hideElement(loader);
            this.showElement(container);
        }, 500);
    }
}
