export const ALL_USER_FREE_TRIAL = 'ALL_USER_FREE_TRIAL';
export const ALL_USER_FREE_TRIAL_ORIGINAL = 'A';
export const ALL_USER_FREE_TRIAL_VARIATION = 'B';
export const LIVE_GROUP_NAV_LINK = 'LIVE_GROUP_NAV_LINK';
export const LIVE_GROUP_NAV_LINK_ORIGINAL = 'A';
export const LIVE_GROUP_NAV_LINK_VARIATION = 'B';
export const PAYWALL_CUSTOMIZED_BY_LANGUAGE_LEARNT = 'PAYWALL_CUSTOMIZED_BY_LANGUAGE_LEARNT';
export const PAYWALL_CUSTOMIZED_BY_LANGUAGE_LEARNT_ORIGINAL = 'A';
export const PAYWALL_CUSTOMIZED_BY_LANGUAGE_LEARNT_VARIATION = 'B';
const experiments = {
    [ALL_USER_FREE_TRIAL]: {
        experimentId: '19653920650',
        variations: {
            19574801875: ALL_USER_FREE_TRIAL_ORIGINAL,
            19584734574: ALL_USER_FREE_TRIAL_VARIATION
        }
    },
    [LIVE_GROUP_NAV_LINK]: {
        experimentId: '20610290278',
        variations: {
            20623550127: LIVE_GROUP_NAV_LINK_ORIGINAL,
            20630220107: LIVE_GROUP_NAV_LINK_VARIATION
        }
    },
    [PAYWALL_CUSTOMIZED_BY_LANGUAGE_LEARNT]: {
        experimentId: '20871750150',
        variations: {
            20880010097: PAYWALL_CUSTOMIZED_BY_LANGUAGE_LEARNT_ORIGINAL,
            20887480165: PAYWALL_CUSTOMIZED_BY_LANGUAGE_LEARNT_VARIATION
        }
    }
};
export default experiments;
