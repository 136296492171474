/**
 * Create a url with the attached query parameters
 * @param {string} url
 * @param {object} queryParams
 *
 * @example
 * // will output '/test?param1=foo'
 * createUrlWithQueryParams('/test', { param1: 'foo' })
 */
const createUrlWithQueryParams = (url, queryParams = {}) => {
    if (!Object.keys(queryParams).length) {
        return url;
    }

    const params = Object.entries(queryParams).map(([key, value]) => `${key}=${value}`).join('&');
    return `${url}?${params}`;
};

/**
 * Redirect to a url
 * @param {string} url
 * @param {object} queryParams
 */
const redirect = (url, queryParams = {}) => {
    window.location.href = createUrlWithQueryParams(url, queryParams);
};

const WindowLocationService = {
    createUrlWithQueryParams,
    redirect
};

export default WindowLocationService;
