import Tracking from 'tracking/tracking-controller.ts';
import QueryParameters from 'helpers/query-parameters.js';

/**
 * Tracking Common is used to send some tracking accross
 * all the application. There's not really any condition here.
 * Let's say tomorrow you need an event to know when the current
 * user is authenticated, this could live here.
 * NB: As a reminder, each event sent through the third-party
 * tracking services always tries to send the user data along the event.
 */
(function(root, doc, undefined) {

    const getPageURL = () => {
        return root.location.href;
    };

    const getReferrer = () => {
        return doc.referrer || null;
    };

    const sendCJAffiliateParameter = () => {
        const cjeventValue = QueryParameters.getCJAffiliateParameter();
        if (cjeventValue) {
            Tracking.send('CJ_AFFILIATE_COOKIE', {
                cj_event_token: cjeventValue
            });
        }
    };

    /**
     * Event sent after the page is initialized
     */
    const sendPageInit = () => {
        Tracking.send('PAGE_INIT', {
            page_url: getPageURL(),
            referrer_url: getReferrer()
        });
    };

    // Events list to fire
    sendPageInit();
    sendCJAffiliateParameter();

})(window, document);
