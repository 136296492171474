import TranslationsService from 'common/translations.service.ts';
import FreeTrialBannerBase from 'free-trial/free-trial-banner/free-trial-banner-base.js';

class FreeTrialBannerReferral extends FreeTrialBannerBase {
    onInit({ freeTrialDays }) {
        const content = TranslationsService.getAndReplaceTranslationMulti('CLAIM_YOUR_X_DAYS_OF_PREMIUM', [freeTrialDays]);

        if (freeTrialDays > 0) {
            this.updateContent(content);
            this.show();
        }
    }
}

export default FreeTrialBannerReferral;
