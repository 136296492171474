import Utils from 'core-helpers/utils.js';
const DIMISS_CTA_CLASS = 'js-base-modal-dimiss';
const MODAL_OPEN_CLASS = 'modal--open';
/**
 * A reusable class that implements showing and hiding of a modal element
 */
class DefaultBaseModal {
    constructor(modal, options = {}) {
        /**
         * Dismiss the modal
         */
        this.onClickDismiss = (e) => {
            if (e) {
                e.preventDefault();
            }
            const { onClickDismiss } = this.options;
            this.hide();
            if (onClickDismiss) {
                onClickDismiss();
            }
        };
        /**
         * Show the page modal
         */
        this.show = () => {
            Utils.addClass(this.modalEl, MODAL_OPEN_CLASS);
        };
        /**
         * Hide the page modal
         */
        this.hide = () => {
            Utils.removeClass(this.modalEl, MODAL_OPEN_CLASS);
        };
        this.options = options;
        this.modalEl = modal;
        // @ts-expect-error - TODO: Utils auto completes to document type. Change to HTMLElement
        this.dismissEl = Utils.getElementByClass(DIMISS_CTA_CLASS, modal);
        if (this.modalEl && this.dismissEl) {
            this.dismissEl.addEventListener('click', this.onClickDismiss);
        }
    }
}
export default DefaultBaseModal;
