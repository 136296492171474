import Utils from 'core-helpers/utils.js';
import ConfigService from 'config/config-service.js';

// List of countries where the promo banner should be shown
const COUNTRY_CODES_ALLOWED = ['DE', 'AT'];

const init = async() => {
    const infoBannerWrapper = Utils.getById('hp-promo-banner');

    if (infoBannerWrapper) {
        const countryCode = await ConfigService.getCountryCode();

        if (countryCode && COUNTRY_CODES_ALLOWED.includes(countryCode)) {
            Utils.removeClass(infoBannerWrapper, 'hidden');
        }
    }
};

init();
