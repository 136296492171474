/* eslint-disable no-new */

import Utils from 'core-helpers/utils.js';
import ItemRotator from 'components/item-rotator-class.js';
import ConfigService from 'config/config-service.js';
import { countriesData, countriesDefault } from 'country-customisation/country-custom.constants.js';

const HEADER_ROTATION_INTERVAL = 3000;

const initBGImageRotator = () => {
    const imagesWrapper = Utils.getElementByClass('js-bg-wrap');
    const images = Utils.getElementsByClass('js-header-img');

    if (imagesWrapper && images.length > 1) {
        new ItemRotator(imagesWrapper, {
            interval: HEADER_ROTATION_INTERVAL,
            itemPrevClass: 'main-header-wave__background-img--prev',
            itemActiveClass: 'main-header-wave__background-img--active',
            itemNextClass: 'main-header-wave__background-img--next'
        });
    }
};

const createImgElement = () => {
    const newImg = document.createElement('img');

    Object.assign(newImg, {
        className: 'main-header-wave__background-img fluid-content__item js-item-rotator__item js-header-img',
        height: 490,
        width: 640,
        alt: 'learn-languages'
    });

    return newImg;
};

const initBGHeader = () => {
    let imagesWrapper = Utils.getById('images-wrapper');

    const onSuccess = (countryCode) => {
        const imgBaseSrc = `${process.env.staticRoot}/img/design/homepage-2020/header`;
        const imagesArray = countriesData[countryCode] ? countriesData[countryCode].images : countriesDefault.images;

        imagesArray.forEach(image => {
            const newImage = createImgElement();
            newImage.src = `${imgBaseSrc}/${image}`;
            imagesWrapper.appendChild(newImage);
        });
    };

    if (!imagesWrapper) {
        initBGImageRotator();
        return Promise.reject();
    }

    return ConfigService.getCountryCode()
        .catch(() => {})
        .then(onSuccess)
        .then(initBGImageRotator);
};

export { initBGHeader };
