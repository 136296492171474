import Utils from 'core-helpers/utils.js';
import _ from 'underscore';
import BreakPointDetection from 'helpers/breakpoint-detection.js';
import PubSub from 'pubsub-js/src/pubsub.js';

(function(root, doc, undefined) {

    const isDesktopViewport = (screenState) => {
        return screenState === 4;
    };

    const isLoaded = () => {
        return videoLoaded;
    };

    const setLoaded = () => {
        videoLoaded = true;
    };

    /**
     * Returns if the browser supports the given mimeType
     * @param {HTMLMediaElement} element
     * @param {string} mimeType
     */
    function supportsVideoFormat(element, mimeType) {
        return element.canPlayType(mimeType) === 'probably';
    }

    /**
     * Returns webm if it's supported, otherwise tries with mp4, if it's not
     * returns false
     */
    const getSupportedVideoExtension = () => {
        const videoObj = document.createElement('video');
        const webmMimeType = videoElement.getAttribute('data-webm-codec');
        const mp4Baseline = videoElement.getAttribute('data-mpfour-codec');

        if (webmMimeType && supportsVideoFormat(videoObj, webmMimeType)) {
            return 'webm';
        }

        if (mp4Baseline && supportsVideoFormat(videoObj, mp4Baseline)) {
            return 'mpfour';
        }

        return false;
    };

    /**
     * Returns the url for the supported extension
     */
    const getVideoUrl = () => {
        const supportedExtension = getSupportedVideoExtension();
        if (!supportedExtension) {
            return false;
        }
        return videoElement.getAttribute(`data-${supportedExtension}`);
    };

    /**
     * Setup the video element from a given blob
     * @param {blob} blob
     */
    const setupVideoElement = (blob) => {
        videoElement.src = videoUrl;
        videoElement.setAttribute('autoplay', true);
        videoElement.setAttribute('loop', true);
        setLoaded();
    };

    /**
     * Setup the request to be processed to obtain the blob of the video
     * @param {string} url
     */
    const setupRequest = (url) => {
        const request = new XMLHttpRequest();
        request.open('GET', url);
        request.responseType = 'blob';
        return request;
    };

    /**
     * Send request to load the video
     * @param {number} screenState
     */
    const loadVideo = (screenState) => {
        if (!isDesktopViewport(screenState) || isLoaded()) {
            return false;
        }

        const videoRequest = setupRequest(videoUrl);

        videoRequest.onreadystatechange = function() {
            const requestSuccessful = (videoRequest.readyState === videoRequest.DONE && videoRequest.status === 200);

            if (requestSuccessful) {
                setupVideoElement(videoRequest.response);
            }
        };

        videoRequest.send();
    };

    const init = () => {
        if (!videoElement) {
            return false;
        }

        videoUrl = getVideoUrl();

        if (videoUrl) {
            loadVideo(BreakPointDetection.getState());
            PubSub.subscribe('BREAKPOINT_DETECTION__CHANGED', loadVideo);
        }
    };

    // Private vars
    let videoLoaded = false;
    const videoElement = Utils.getById('main-header-video');
    let videoUrl = null;

    // init
    init();

}(window, document));
