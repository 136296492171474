import Utils from 'core-helpers/utils.js';

/**
 * Returns the institution parameters if present in the URL.
 * @return {Object}
 */
const getInstitutionParameters = () => {
    const code = Utils.getParameter('institution_code');
    const name = Utils.getParameter('institution_name');
    if (!code || !name) {
        return {};
    }
    return {
        institution_code: code,
        institution_name: name
    };
};

/**
 * Returns referral token code if it is present in the URL.
 * @return {Object}
 */
const getReferralParameters = () => {
    const referralCode = Utils.getParameter('referral_code');
    const referralExerciseCode = Utils.getParameter('exercise_code');

    if (!referralCode && !referralExerciseCode) {
        return null;
    }

    return {
        referral_code: referralCode,
        exercise_code: referralExerciseCode
    };
};

/**
 * CJ Affiliate Partnership
 * Source: https://busuucom.atlassian.net/wiki/spaces/partners/pages/1715863704/Affiliate+program+-+CJ
 */
const getCJAffiliateParameter = () => {
    return Utils.getParameter('cjevent');
};

/**
 * Differentiates registration origin
 * Added on last touchpoints of onboarding in Live for new users
 */
const getRegistrationOriginParameter = () => {
    return Utils.getParameter('registration_origin');
};

/**
 * Returns the mobile authentication flow
 * query parameter if present.
 * @return {String|null}
 */
const getMobileAuthParameter = () => {
    return Utils.getParameter('auth-type');
};

const QueryParameters = {
    getInstitutionParameters,
    getReferralParameters,
    getCJAffiliateParameter,
    getRegistrationOriginParameter,
    getMobileAuthParameter
};

export default QueryParameters;
