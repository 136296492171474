import Utils from 'core-helpers/utils.js';
import ExceptionService from 'core-services/exceptions/exception-service.js';
import AdjustScript from 'core-modules/script-adjust.js';
import AdjustEvents from 'core-services/tracking/adjust-service.events.js';

/**
 * Private vars
 */
const ADJUST_APP_TOKEN = 'w1nm4welnnk0';
const filename = 'tracking-adjust.js';

let enableLogs = false;
let enableOptOut = false;
let commonData = {}; // basic data send through all tracking

/**
 * Checks if Adjust is present on window object
 * @return {Boolean}
 */
const isLoaded = () => {
    if (!window.Adjust) {
        ExceptionService.handle('warning', {
            filename: filename,
            message: 'Adjust is not found on the window.'
        });
    }
    return Boolean(window.Adjust);
};

/**
 * Prevents tracking if user is on a localhost environment.
 * @return {Boolean}
 */
const preventTracking = (eventName, opts) => {
    if (enableLogs) {
        ExceptionService.handle('warning', {
            filename: filename,
            message: 'TRACKING Adjust: ' + eventName,
            data: opts
        });

        return false;
    }

    return enableOptOut || Utils.isLocalEnvironment();
};

/**
 * Add additional datas to the tracking when the user
 * is authenticated
 * @param {Object} user
 */
const identifyUser = (user) => {
    commonData = Object.assign(commonData, {
        userId: user.uid
    });
};

/**
 * Add additional visitor id which can be added to adjust callback
 * @param {String} optimizelyVisitorId
 */
const identifyOptimizelyVisitor = (optimizelyVisitorId) => {
    commonData = Object.assign(commonData, {
        optimizelyVisitorId
    });
};

/**
 * Convert `dataArr` to key value pair objects for adjust `callbackParams`
 * @param {Object[]} dataArr Array of objects to be converted to key value
 * @return {[{ key: String, value: String }]}
 */
const getCallbackParams = (dataArr) => {
    const callbackEntries = dataArr.reduce((arr, item) => {
        return arr.concat(Object.entries(item));
    }, []);

    return callbackEntries.map(([key, value]) => ({
        key,
        value: `${value}`
    }));
};

/**
 * Builds and returns the params Object sent to Adjust.
 * If an event isn't "Adjust" ready, returns null.
 * @param  {Object} eventTemplate
 * @param  {Object} data
 * @return {Object}
 */
const getEventParams = (eventTemplate, data) => {
    const adjustData = eventTemplate.adjust;

    if (!adjustData) {
        return null;
    }

    const eventParams = Boolean(AdjustEvents[adjustData.type])
        ? AdjustEvents[adjustData.type](data)
        : {};

    const params = {
        ...eventParams,
        eventToken: adjustData.token,
        callbackParams: getCallbackParams([
            data,
            commonData
        ])
    };

    return params;
};

const sendEvent = (eventTemplate = {}, data = {}) => {
    const eventName = eventTemplate.real_event_name;

    if (!eventName) {
        ExceptionService.handle('error', {
            filename: filename,
            message: 'sendEvent() - eventName is missing'
        });
        return false;
    }

    const params = getEventParams(eventTemplate, data);

    if (!isLoaded() || !params || preventTracking(eventName, params)) {
        return false;
    }

    Adjust.trackEvent(params);
};

const getAdjustEnvironment = () => {
    return Utils.isProduction() ? 'production' : 'sandbox';
};

const getAdjustLogLevel = () => {
    return enableLogs ? 'verbose' : 'none';
};

const setup = () => {
    if (isLoaded()) {
        Adjust.initSdk({
            appToken: ADJUST_APP_TOKEN,
            environment: getAdjustEnvironment(),
            logLevel: getAdjustLogLevel()
        });
    }
};

const load = (params = {}) => {
    if (params.logs) {
        enableLogs = true;
    }
    if (params.optOut) {
        enableOptOut = true;
    }

    if (!enableOptOut) {
        AdjustScript.init(setup);
    }
};

const AdjustService = {
    sendEvent,
    load,
    isLoaded,
    identifyUser,
    identifyOptimizelyVisitor
};

export default AdjustService;
