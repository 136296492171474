import BrowserStorageService from 'core-services/browser-storage-service.js';
import Utils from 'core-helpers/utils.js';

/**
 * Stores captcha token if found as url query param
 * @private
 */
export const storeCaptchaBypassToken = () => {
    const captchaToken = Utils.getParameter('captcha_token');

    if (captchaToken) {
        BrowserStorageService.set('CAPTCHA_TOKEN', captchaToken);
    }
};
