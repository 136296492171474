import Utils from 'core-helpers/utils.js';

/**
 * A class for interacting with flags on the page and providing the associated lang code.
 * Should be used with `flags-list.html.twig`
 *
 * @example
 * const instance = new FlagInteractor(element, {
 *     onMouseEnter: (langCode) => {
 *         console.log(langCode); // Example: en, de etc
 *     })
 * });
 *
 * <div id="element-wrapper">
 *     {% include 'components/flags-list.html.twig' %}
 * </div>
 */
class FlagInteractor {

    /**
     * Initialize the flag interactor
     * @param {Element} flagWrapperElement - The element containing the flags
     * @param {Object} options
     * @param {Function} options.onMouseEnter
     * @param {Function} options.onMouseLeave
     * @param {Function} options.onClick
     */
    constructor(flagWrapperElement, options = {}) {
        this.flagWrapperElement = flagWrapperElement;
        this.options = options;
        this.flags = [];

        if (this.flagWrapperElement) {
            this.initInteractionEvents();
        }

        return this.flags;
    }

    getAttributeFromEl(el, attr) {
        return el.getAttribute(attr);
    }

    initInteractionEvents() {
        const {
            flagClass, onMouseEnter, onMouseLeave, onClick
        } = this.options;

        this.flags = Utils.getElementsByClass(flagClass, this.flagWrapperElement);

        this.flags.forEach((flag) => {
            const lang = this.getAttributeFromEl(flag, 'data-value');

            if (onMouseEnter) {
                flag.addEventListener('mouseenter', this.handleMouseEnter.bind(this, lang, flag));
            }

            if (onMouseLeave) {
                flag.addEventListener('mouseleave', this.handleMouseLeave.bind(this, lang, flag));
            }

            if (onClick) {
                flag.addEventListener('click', this.handleClick.bind(this, lang, this.flags, flag));
            }
        });
    }

    handleMouseEnter(value, flag) {
        const { onMouseEnter } = this.options;

        if (onMouseEnter) {
            onMouseEnter(value, flag);
        }
    }

    handleMouseLeave(value, flag) {
        const { onMouseLeave } = this.options;

        if (onMouseLeave) {
            onMouseLeave(value, flag);
        }
    }

    /**
     * Handles a click event of a flag
     * `activeClass` can be passed as an optional param
     * which will be applied to the clicked flag.
     * @param {String} value
     * @param {Array} flags
     * @param {Object} flag
     */
    handleClick(value, flags, flag) {
        const { onClick, activeClass } = this.options;

        // Append active class to clicked flag
        if (activeClass) {
            flags.forEach((_flag) => {
                Utils.removeClass(_flag, activeClass);
                const flagLang = _flag.getAttribute('data-value');
                if (flagLang === value) {
                    Utils.addClass(_flag, activeClass);
                }
            });
        }

        if (onClick) {
            onClick(value, flag);
        }
    }
}

export default FlagInteractor;
