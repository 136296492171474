import { debounce } from 'underscore';

/**
 * Class that listens to the scroll event on the window.
 * Given a number, it will invoke the given callback with status parameters.
 *
 * @example
 * const instance = new VerticalScrollTransition(distanceFromTop, callback);
 */

class VerticalScrollTransition {
    /**
     * Initializes the styling changes to
     * @param {Number} distance from top in px
     * @param {Function} callback to update on scroll status
     */
    constructor(distanceFromTop, callback) {
        this.distanceFromTop = distanceFromTop;
        this.callback = callback;

        this.calculateYScrollDistance = this.calculateYScrollDistance.bind(this);
        this.destroy = this.destroy.bind(this);

        // Calculate the Y distance as soon as it's mounted
        this.calculateYScrollDistance();

        // Assign method to reference so it can be later destroyed
        this.initScroll = debounce(this.calculateYScrollDistance, 10);
        window.addEventListener('scroll', this.initScroll);
    }

    calculateYScrollDistance() {
        if (window.scrollY >= this.distanceFromTop) {
            this.callback({ scrolled: true });
        }

        if (window.scrollY < this.distanceFromTop) {
            this.callback({ scrolled: false });
        }
    }

    destroy() {
        window.removeEventListener('scroll', this.initScroll);
    }
}

export default VerticalScrollTransition;
