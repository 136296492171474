import Utils from 'core-helpers/utils.js';
import PlansHelper from 'core-helpers/plans-helper.js';
import FreeTrialService from 'core-services/free-trial/free-trial.service.js';
import CurrentUser from 'user/current-user-service.js';
import PlansService from 'premium/plans-service.js';
import PurchaseService from 'purchase/purchase-service.js';
import eCommerceService from 'core-services/ecommerce-origin-service.js';
import ExceptionService from 'core-services/exceptions/exception-service.js';
import WindowLocationService from 'common/window-location.service.js';
import FreeTrialExperiment from 'experiments/free-trial-experiment.js';
import {
    FT_MONTHS, FT_TIER, FT_PLAN_TYPE_REFERRAL, FT_PLAN_TYPE_BASIC, FT_PLAN_TYPES, FT_TYPE_BASIC, FT_TYPE_REFERRAL
} from 'core-services/free-trial/free-trial.constants.js';
import FreeTrialBannerReferral from 'free-trial/free-trial-banner/free-trial-banner-referral.js';
import FreeTrialBannerBasic from 'free-trial/free-trial-banner/free-trial-banner-basic.js';

/**
 * Private vars
 */
const filename = 'free-trial-banner-controller.js';

/**
 * The full backend response for current user data.
 */
let plans = {};

/**
 * We need a pointer to know which plan has trial
 */
let freeTrialPlan = null;

const storePlans = (plansData) => {
    plans = Utils.deepClone(plansData);
    return plansData;
};

const onError = (error) => {
    ExceptionService.handle('warning', {
        filename: filename,
        message: 'Tiered plans could not be loaded for free claim trial banner',
        data: error
    });
};

const checkIfUserIsAuthenticated = (plansData) => {
    if (PlansHelper.isUserAuthenticated(plansData)) {
        return CurrentUser.get();
    }

    throw {
        reason: 'USER_NOT_AUTHENTICATED'
    };
};

const loadFreeTrialBanner = (user) => {
    const trialType = FreeTrialService.getFreeTrialType(user);

    if (trialType === FT_TYPE_BASIC && FreeTrialExperiment.isInExperiment()) {
        return loadBasicBanner();
    }

    if (trialType === FT_TYPE_REFERRAL) {
        return loadReferralBanner();
    }

    throw {
        reason: 'UNIDENTIFIED_TRIAL_TYPE'
    };
};

const loadReferralBanner = () => {
    const trialPlan = PlansHelper.findFreeTrialPlanByMonth(plans[FT_PLAN_TYPE_REFERRAL][FT_TIER], FT_MONTHS);

    if (trialPlan) {
        freeTrialPlan = PlansHelper.getFormattedPlan(trialPlan);

        new FreeTrialBannerReferral(freeTrialPlan, {
            onClickCTA: onTrialSelection.bind(this, FT_PLAN_TYPE_REFERRAL, 'CLAIM_REFERRAL_FT_PAYWALL_BANNER')
        });
    }
};

const loadBasicBanner = () => {
    const trialPlan = PlansHelper.findFreeTrialPlanByMonth(plans[FT_PLAN_TYPE_BASIC][FT_TIER], FT_MONTHS);

    if (trialPlan) {
        freeTrialPlan = PlansHelper.getFormattedPlan(trialPlan);

        new FreeTrialBannerBasic(freeTrialPlan, {
            onClickCTA: onTrialSelection.bind(this, FT_PLAN_TYPE_BASIC, 'CLAIM_BASIC_FT_PAYWALL_BANNER')
        });
    }
};

const onTrialSelection = (planType, ecommerceOrigin, href) => {
    const freeTrialPlansData = { ...plans[planType], user: plans.user };
    const storagePlan = PlansHelper.getValidStoragePlan(freeTrialPlansData, freeTrialPlan);

    if (storagePlan.plan.hasFreeTrial) {
        PurchaseService.storePlanForPayment(plans, storagePlan.plan);
        eCommerceService.store(ecommerceOrigin);
        WindowLocationService.redirect(href);
    }
};

const init = () => {
    PlansService.getTieredPlansCustom(FT_PLAN_TYPES)
        .then(storePlans)
        .then(checkIfUserIsAuthenticated)
        .then(loadFreeTrialBanner)
        .catch(onError);
};

const FreeTrialBanner = {
    init
};

export default FreeTrialBanner;
