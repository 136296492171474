import SharedDataService from 'data/shared-data-service.js';
const COOKIES_OPTED_OUT = SharedDataService.getCookiesOptOut();
/**
 * Has the user dismissed the cookies banner
 */
export const isBannerDismissed = () => {
    return Boolean(SharedDataService.getCookiesBannerDismissed());
};
/**
 * Has the user allowed us to store cookies
 */
export const hasUserOptedOut = () => {
    return COOKIES_OPTED_OUT;
};
/**
 * Store the dismissed banner state and if the user allows cookies
 */
export const setOptOutAndDismissBanner = (optOutCookies) => {
    SharedDataService.setCookiesOptOut(optOutCookies);
    SharedDataService.setCookiesBannerDismissed(true);
};
