import Utils from 'core-helpers/utils.js';
import AdjustService from 'core-services/tracking/adjust-service.js';
import GTM from 'tracking/tracking-gtm.js';
import Optimizely from 'tracking/tracking-optimizely.js';
import Snowplow from 'tracking/tracking-snowplow.js';
import TrackingEventsConstants from 'tracking/tracking-constants';
import SharedDataService from 'data/shared-data-service.js';
import CurrentUser from 'user/current-user-service.js';
import ExceptionService from 'core-services/exceptions/exception-service.js';
import QueryParameters from 'helpers/query-parameters.js';
import { hasUserOptedOut } from 'components/banners/cookie-banner-service';
// Vars
let currentUserID = null;
const trackingProviders = [
    { name: 'GTM', provider: GTM, logs: false },
    { name: 'Optimizely', provider: Optimizely, logs: false },
    { name: 'Snowplow', provider: Snowplow, logs: false },
    { name: 'Adjust', provider: AdjustService, logs: false }
];
/**
 * Returns an event template from an event name
 * @private
 */
const getEventTemplate = (eventName) => {
    const eventTemplate = TrackingEventsConstants[eventName];
    return eventTemplate;
};
/**
 * Extends the event parameters with common params.
 * @private
 */
const extendWithCommonData = (params) => {
    return Object.assign(Object.assign({}, Utils.deepClone(params)), Utils.deepClone(QueryParameters.getInstitutionParameters()));
};
/**
 * Checks if we can send the event to the tracking service
 */
const canSendEvent = (eventTemplate, trackingServiceName) => {
    const isExcluded = eventTemplate.exclusions.includes(trackingServiceName);
    return !isExcluded;
};
/**
 * Send an event to all tracking provider
 * @private
 */
const sendEvent = (eventName, data) => {
    const eventTemplate = getEventTemplate(eventName);
    const params = extendWithCommonData(data);
    trackingProviders.forEach(({ name, provider }) => {
        if (canSendEvent(eventTemplate, name)) {
            provider.sendEvent(eventTemplate, params);
        }
    });
};
/**
 * When user is recognised, we add extra data to trackers.
 * We add a logic to avoid multiple authentications
 * based on the current user id. In some cases this ID can change
 * and a new user needs to be identified. (e.g.: registration)
 * @private
 */
const identifyUser = (user) => {
    if (currentUserID === user.uid) {
        return false;
    }
    // Add Sentry user context
    ExceptionService.identifyUser(user);
    trackingProviders.forEach(({ provider }) => {
        provider.identifyUser(user);
        if (provider.identifyOptimizelyVisitor) {
            provider.identifyOptimizelyVisitor(Optimizely.getVisitorId());
        }
    });
    // Store userID to avoid multiple authentications
    currentUserID = user.uid;
};
/**
 * Middleware wrapper before sending events.
 * This function tries to get some information
 * about the user first and then send the event,
 * so we can ensure that we have user information
 * along with the event when it's available.
 * @public
 */
const send = (eventName, data = {}) => {
    const onComplete = () => {
        sendEvent(eventName, data);
    };
    CurrentUser.get()
        .then(identifyUser)
        .catch(() => { })
        .then(onComplete);
};
/**
 * Shortcut handler for send() that wraps a promise
 * that always resolves. It ensures the tracking has been
 * sent using a delay timeout to let enough time
 * for the tracking request to process.
 * @public
 */
const sendAndWait = (eventName, data = {}) => {
    send(eventName, data);
    return new Promise((resolve) => {
        window.setTimeout(resolve, 750);
    });
};
const init = () => {
    /**
     * Everytime the app load, we store a
     * marketing data object in the session storage.
     */
    SharedDataService.storeMarketingData();
    const optOut = hasUserOptedOut();
    trackingProviders.forEach(({ provider: { load }, logs }) => {
        if (load) {
            load({ logs, optOut });
        }
    });
};
init();
const TrackingController = {
    send,
    sendAndWait
};
export default TrackingController;
