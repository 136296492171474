import { debounce } from 'underscore';

import Utils from 'core-helpers/utils.js';

/**
 * A class for setting the height to the greatest child height
 *
 * @example
 * const instance = new ItemChildSizer(element, {
 *     calculateClass: 'element__item',
 * });
 *
 * <div class="element">
 *     <div class="element__item">
 *         ...
 *     </div>
 *     <div class="element__item">
 *         ...
 *     </div>
 *     <div class="element__item">
 *         ...
 *     </div>
 * </div>
 */
class ItemChildHeightCalculator {
    /**
     * Initialize an item rotator
     * @param {Element} wrapperElement
     * @param {Object} options
     * @param {String} options.calculateClass - The class for items to calculate
     */
    constructor(wrapperElement, options = {}) {
        this.wrapperElement = wrapperElement;
        this.options = options;

        if (this.wrapperElement) {
            this.initHeightCalculation();
        }
    }

    initHeightCalculation() {
        const items = this.options.calculateClass
            ? Utils.getElementsByClass(this.options.calculateClass, this.wrapperElement)
            : this.wrapperElement.childNodes;

        if (items.length) {
            this.calculateWrapperHeight(items);

            window.addEventListener('resize', debounce(this.calculateWrapperHeight.bind(this, items), 300));
        }
    }

    getItemHeight(item) {
        return item ? item.offsetHeight : 0;
    }

    setWrapperHeight(height) {
        this.wrapperElement.style.height = `${height}px`;
    }

    calculateWrapperHeight(items) {
        let maxHeight = 0;

        items.forEach((item) => {
            const itemHeight = this.getItemHeight(item);

            maxHeight = itemHeight > maxHeight
                ? itemHeight
                : maxHeight;
        });

        this.setWrapperHeight(maxHeight);
    }
}

export default ItemChildHeightCalculator;
