(function(root, doc, undefined) {

    'use strict';

    /**
     * Fix an issue in Samsung Browser v7.2
     * where `ToString()` is invoked instead of `toString()`.
     * This was reported by Sentry.
     * Source: https://github.com/SamsungInternet/support/issues/56
     */
    Function.prototype.ToString = function () {
        return this.toString();
    };

})(window, document);
