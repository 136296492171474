import _ from 'underscore';
import Utils from 'core-helpers/utils.js';
import ExceptionService from 'core-services/exceptions/exception-service.js';
import SharedDataService from 'data/shared-data-service.js';

/**
 * Optimizely Manager
 */
var Optimizely = (function(root) {

    /**
     * Checks if Optimizely is loaded on window object
     * @return {Boolean}
     */
    var isLoaded = function() {
        var optimizelyLoaded = Boolean(root.optimizely && root.optimizely.get && root.optimizely.push);

        if (!optimizelyLoaded) {
            ExceptionService.handle('warning', {
                filename: filename,
                message: 'Optimizely not initialized in the window'
            });
        }

        return optimizelyLoaded;
    };

    /**
     * Prevents tracking if user is on a localhost environment.
     * @return {Boolean}
     */
    var preventTracking = function(eventName, data) {
        if (enableLogs) {
            ExceptionService.handle('warning', {
                filename: filename,
                message: 'TRACKING OPTIMIZELY: ' + eventName,
                data: data
            });
        }
        return enableLogs ? false : Utils.isLocalEnvironment();
    };

    /*
     * Optimizely different methods
     */
    var sendEvent = function(eventTemplate = {}, data = {}) {

        const eventName = eventTemplate.real_event_name;
        if (!eventName) {
            ExceptionService.handle('error', {
                filename: filename,
                message: 'eventName is missing'
            });
            return false;
        }

        // Always send through commonData + options
        var params = _.extend({}, commonData, data);
        params = Utils.deepClone(params);

        if (!isLoaded() || preventTracking(eventName, params)) {
            return false;
        }

        root.optimizely.push({
            type: 'event',
            eventName: eventName,
            tags: params
        });

    };

    /*
     * Store extra data for Optimizely events
     * @params {Object} user
     */
    var identifyUser = function(user) {

        if (!isLoaded()) {
            return false;
        }

        /**
         * Store common user data for
         * next tracking events
         */
        commonData = _.extend(commonData, {
            name: user.name,
            uid: user.uid,
            platform: 'Web',
            email: user.email,
            country: user.country,
            countryCode: user.country_code,
            role: user.is_premium ? 'premium' : 'free',
            language_learnt: user.languages.learning_default,
            interface_language: user.languages.interface,
            created_timestamp: user.created
        });

        // If user is a B2B user, add the instution details here
        if (user.institution) {
            commonData.role = 'b2b';
            commonData.institution_role = user.institution.institution_role || null;
            commonData.institution_id = user.institution.institution_id || null;
        }

        /**
         * Use this API to record the user's values for any number
         * of dimensions or custom attributes.
         * https://developers.optimizely.com/x/solutions/javascript/reference/index.html#function_setuser
         */
        root.optimizely.push({
            type: 'user',
            attributes: Utils.deepClone(commonData)
        });

        /**
         * As our app is loading User Asynchronously,
         * we must re-activate Optimizely manually after we got the user infos.
         * https://developers.optimizely.com/x/solutions/javascript/reference/#function_activate
         * https://help.optimizely.com/Build_Campaigns_and_Experiments/Conditional_activation_for_pages_in_Optimizely_X
         */
        root.optimizely.push({
            type: 'activate'
        });

    };

    /**
     *  Returns active experiments and variants ids as :
     *  {
     *   'experiment1Id': 'variantId',
     *   'experiment2Id': 'variant2Id'
     *  }
     */
    const getExperimentsData = () => {
        if (!isLoaded()) {
            return null;
        }

        const state = root.optimizely.get('state');

        const experimentsMap = Object.entries(state.getExperimentStates());
        const experimentsValue = experimentsMap.reduce((result, [experimentId, experimentEntry]) => {

            // check if there are any active experiments or variations
            if (experimentEntry.isActive && (experimentEntry.variation && experimentEntry.variation.id)) {
                result[experimentId] = experimentEntry.variation && experimentEntry.variation.id;
            }

            return result;
        }, {});

        return experimentsValue;
    };

    const getVisitorId = () => {
        if (!isLoaded()) {
            return null;
        }

        return root.optimizely.get('visitor').visitorId;
    };

    var load = function(params) {
        if (params.logs) {
            enableLogs = true;
        }
        commonData.attribution = SharedDataService.getMarketingData();
    };

    /*
     * Private vars
     */
    var filename = 'tracking-optimizely.js';
    var enableLogs = false;
    var commonData = {}; // basic data send through all tracking

    return {
        sendEvent,
        identifyUser,
        isLoaded,
        load,
        getExperimentsData,
        getVisitorId
    };

})(window);

export default Optimizely;
