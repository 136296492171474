import Utils from 'core-helpers/utils.js';
import ItemRotatorProgress from 'components/item-rotator-progress-class.js';
import ItemChildHeightCalculator from 'components/item-child-height-calculator-class.js';

const initCard = () => {
    const reviewsSlideshow = Utils.getById('reviews-slideshow');

    if (reviewsSlideshow) {
        const newItemRotator = new ItemRotatorProgress(reviewsSlideshow, {
            interval: 8000,
            itemPrevClass: 'reviews__reviews-wrapper--prev',
            itemActiveClass: 'reviews__reviews-wrapper--active',
            indicatorActiveClass: 'reviews__indicator--active'
        });
        return newItemRotator;
    }
};

const initSize = () => {
    const reviews = Utils.getElementByClass('reviews__main-container');

    if (reviews) {
        const newHeight = new ItemChildHeightCalculator(reviews, {
            calculateClass: 'reviews__reviews-container'
        });
        return newHeight;
    }
};

initCard();
initSize();
