const eCommerceOriginConstants = {
    'CERTIFICATE_PAYWALL': 'certificate',
    'CLAIM_BASIC_FT_PAYWALL_BANNER': 'claim_basic_ft_paywall_banner',
    'CLAIM_BASIC_TRIAL_BANNER': 'claim_basic_trial_banner',
    'CLAIM_REFERRAL_FT_PAYWALL_BANNER': 'claim_referral_ft_paywall_banner',
    'CLAIM_REFERRAL_TRIAL_BANNER': 'claim_referral_trial_banner',
    'COMPREHENSION_ACTIVITY_PAYWALL': 'audio',
    'CONVERSATION_ACTIVITY_PAYWALL': 'conversation',
    'DIALOGUE_ACTIVITY_PAYWALL': 'dialogue',
    'DISCOUNT_COUPON_FORM': 'discount_coupon_form',
    'EXTERNAL_SOURCES': 'external_sources',
    'FREE_TRIAL_ONBOARDING': 'free_trial_onboarding',
    'GRAMMAR_DEVELOP_ACTIVITY_PAYWALL': 'grammar_develop',
    'GRAMMAR_DISCOVER_ACTIVITY_PAYWALL': 'grammar_discover',
    'GRAMMAR_PRACTICE_ACTIVITY_PAYWALL': 'grammar_practice',
    'LESSONS_VISIBLY_LOCKED': 'lessons_visibly_locked', // LESSONS_VISIBLY_LOCKED Experiment
    'MEMORISE_ACTIVITY_PAYWALL': 'memorise',
    'MERCHANDISING_BANNER': 'merch_banner',
    'MERCHANDISING_BANNER_COUNTDOWN': 'merch_banner_countdown',
    'MERCHANDISING_BANNER_PAYWALL': 'merch_banner_paywall', // LESSONS_VISIBLY_LOCKED Experiment
    'MERCHANDISING_BANNER_PAYWALL_COUNTDOWN': 'merch_banner_paywall_countdown', // LESSONS_VISIBLY_LOCKED Experiment
    'ONBOARDING_STUDY_PLAN': 'onboarding_study_plan',
    'ONBOARDING_STUDY_PLAN_LAST_CHANCE': 'onboarding_study_plan_last_chance',
    'PREMIUM_ACTIVITY_TOOLTIP': 'locked_content',
    'PREMIUM_CTA_NAVTOP': 'cta_navtop',
    'PRINT_PREMIUM_PAYWALL': 'lesson_summary',
    'PROMO_BRAZE': 'promo_braze',
    'PROMO_CART_ABANDONMENT': 'cart_abandonment',
    'PROMO_GLOBAL': 'promo_global',
    'PROMO_TWO_DAY_STREAK': '2_day_streak',
    'PRONUNCIATION_ACTIVITY_PAYWALL': 'pronunciation',
    'QUIZ_ACTIVITY_PAYWALL': 'quiz',
    'READING_ACTIVITY_PAYWALL': 'text',
    'SELECT_LANG_MODAL_PAYWALL': 'multi_language',
    'SMART_REVIEW_ACTIVITY_PAYWALL': 'smart_review',
    'SMART_REVIEW_UPSELL': 'smart_review_upsell',
    'UPGRADE_BANNER': 'upgrade_banner',
    'VIDEO_ACTIVITY_PAYWALL': 'video',
    'VOCABULARY_ACTIVITY_PAYWALL': 'vocabulary'
};

export default eCommerceOriginConstants;
