/**
 * Builds the event data for a purchase success event
 * @param {Object} params
 * @param {String} params.transactionTotal
 * @param {String} params.currency
 */
const buildPurchaseSuccessEvent = (params) => ({
    revenue: params.transactionTotal,
    currency: params.currency
});

const adjustEvents = {
    'ADJUST_PURCHASE_SUCCESS': buildPurchaseSuccessEvent
};

export default adjustEvents;
