/**
 * name: ScriptLoader Module
 * description: A simple way to load scripts asynchronously
 * after the window has loaded or instantly.
 */
import Console from 'core-helpers/console-helper.js';

// Private vars
const filename = 'script-loader.js';
const firstScriptTag = document.getElementsByTagName('script')[0];
let queue = [];
let scriptListIDs = [];
let randomId = 0;
let windowIsLoad = false;

/**
 * Script page injection
 * @param  {String}   url
 * @param  {Function} callback
 * @param  {String}   id
 */
const load = (url, id, callback) => {

    let script = document.createElement('script');
    script.async = true;
    script.src = url;
    script.id = id;

    if (callback && script.readyState) { // IE
        script.onreadystatechange = () => {
            if (script.readyState === 'loaded' || script.readyState === 'complete') {
                script.onreadystatechange = null;
                callback(id);
            }
        };
    } else if (callback) { // others
        script.onload = () => {
            callback(id);
        };
    }

    if (firstScriptTag && firstScriptTag.parentNode) {
        firstScriptTag.parentNode.insertBefore(script, firstScriptTag);
    }

};

/**
 * Add a Script into the wait queue if window isn't loaded
 * If window is loaded, inject the script immediatly.
 * @param {String}   url      - Script URL
 * @param {String}   id       - Script ID (optional)
 * @param {Function} callback - Invoked when script is loaded (optional)
 * @param {Boolean}  loadNow  - Force Script to load right now (optional)
 */
const add = (url, id, callback, loadNow) => {

    // Avoid duplicate script ids
    if (scriptListIDs.includes(id)) {
        Console.log(filename, 'Script id "' + id + '" is duplicated, abort dom injection.');
        return false;
    }

    // Generate an id for each script
    let scriptID = id;
    if (!scriptID) {
        scriptID = 'script-' + randomId;
        randomId += 1;
    }

    // ..and push it into the list
    scriptListIDs.push(scriptID);

    // Scripts can be injected right now or after load event (default)
    if (loadNow || windowIsLoad) {
        load(url, scriptID, callback);
    } else {

        /**
         * If window isn't loaded,
         * then store current arguments.
         * This will be invoked later when window will be loaded
         */
        let array = [];
        array.push(url);
        array.push(scriptID);
        array.push(callback);

        queue.push(array);

    }

};

/**
 * window on 'load' event handler
 */
const onLoad = () => {

    windowIsLoad = true;

    // For each element in the queue, load it
    for (let i = 0, l = queue.length; i < l; i++) {
        load.apply(null, queue[i]);
    }

    // Empty queue
    queue.length = 0;

};

// Attach a load event on window
window.addEventListener('load', onLoad);

// Public methods
export default {
    add
};
