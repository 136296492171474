import Utils from 'core-helpers/utils.js';
import NavGlide from 'components/nav-glide-class.js';
import PlansHelper from 'core-helpers/plans-helper.js';
import TieredPlansHelper from 'premium/tiered-plans-helper.js';
import TranslationsService from 'common/translations.service.ts';

const filename = 'tiered-plans-ui-service.js';

// Elements
let tieredPlans;

// UI Classnames
const UI_ERROR_CLASSNAME = 'tiered-plans--error';
const UI_DISABLED_CLASSNAME = 'tiered-plans--disabled';
const UI_LOADING_CLASSNAME = 'tiered-plans--loading';
const UI_PRICES_VISIBLE_CLASSNAME = 'tiered-plans__price--visible';

/**
 * In order to achieve a nice effect,
 * we must allow time for the animation
 * to complete and only after it's done,
 * start it again. This value should always
 * be a little higher than the actual CSS transitionDuration.
 */
const PRICES_DELAY_BEFORE_ANIMATION = 225;

/**
 * Removes month items within the nav
 * that doesn't match the plans returned
 * from the API.
 * @param {Object} casualPlans
 */
const removeExtraPlansFromNavigation = (casualPlans) => {
    const navItems = TieredPlansHelper.getNavigationItems();
    navItems.forEach((item) => {
        const months = TieredPlansHelper.getPlanDuration(item);
        const found = PlansHelper.findPlanByMonth(casualPlans, months);
        if (!months || !found) {
            item.parentNode.removeChild(item);
        }
    });
};

/**
 * @param {Object} options
 */
const initNav = (options) => {
    const tieredPlansNav = Utils.getById('tiered-plans-nav');
    return new NavGlide(tieredPlansNav, options);
};

/**
 * @param {Element} element
 * @param {String} text
 * @private
 */
const setText = (element, text) => {
    Utils.setText(element, text);
};

/**
 * @param {String} URL
 */
const updateSubmitButtonsHref = (URL) => {
    Utils.getElementsByClass('js-submit-plan', tieredPlans).forEach((item) => {
        item.href = URL;
    });
};

/**
 * @param {Element} prices
 * @param {String} state
 * @private
 */
const toggleShowPrices = (prices, state) => {
    if (state === 'hide') {
        return Utils.removeClass(prices, UI_PRICES_VISIBLE_CLASSNAME);
    }
    Utils.addClass(prices, UI_PRICES_VISIBLE_CLASSNAME);
};

/**
 * @param {Array} elements
 */
const resetPrices = (elements) => {
    elements.forEach((item) => {
        setText(item, '');
    });
};

/**
 * Removes the current prices and display the new prices
 * @param {Object} plan
 * @param {Element} element
 */
const updatePlanPrices = (plan, element) => {
    const originalPriceElement = Utils.getElementByClass('js-original-price', element);
    const monthlyPriceElement = Utils.getElementByClass('js-monthly-price', element);
    const discountElements = Utils.getElementsByClass('js-plan-discount', element);

    const resetElements = [originalPriceElement, monthlyPriceElement].concat(discountElements);

    resetPrices(resetElements);

    if (plan.hasDiscount) {
        setText(originalPriceElement, plan.monthlyPrice);
        const discountText = TranslationsService.getTranslation('X_PERCENT').replace('%s', plan.discountPercent);
        discountElements.forEach((item) => {
            setText(item, discountText);
        });
    }
    const monthlyPrice = plan.hasDiscount
        ? plan.monthlyPriceAfterDiscount
        : plan.monthlyPrice;

    setText(monthlyPriceElement, monthlyPrice);
};

/**
 * Removes the current billed term and displays the new one
 * @param {Object} plan
 * @param {Element} element
 */
const updatePlanBilledTerm = (plan, element) => {
    const billedTermElement = Utils.getElementByClass('js-billed-term', element);

    const billedTermKey = plan.months === 1
        ? '1_MONTH_BILLED'
        : `${plan.months}_MONTHS_BILLED`;

    setText(billedTermElement, TranslationsService.getTranslation(billedTermKey));
};

/**
 * Updates the current plans details with an animation effect.
 * @param {Object} plan
 * @param {Element} element
 */
const updatePlanDetails = (plan, element) => {
    const prices = Utils.getElementByClass('js-prices', element);

    const showPrices = () => {
        updatePlanPrices(plan, element);
        updatePlanBilledTerm(plan, element);

        toggleShowPrices(prices, 'show');
    };

    toggleShowPrices(prices, 'hide');
    setTimeout(showPrices, PRICES_DELAY_BEFORE_ANIMATION);
};

/**
 * UI Module states
 * @param {String} state
 * @param {String} errorType
 */
const setState = (state, errorType = 'ERROR') => {
    tieredPlans = Utils.getById('tiered-plans');

    switch (state) {
        case 'REQUEST_START':
            Utils.addClass(tieredPlans, `${UI_LOADING_CLASSNAME} ${UI_DISABLED_CLASSNAME}`);
            break;
        case 'REQUEST_COMPLETE':
            Utils.removeClass(tieredPlans, UI_LOADING_CLASSNAME);
            break;
        case 'REQUEST_SUCCESS':
            Utils.removeClass(tieredPlans, UI_DISABLED_CLASSNAME);
            break;
        case 'ERROR':
            showError(errorType);
            Utils.addClass(tieredPlans, `${UI_ERROR_CLASSNAME} ${UI_DISABLED_CLASSNAME}`);
            break;
        default:
            throw new Error(`[${filename}]: setState() - state expected`);
    }
};

/**
 * @param {String} type
 * @private
 */
const showError = (type) => {
    const tieredPlansError = Utils.getById('tiered-plans-error');
    setText(tieredPlansError, TranslationsService.getTranslation(type));
};

/**
 * Updated the title and flag in "Paywall Customized by language learnt" experient
 * @param {Object} data
 * @public
 */
const paywallCustomizedByLanguageLearntExperiment = ({ learning_default: learningDefault }) => {
    if (!learningDefault) {
        return false;
    }

    const getTranslations = (language) => {
        const lang = TranslationsService.getTranslation(`LANGUAGE_${language}`);
        const msg = TranslationsService.getAndReplaceTranslation('UNLOCK_THE_FULL_LANGUAGE_X_COURSE', lang);
        return { lang, msg };
    };

    const getFlagSrc = (language) => {
        return `${process.env.staticRoot}/img/icons/flags/icon_flag-${language.toLowerCase()}.svg`;
    };

    const { lang, msg } = getTranslations(learningDefault.toLowerCase());
    const flagSrc = getFlagSrc(learningDefault.toLowerCase());

    const flagElement = document.createElement('img');

    const attrs = {
        src: flagSrc,
        width: 50,
        height: 50,
        class: 'tiered-plans__main-title-flag',
        alt: lang
    };

    Object.entries(attrs).forEach(([key, value]) => {
        flagElement.setAttribute(key, value);
    });

    const title = Utils.getElementByClass('tiered-plans__main-title');

    const showNewTitle = () => {
        title.removeEventListener('animationend', showNewTitle);
        Utils.setText(title, msg);
        title.appendChild(flagElement);
        Utils.removeClass(title, 'tiered-plans__main-title--fadeout');
        Utils.addClass(title, 'tiered-plans__main-title--flagged');
    };

    title.addEventListener('animationend', showNewTitle);

    Utils.addClass(title, 'tiered-plans__main-title--fadeout');
};

/**
 * Tiered Plans UI Service:
 * This service should hold any methods that is changing the UI
 * or the DOM module.
 */
const TieredPlansUIService = {
    setState,
    initNav,
    updatePlanDetails,
    updateSubmitButtonsHref,
    removeExtraPlansFromNavigation,
    setText,
    paywallCustomizedByLanguageLearntExperiment
};

export default TieredPlansUIService;
