import Utils from 'core-helpers/utils.js';
import ConfigService from 'config/config-service.js';
import DefaultBaseBanner from 'components/banners/default-base-banner';
import CountriesService from 'core-services/countries-service.js';
import Tracking from 'tracking/tracking-controller';
import { setOptOutAndDismissBanner, isBannerDismissed } from 'components/banners/cookie-banner-service';
const BANNER_ID = 'cookie-banner';
const ACCEPT_ID = 'cookie-banner-accept';
const REJECT_ID = 'cookie-banner-reject';
let elBanner;
let elAccept;
let elReject;
let banner;
const sendTracking = (optOutCookies) => {
    const eventName = optOutCookies ? 'COOKIES_OPTED_OUT' : 'COOKIES_OPTED_IN';
    Tracking.send(eventName);
};
const onClickCTA = (optOutCookies, e) => {
    if (e) {
        e.preventDefault();
    }
    sendTracking(optOutCookies);
    setOptOutAndDismissBanner(optOutCookies);
    banner.hide();
};
const initCookieBanner = () => {
    banner = new DefaultBaseBanner(elBanner);
    elAccept.addEventListener('click', onClickCTA.bind(null, false));
    elReject.addEventListener('click', onClickCTA.bind(null, true));
    banner.show();
};
const loadCookieBanner = () => {
    const onSuccess = (countryCode) => {
        if (CountriesService.isCountryGDPRProtected(countryCode)) {
            initCookieBanner();
        }
    };
    ConfigService.getCountryCode()
        .then(onSuccess)
        .catch(initCookieBanner);
};
const init = () => {
    const bannerDismissed = isBannerDismissed();
    elBanner = Utils.getById(BANNER_ID);
    elAccept = Utils.getById(ACCEPT_ID);
    elReject = Utils.getById(REJECT_ID);
    if (elBanner && !bannerDismissed) {
        loadCookieBanner();
    }
};
export { init };
