import { FT_TYPE_BASIC, FT_TYPE_REFERRAL } from 'core-services/free-trial/free-trial.constants.js';

/**
 * Returns if the user is eligible for a free trial.
 * @param {Object} user
 * @return {Boolean}
 */
const isEligibleForFreeTrial = (user) => {
    return user && user.free_trial_eligible && !user.is_premium;
};

/**
 * Returns if the user id for the suer who referred the current one.
 * @param {Object} user
 * @return {Number?}
 */
const getFreeTrialReferrerId = (user) => {
    return user ? user.advocate_id : null;
};

/**
 * Returns if the user is eligible for the referral free trial. Should only occur if they used
 * a referral code.
 * @return {Boolean}
 */
const isEligibleForReferralFreeTrial = (user) => {
    return isEligibleForFreeTrial(user) && getFreeTrialReferrerId(user) !== null;
};

/**
 * Returns the type of trial the user is eligible for
 * @return {string?}
 */
const getFreeTrialType = (user) => {
    if (isEligibleForReferralFreeTrial(user)) {
        return FT_TYPE_REFERRAL;
    }

    if (isEligibleForFreeTrial(user)) {
        return FT_TYPE_BASIC;
    }

    return null;
};

const FreeTrialService = {
    getFreeTrialReferrerId,
    isEligibleForFreeTrial,
    isEligibleForReferralFreeTrial,
    getFreeTrialType
};

export default FreeTrialService;
