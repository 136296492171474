/**
 * Marketing UTM Service:
 * This service returns a method to retrieve
 * a marketing data Object suitable for tracking
 * and/or registration services more often.
 *
 * It returns an Object with UTM parameters when
 * found or the referrer type when available.
 *
 */
import ConsoleHelper from 'core-helpers/console-helper.js';

const filename = 'marketing-service.js';

const UTM_QUERY_PARAMS = ['utm_source', 'utm_campaign',
    'utm_group', 'utm_subnetwork', 'utm_term', 'utm_placement',
    'utm_medium', 'utm_term', 'utm_content', 'utm_id', 'utm_group'];
const BUSUU_HOSTNAMES = ['www.busuu.com', 'www.boshu.cn', 'org.busuu.com'];
const SEARCH_ENGINES = ['google', 'bing', 'yahoo', 'ask',
    'aol', 'wow', 'webcrawler', 'mywebsearch', 'infospace', 'duckduckgo',
    'contenko', 'alhea', 'baidu', 'yandex', 'mail.ru', 'so.com', 'sogou'];

/**
 * Returns the param and value associated
 * from a query param.
 * @param  {String} queryParam
 * @return {Object || null}
 * E.G.: 'foo=bar'
 */
const getParamAndValue = (queryParam) => {

    const sanitizeValue = (value) => {
        const hasHash = Boolean(value.indexOf('#') > -1);
        return hasHash ? value.split('#')[0] : value;
    };

    const split = queryParam.split('=');
    let param = null;
    let value = null;

    if (split && split.length === 2) {
        param = split[0];
        value = sanitizeValue(split[1]);
    }

    if (param && value) {
        return {
            param: param,
            value: value
        };
    }

    return null;

};

/**
 * Returns the query search from a given string.
 * N.B.: We voluntary avoid window.location.search as
 * Angular doesn't support it and returns an Object instead.
 * @param  {String} string
 * @return {String}
 */
const getSearchQuery = (string) => {
    if (typeof string !== 'string') {
        return null;
    }
    const split = string.split('?');
    return (split && split.length === 2) ? split[1] : null;
};

/**
 * Returns current query params from URL.
 * @param  {Array} filter
 * @return {Object || null}
 */
const getMarketingParams = (filter) => {

    const searchQuery = decodeURIComponent(getSearchQuery(window.location.href));

    if (!searchQuery || searchQuery.trim() === '') {
        return null;
    }

    const searchParams = searchQuery.split('&');
    let marketingParams = null;

    /**
     * If current URL has parameters:
     * For each found parameter (foo=bar, foo=baz, etc.)
     * we decompile the parameter from the value (param=foo, value=bar).
     * Then we store within `marketingParams` each parameter.
     */
    for (let index = 0, length = searchParams.length; index < length; index++) {

        let queryParam = getParamAndValue(searchParams[index]);
        if (!queryParam) {
            continue;
        }

        /**
         * An optional parameters filter Array can be passed
         * when you want to restrict a restrictive list of
         * parameters you want to get.
         */
        if (filter && Boolean(filter.indexOf(queryParam.param) === -1)) {
            continue;
        }

        marketingParams = marketingParams || {};
        marketingParams[queryParam.param] = queryParam.value;

    }

    return marketingParams;

};

/**
 * Returns the referrer link if it exists.
 * @return {String || null}
 */
const getReferrer = () => {
    return (document.referrer && document.referrer.length) ? document.referrer : null;
};

/**
 * Returns true when the referrer comes from
 * a search engine.
 * @param {String}
 * @return {Boolean}
 */
const isReferrerFromSEO = (referrer) => {
    if (!referrer) {
        return false;
    }
    for (let index = 0, length = SEARCH_ENGINES.length; index < length; index++) {
        let engine = SEARCH_ENGINES[index];
        let match = Boolean(referrer.indexOf(engine) !== -1);
        if (match) {
            return true;
        }
    }
    return false;
};

/**
 * Returns true when the document referrer
 * isn't busuu but external.
 * @param {String}
 * @return {Boolean}
 */
const isReferrerExternal = (referrer) => {
    if (!referrer) {
        return false;
    }
    let isExternal = true;
    for (let index = 0, length = BUSUU_HOSTNAMES.length; index < length; index++) {
        let busuuHost = BUSUU_HOSTNAMES[index];
        let match = Boolean(referrer.indexOf(busuuHost) !== -1);
        if (match) {
            isExternal = false;
        }
    }
    return isExternal;
};

/**
 * Returns an Object when suitable marketing
 * data is found.
 * @return {Object}
 */
const getData = function() {

    let marketingData = null;
    try{
        marketingData = getMarketingParams(UTM_QUERY_PARAMS);
    }catch(e) {
        ConsoleHelper.log(filename, `Error while getting marketing parameters.`, e);
        return null;
    }

    if (marketingData) {
        return marketingData;
    }

    const referrer = getReferrer();
    if (isReferrerFromSEO(referrer)) {
        return {
            'utm_medium': 'organic',
            'utm_content': referrer
        };
    }

    if (isReferrerExternal(referrer)) {
        return {
            'utm_medium': 'referral',
            'utm_content': referrer
        };
    }

    return {
        'utm_medium': 'direct'
    };

};

export default {
    getData
};
