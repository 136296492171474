import { ElementObserver } from 'viewprt';
import Utils from 'core-helpers/utils.js';

/**
 * Class that implements the viewprt pkg.
 * Given a section, element and a highlightClass it will change the element to the given class when the section is in view.
 *
 * @example
 * const instance = new SectionObserver(section: DOM elem, element: DOM elem, highlightClass: string);
 */

class SectionObserver {
    /**
     * Checks if section of the web is is view and change the given element's style.
     * @param {HTMLElement} section to be observed. If it's in/out of view.
     * @param {HTMLElement} element to update dependent of section's status.
     * @param {string} classToAdd class to add to the element when section is in view.
     * @param {number} sectionOffset This offsets the area of the element in view and shrinks
        the area to be accounted for by the observer since it is a negative number.
        To expand the area a positive number should be used. Default to 0.
     */
    constructor(section, element, classToAdd, sectionOffset = 0) {
        this.sectionOffset = sectionOffset;
        this.section = section;
        this.element = element;
        this.classToAdd = classToAdd;

        this.highlightClass = this.highlightClass.bind(this);

        if (this.section && this.element && this.classToAdd) {
            this.highlightClass();
        }
    }

    highlightClass() {
        ElementObserver(this.section, {
            onEnter: () => Utils.addClass(this.element, this.classToAdd),
            offsetY: this.sectionOffset,
            onExit: () => Utils.removeClass(this.element, this.classToAdd)
        });
    }
}

export default SectionObserver;
