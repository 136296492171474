import Utils from 'core-helpers/utils.js';

/**
 * Calculates difference between two timestamps in seconds
 * @param  {Timestamp} time
 * @return {Timestamp}
 */
const calculateTimeDifference = (time) => {
    if (!Utils.isNumber(time)) {
        throw new Error(`calculateTimeDifference() - 'time: ${time}' - is not a number.`);
    }

    const nowInSeconds = Math.round(new Date().getTime() / 1000);
    return time - nowInSeconds;
};

/**
 * Adds a leading `0` for numbers under `10`
 * @param  {Number} num
 * @return {String}
 */
const padStartNumber = (num) => {
    if (!Utils.isNumber(num)) {
        throw new Error(`padStartNumber() - 'num: ${num}' - is not a number.`);
    }

    if (num < 10) {
        return `0${num}`;
    }

    return num.toString();
};

/**
 * Formats time in seconds to a human readable string format.
 * @param  {Number} seconds - Time in seconds to format
 * @return {String} Time in DD:HH:MM:SS | HH:MM:SS | MM:SS format
 */
const secsToHumanReadable = (seconds) => {
    if (!seconds || !Utils.isNumber(seconds) || seconds === 0) {
        return '00:00';
    }

    const dividedMins = seconds % 3600;
    const remainingDays = padStartNumber(Math.floor(seconds / 86400));
    const remainingHours = padStartNumber(Math.floor((seconds % 86400) / (60 * 60)));
    const remainingMins = padStartNumber(Math.floor(dividedMins / 60));
    const secondsRemaining = padStartNumber(Math.ceil(dividedMins % 60));

    if (remainingDays > 0) {
        return `${remainingDays}:${remainingHours}:${remainingMins}:${secondsRemaining}`;
    }

    if (remainingHours > 0) {
        return `${remainingHours}:${remainingMins}:${secondsRemaining}`;
    }

    return `${remainingMins}:${secondsRemaining}`;
};

export default {
    calculateTimeDifference,
    secsToHumanReadable
};
