
/**
 * Display a warning message in the console
 * @param {String} fileName - file name where log is coming from
 * @param {String} message - warning to display
 * @param {Multiple} data - can be any additional data (optional)
 * @param {Function} callback - (optional)
 */
function log(fileName, message, data, callback) {

    let logMessage = '[File: ' + fileName + '] ' + message;

    if (console && console.warn) {
        if (data) {
            console.warn(logMessage, data);
        } else {
            console.warn(logMessage);
        }
    }

    if (callback && typeof(callback) === 'function') {
        callback({
            message: logMessage,
            data: data
        });
    }

}

export default {
    log
};
