/**
 * A service to provide data storage using localStorage or sessionStorage.
 * Can be used to get/set/remove any keys.
 */
import BrowserStorageConstant from 'core-constants/browser-storage-constants.js';

/**
 * Clear localStorage and sessionStorage.
 */
const clear = () => {
    try {
        window.localStorage.clear();
        window.sessionStorage.clear();
    } catch (e) { }
};

/**
 * Returns an associate data Object for a given key.
 * @param  {String} key
 * @return {Object}
 */
const getConstantKeyData = (key) => {
    const exist = BrowserStorageConstant.hasOwnProperty(key);
    if (!exist) {
        throw new Error(`Key '${key}' has not been found on browser storage constants list.`);
    }
    return BrowserStorageConstant[key];
};

/**
 * Returns all the storage keys. Both LS and ST.
 * N.B: We freeze the values to avoid any other scripts
 * to change the constants.
 * @return {Object}
 */
const getAll = () => {
    const keys = BrowserStorageConstant;
    var allKeys = {};
    for (let key in keys) {
        if (keys.hasOwnProperty(key)) {
            allKeys[key] = Object.freeze(keys[key]);
        }
    }
    return Object.freeze(allKeys);
};

/**
 * Returns a given item in browser storage (LS or ST) from a key value
 * @param  {String} key
 * @return {String}
 */
const get = (key) => {

    const keyData = getConstantKeyData(key) || {};
    const type = keyData.type;
    key = keyData.key;

    if (!type || !key) {
        throw new Error('Key or type is missing.');
    }

    try {
        switch (type) {
            case 'LS':
                return window.localStorage.getItem(key) || null;
            case 'ST':
                return window.sessionStorage.getItem(key) || null;
        }
    } catch (e) { }

};

/**
 * Set a given key/value in the localStorage
 * @param  {String} key
 * @param  {String} value
 */
const set = (key, value) => {

    const keyData = getConstantKeyData(key) || {};
    const type = keyData.type;
    value = keyData.mandatoryValue ? value : key;
    key = keyData.key;

    if (!type || !value || !key) {
        throw new Error(`Key, type or value is missing: key: ${key}, type: ${type}, value: ${value}.`);
    }

    try {
        switch (type) {
            case 'LS':
                window.localStorage.setItem(key, value);
                break;
            case 'ST':
                window.sessionStorage.setItem(key, value);
                break;
        }
    } catch (e) { }

};

/**
 * Delete a given item in localStorage from a key value
 * @param  {String} key
 */
const remove = (key) => {

    const keyData = getConstantKeyData(key) || {};
    const type = keyData.type;
    key = keyData.key;

    if (!type || !key) {
        throw new Error('Key or type is missing.');
    }

    try {
        switch (type) {
            case 'LS':
                window.localStorage.removeItem(key);
                break;
            case 'ST':
                window.sessionStorage.removeItem(key);
                break;
        }
    } catch (e) { }

};

export default {
    get: get,
    getAll: getAll,
    set: set,
    clear: clear,
    remove: remove
};

