import Utils from 'core-helpers/utils.js';

/**
 * Private vars
 */
const liveLessonsTable = Utils.getById('live-lessons-table');
const dropdown = Utils.getById('live-lessons-table-dropdown');
let activeTimetable = null;

const toggleTimetable = (timetable) => {
    if (activeTimetable) {
        Utils.removeClass(activeTimetable, 'llt__flag--active');
        Utils.hide(getActiveTimetable(activeTimetable));
    }
    if (timetable) {
        activeTimetable = timetable;
        Utils.addClass(activeTimetable, 'llt__flag--active');
        Utils.show(getActiveTimetable(activeTimetable));
    }
};

const updateDropdownState = (value) => {
    dropdown.value = value;
};

const onFlagHandler = function(e) {
    e.preventDefault();
    toggleTimetable(this);
    updateDropdownState(this.getAttribute('data-target'));
};

const addFlagsHandler = (liveLessonsTable) => {
    Utils.delegate(liveLessonsTable, 'js-flag-toggler', 'click', onFlagHandler);
};

const onDropdodownChange = function() {
    const target = Utils.getElementByAttribute(`data-target=${this.value}`, liveLessonsTable);
    toggleTimetable(target);
};

const addDropdownHandler = (dropdown) => {
    dropdown.addEventListener('change', onDropdodownChange);
};

const getActiveTimetable = (element) => {
    return Utils.getById(element.getAttribute('data-target'));
};

const init = () => {

    if (!liveLessonsTable) {
        return false;
    }

    // Togglers handlers
    addFlagsHandler(liveLessonsTable);
    addDropdownHandler(dropdown);

    // Display acttive timetable
    activeTimetable = Utils.getElementByClass('llt__flag--active');
    toggleTimetable(activeTimetable);

};

init();
