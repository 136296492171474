import Utils from 'core-helpers/utils.js';
import PlansHelper from 'core-helpers/plans-helper.js';
import PlansService from 'premium/plans-service.js';
import Tracking from 'tracking/tracking-controller.ts';
import ExceptionService from 'core-services/exceptions/exception-service.js';
import TieredPlansHelper from 'premium/tiered-plans-helper.js';
import TieredPlansUIService from 'premium/tiered-plans-ui-service.js';
import CurrentUserService from 'user/current-user-service.js';
import eCommerceService from 'core-services/ecommerce-origin-service.js';
import PurchaseService from 'purchase/purchase-service.js';
import FreeTrialBanner from 'free-trial/free-trial-banner/free-trial-banner-controller.js';
import PaywallCustomizedByLanguageLearntExperiment from 'experiments/paywall-customized-language-learnt-experiment.js';

let tieredPlans;
let tieredPlansCasual;
let tieredPlansSerious;
const filename = 'tiered-plans-controller.js';

/**
 * The full backend response for plans.
 */
let plansData = {};

/**
 * We need a pointer to know which plan has been
 * selected and we store both tiers here.
 */
let activePlans = {
    casual: null,
    serious: null
};

const onCodeFailure = (message = '', data = {}, type = 'error') => {
    ExceptionService.handle(type, { filename, message, data });
};

/**
 * @param {Element} element
 */
const getPlanDuration = (element) => {
    return TieredPlansHelper.getPlanDuration(element);
};

/**
 * @param {Number} duration
 */
const sendTracking = (duration) => {
    const discountMax = [activePlans.casual, activePlans.serious].reduce((a, b) => {
        return Math.max(a.discountPercent || 0, b.discountPercent || 0);
    });
    const data = {
        plan_length: duration,
        discount_amount: discountMax
    };
    const origin = eCommerceService.retrieve();
    if (origin) {
        data.ecommerce_origin = origin;
    }
    Tracking.send('PAYWALL_PLAN_DURATION_CHANGED', data);
};

/**
 * @param {Element} element
 */
const onPlanClick = (element) => {
    const duration = getPlanDuration(element);
    setActivePlan(duration);
    sendTracking(duration);
};

/**
 * @param {Object} plan
 */
const getFormattedPlan = (plan) => {
    return PlansHelper.getFormattedPlan(plan);
};

/**
 * @param {Number} monthPlan
 */
const setActivePlan = (monthPlan) => {

    // Find plan
    const [casualPlan, seriousPlan] = [plansData.plans.premium_standard, plansData.plans.premium_plus].map((tier) => {
        return PlansHelper.findPlanByMonth(tier, monthPlan);
    });

    // Format them
    activePlans = {
        casual: getFormattedPlan(casualPlan),
        serious: getFormattedPlan(seriousPlan)
    };

    // Defines the tier element for the prices
    const tieredPlanElements = {
        casual: tieredPlansCasual,
        serious: tieredPlansSerious
    };

    // ..then display them
    Object.keys(activePlans).forEach((key) => {
        tieredPlanElements[key].forEach((element) => {
            TieredPlansUIService.updatePlanDetails(activePlans[key], element);
        });
    });

};

/**
 * This method will ensure the plans data
 * are healthy and will remove the useless plans
 * from the DOM that aren't matching
 * tha API plans returned.
 * @param {Object} plans
 */
const verifyDataAndRemoveExtraPlans = (plans) => {

    // Validate data
    plansData = Utils.deepClone(plans);

    const isDataValid = TieredPlansHelper.isPlansStructureCorrect(plansData);
    if (!isDataValid) {
        throw onCodeFailure('Invalid data.');
    }

    // Filter out plans from the DOM
    TieredPlansUIService.removeExtraPlansFromNavigation(plansData.plans.premium_standard);

    // ..then set back request success state
    TieredPlansUIService.setState('REQUEST_SUCCESS');

};

const setupMenuAndDisplayActivePlan = () => {

    // Init navigation and get active element
    const navGlideInstance = TieredPlansUIService.initNav({
        onItemClick: onPlanClick
    });

    // Display selected plan
    const activeItem = navGlideInstance.getActiveItem();
    setActivePlan(getPlanDuration(activeItem));

};

const redirect = (URL) => {
    window.location.href = URL;
};

const onPlanSelection = function(e) {

    e.preventDefault();
    onRequestStart();

    const tieredPlan = activePlans[this.getAttribute('data-tier-type')];
    const storagePlan = TieredPlansHelper.getFormattedPlanBeforeStorage(tieredPlan);

    PurchaseService.storePlanForPayment(plansData, storagePlan);
    redirect(this.href);
};

/**
 * If the user is authenticated,
 * we need a click handler on each plans to
 * add severals steps before going to the cart.
 */
const updateButtonBehaviours = () => {

    /**
     * If the user is authenticated,
     * we need a click handler on each plans to
     * add severals steps before going to the cart.
     */
    if (!PlansHelper.isUserAuthenticated(plansData)) {
        return false;
    }

    // Update URL
    const URL = TieredPlansHelper.getCartURL();
    TieredPlansUIService.updateSubmitButtonsHref(URL);

    // Add handlers
    Utils.delegate(tieredPlans, 'js-submit-plan', 'click', onPlanSelection);
};

const onPlansError = (e) => {
    onCodeFailure('Tiered plans loading error', e, 'warning');
    TieredPlansUIService.setState('ERROR', 'TEMPORARY_ERROR');
};

const onPlansComplete = () => {
    TieredPlansUIService.setState('REQUEST_COMPLETE');
};

const onRequestStart = () => {
    TieredPlansUIService.setState('REQUEST_START');
};

const fetchPlans = () => {
    return PlansService.getTieredPlans();
};

const paywallCustomizedByLanguageLearntExperiment = () => {
    if (!PaywallCustomizedByLanguageLearntExperiment.isInExperiment()) {
        return false;
    }

    CurrentUserService.get().then(data => TieredPlansUIService.paywallCustomizedByLanguageLearntExperiment(data.languages));
};

const init = () => {

    tieredPlans = Utils.getById('tiered-plans');
    tieredPlansCasual = Utils.getElementsByClass('js-tiered-plans-casual');
    tieredPlansSerious = Utils.getElementsByClass('js-tiered-plans-serious');

    if (!tieredPlans) {
        return false;
    }
    onRequestStart();
    FreeTrialBanner.init();

    /**
     * NB: The promise chain is very important here:
     * 1. Fetch plans/restructure/display > Catch (if one of these things fail we care & show an error)
     * 2. Then fetch user to update button behaviours > Catch (if this fails/logged out we don't care, the button will just go to /login)
     * 3. Catch any errors from the above
     * 4. Complete - stops the loader
     */
    fetchPlans()
        .then(verifyDataAndRemoveExtraPlans)
        .then(setupMenuAndDisplayActivePlan)
        .then(updateButtonBehaviours)
        .then(paywallCustomizedByLanguageLearntExperiment)
        .catch(onPlansError)
        .then(onPlansComplete);
};

export { init };
