import PromotionService from 'promotion/promotion-service.js';
import Utils from 'core-helpers/utils.js';
import PlansService from 'premium/plans-service.js';
import PlansHelper from 'core-helpers/plans-helper.js';
import TranslationsService from 'common/translations.service.ts';
import SharedDataService from 'data/shared-data-service.js';

let titleEl;
let subTitleEl;
let ctaEl;

const onClickBuyNow = function(storagePlan, e) {
    if (e) {
        e.preventDefault();
    }

    if (storagePlan) {
        SharedDataService.storeSelectedPlan(storagePlan);
    }

    window.location.href = this.href;
};

const setupLandingHeader = (plans, plan, planDuration) => {
    const formattedPlan = PlansHelper.getFormattedPlan(plan);
    const storagePlan = PlansHelper.getValidStoragePlan(plans, formattedPlan);

    const wasPrice = plan.total.price_human;
    const nowPrice = `<span>${plan.total.price_discounted_human}</span>`;

    const subTitle = TranslationsService.getAndReplaceTranslationMulti('X_MONTHS_OF_PREMIUM_PLUS_WAS_X_NOW_X', [planDuration, wasPrice, nowPrice]);

    subTitleEl.innerHTML = subTitle;
    ctaEl.addEventListener('click', onClickBuyNow.bind(ctaEl, storagePlan));
};

const canShowPromotionLanding = (plan, planDuration) => {
    return PromotionService.canShowBuyXGetXFree(plan.discount_percent, planDuration);
};

const hideSkeletonLoaders = (landingPlaceholder, landingContent) => {
    Utils.addClass(landingPlaceholder, 'hidden');
    Utils.removeClass(landingContent, 'hidden');
};

const fetchLandingPromotion = (planDuration) => {
    const landingPlaceholder = Utils.getById('months-free-landing-placeholder');
    const landingContent = Utils.getById('months-free-landing-content');

    const onSuccess = (plans) => {
        const plan = PlansHelper.findPlanByMonth(plans.plans.premium_plus, planDuration);

        if (plan) {
            const isPromotionLandingValid = canShowPromotionLanding(plan, planDuration);

            if (isPromotionLandingValid) {
                setupLandingHeader(plans, plan, planDuration);
            }
        }
    };

    PlansService.getTieredPlans()
        .then(onSuccess)
        .catch(() => {})
        .then(hideSkeletonLoaders.bind(null, landingPlaceholder, landingContent));
};

const init = () => {
    const header = Utils.getById('months-free-landing-header');

    if (header) {
        const planDuration = Number(header.getAttribute('data-plan-duration') || 12);

        titleEl = Utils.getElementByClass('months-free-landing-header__title', header);
        subTitleEl = Utils.getElementByClass('months-free-landing-header__subtitle', header);
        ctaEl = Utils.getElementByClass('months-free-landing-header__cta', header);

        fetchLandingPromotion(planDuration);
    }
};

init();

export { init };
