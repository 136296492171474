/**
 * This service is used to store an e-commerce value through our apps.
 * When a user sees a paywall and decide to go ahead,
 * we store this value that is the context of the CTA or the action that
 * leads to the paywall.
 * Example: Users sees the certificate paywall and decide to turn premium,
 * the stored value will be "certificate".
 * This value will then be used in the paywal of the cart to know
 * what was the last paywall the user has seen.
 * More infos: https://busuucom.atlassian.net/wiki/spaces/FRON/pages/1010958375/eCommerce+Origins
 */
import BrowserStorageService from 'core-services/browser-storage-service.js';
import eCommerceOriginConstants from 'core-constants/ecommerce-origin-constants.js';
import ConsoleHelper from 'core-helpers/console-helper.js';
import MarketingService from 'core-services/marketing-service.js';

const filename = 'ecommerce-origin-service.js';

const getEcommerceOrigin = (type) => {
    return eCommerceOriginConstants[type];
};

/**
 * Indicates wether or not an eCommerce Origin
 * type exists based on a given type.
 * @param  {string}  type
 * @return {Boolean}
 */
const isTypeDefined = (type) => {
    const types = Object.keys(eCommerceOriginConstants);
    return Boolean(types.indexOf(type) !== -1);
};

/**
 * Stores an eCommerce Origin type.
 * 1. If an origin storage can't be found,
 *    we delete the previous origin found to avoid
 *    falsy stored values.
 * @param {string} type
 */
const store = (type) => {
    if (isTypeDefined(type)) {
        BrowserStorageService.set('ECOMMERCE_ORIGIN', type);
    } else {
        ConsoleHelper.log(filename, `store() - eCommerce type is not found, type: ${type}.`);
        remove(); // 1
    }
};

/**
 * Returns an eCommerce Origin value previously stored.
 * @return {string}
 */
const retrieve = () => {
    const type = BrowserStorageService.get('ECOMMERCE_ORIGIN');
    return get(type);
};

/**
 * Returns an eCommerce Origin value from a given type. If no origin is found,
 * and if the utm_medium is not 'direct', it assumes the origin is 'deeplinking'
 * This could be organic traffic, referral from other sites, CRM, CPC. This avoids
 * having events with an unknown origin
 * @param  {string}  type
 * @return {string}
 */
const get = (type) => {
    let eCommerceOrigin = isTypeDefined(type) ? getEcommerceOrigin(type) : null;

    if (!eCommerceOrigin) {
        const marketingData = MarketingService.getData();
        const utmMedium = marketingData.utm_medium;
        eCommerceOrigin = Boolean(utmMedium !== 'direct') ? getEcommerceOrigin('EXTERNAL_SOURCES') : 'unknown_origin';
    }
    return eCommerceOrigin;
};

const remove = () => {
    BrowserStorageService.remove('ECOMMERCE_ORIGIN');
};

export default {
    get,
    store,
    retrieve,
    remove
};
