import Utils from 'core-helpers/utils.js';
import Tracking from 'tracking/tracking-controller';
import WindowLocationService from 'common/window-location.service.js';
const redirect = (href) => {
    WindowLocationService.redirect(href);
};
const onChangeHandler = function () {
    const href = this.value;
    if (href) {
        const languageSelected = this.options[this.options.selectedIndex].getAttribute('data-lang');
        Tracking.send('INTERFACE_LANGUAGE_SELECTED', {
            language_selected: languageSelected
        });
        // Add delay to ensure tracking event is sent before redirecting
        setTimeout(redirect.bind(null, href), 500);
    }
};
const attachListener = (select) => {
    select.addEventListener('change', onChangeHandler);
};
const init = () => {
    Utils.getElementsByClass('js-interface-switcher').forEach(attachListener);
};
init();
