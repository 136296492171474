import _ from 'underscore';
import Utils from 'core-helpers/utils.js';
import ExceptionService from 'core-services/exceptions/exception-service.js';
import GTMScript from 'third-party/script-gtm.js';
import BreakPointDetection from 'helpers/breakpoint-detection.js';
import SharedDataService from 'data/shared-data-service.js';
import Optimizely from 'tracking/tracking-optimizely.js';

/**
 * Private vars
 */
const filename = 'tracking-gtm.js';
let enableLogs = false;
let enableOptOut = false;
let commonData = {}; // basic data send through all tracking

/**
 * GTM Manager
 */
const getDevice = function() {
    return (BreakPointDetection.getState() >= 3) ? 'web' : 'mobile';
};

/**
 * Checks if GTM is present on window object
 * @return {Boolean}
 */
const isGTMPresent = function() {
    if (!window.dataLayer) {
        ExceptionService.handle('warning', {
            filename: filename,
            message: 'GTM is not found on the window.'
        });
    }
    return window.dataLayer ? true : false;
};

/**
 * Prevents tracking if user is on a localhost environment.
 * @return {Boolean}
 */
const preventTracking = function(eventName, opts) {
    if (enableLogs) {
        ExceptionService.handle('warning', {
            filename: filename,
            message: 'TRACKING GTM: ' + eventName,
            data: opts
        });

        return false;
    }

    return enableOptOut || Utils.isLocalEnvironment();
};

const push = function(params) {
    window.dataLayer.push(params);
};

const sendEvent = function(eventTemplate = {}, data = {}) {
    const eventName = eventTemplate.real_event_name;

    if (!eventName) {
        ExceptionService.handle('error', {
            filename: filename,
            message: 'sendEvent() - eventName is missing'
        });
        return false;
    }

    // Always send through commonData + options
    let params = _.extend({}, commonData, data, { event: eventName });
    params = Utils.deepClone(params);

    if (!isGTMPresent() || preventTracking(eventName, params)) {
        return false;
    }

    push(params);

};

/**
 * Add additional datas to the tracking when the user
 * is authenticated
 */
const identifyUser = function(user) {
    commonData = _.extend(commonData, {
        role: user.is_premium ? 'premium' : 'free',
        customerId: user.uid,
        countryCode: user.country_code,
        customerDevice: getDevice(),
        languageInterface: user.languages ? user.languages.interface : null,
        languageLearned: user.languages ? user.languages.learning_default : null,
        registrationDate: new Date(user.created * 1000).toString()
    });
};

const load = function(params) {
    if (params.logs) {
        enableLogs = true;
    }

    if (params.optOut) {
        enableOptOut = true;
    }

    /**
     * Additional information if selected plan exists
     */
    const selectedPlan = SharedDataService.getSelectedPlan();
    if (selectedPlan) {
        commonData.plan = selectedPlan.plan;
    }

    /**
     * Additional information if experiments data exists
     */
    if (Optimizely.isLoaded()) {
        commonData.experiments = Optimizely.getExperimentsData();
    }

    commonData.attribution = SharedDataService.getMarketingData();

    if (!enableOptOut) {
        GTMScript.load();
    }
};

const GTM = {
    sendEvent: sendEvent,
    identifyUser: identifyUser,
    load: load
};

export default GTM;
