const TrackingEventsConstants = {
    'INTERFACE_LANGUAGE_SELECTED': {
        real_event_name: 'interface_language_selected',
        exclusions: []
    },
    'COURSE_SELECTED': {
        real_event_name: 'course_selected',
        exclusions: []
    },
    'PAGE_INIT': {
        real_event_name: 'page_init',
        exclusions: []
    },
    'USER_LOGIN_SUCCESS': {
        real_event_name: 'user_login_success',
        exclusions: []
    },
    'USER_LOGIN_FAILED': {
        real_event_name: 'user_login_failed',
        exclusions: []
    },
    'USER_REGISTERED_SUCCESS': {
        real_event_name: 'user_register_success',
        exclusions: [],
        adjust: {
            token: 'xg0m7p'
        }
    },
    'USER_REGISTERED_FAILED': {
        real_event_name: 'user_register_failed',
        exclusions: []
    },
    'VERIFICATION_CODE_PAGE_VIEWED': {
        real_event_name: 'verification_code_page_viewed',
        exclusions: []
    },
    'VERIFICATION_CODE_ENTERED': {
        real_event_name: 'verification_code_entered',
        exclusions: []
    },
    'PAGE_VIEW_HOME': {
        real_event_name: 'homepage_view',
        exclusions: []
    },
    'PAGE_VIEW_INDITEX_LANDING': {
        real_event_name: 'inditex_landing_page_viewed',
        exclusions: []
    },
    'PAGE_VIEW_COURSE_SELECTION': {
        real_event_name: 'course_selection_viewed',
        exclusions: []
    },
    'PAGE_VIEW_LOGIN_PAGE': {
        real_event_name: 'login_page_viewed',
        exclusions: []
    },
    'PAGE_VIEW_REGISTER_PAGE': {
        real_event_name: 'registration_page_viewed',
        exclusions: []
    },
    'PAGE_VIEW_PREMIUM': {
        real_event_name: 'paywall_viewed',
        exclusions: []
    },
    'PAGE_VIEW_PURCHASE': {
        real_event_name: 'cart_entered',
        exclusions: []
    },
    'PAGE_VIEW_AUTOAUTH': {
        real_event_name: 'autoauth_viewed',
        exclusions: []
    },
    'PURCHASE_METHOD_SELECTED': {
        real_event_name: 'cart_psp_change',
        exclusions: []
    },
    'PURCHASE_FAILURE': {
        real_event_name: 'purchase_failed',
        exclusions: []
    },
    'PURCHASE_SUCCESS': {
        real_event_name: 'purchase_success',
        exclusions: [],
        adjust: {
            token: 'cqr8l8',
            type: 'ADJUST_PURCHASE_SUCCESS'
        }
    },
    'AUTOAUTH_SUCCESS': {
        real_event_name: 'autoauth_success',
        exclusions: []
    },
    'PAYWALL_SEEPLANS_CLICKED': {
        real_event_name: 'paywall_see_all_plans_clicked',
        exclusions: []
    },
    'STUDY_PLAN_CONFIRMED': {
        real_event_name: 'study_plan_confirmed',
        exclusions: []
    },
    'CAPTCHA_STARTED': {
        real_event_name: 'captcha_started',
        exclusions: []
    },
    'CAPTCHA_PASSED': {
        real_event_name: 'captcha_passed',
        exclusions: []
    },
    'CAPTCHA_NOT_PASSED': {
        real_event_name: 'captcha_not_passed',
        exclusions: []
    },
    'FAST_CHECKOUT_STARTED': {
        real_event_name: 'fast_checkout_started',
        exclusions: []
    },
    'UNSUBSCRIBE_COMMS_CLICKED': {
        real_event_name: 'unsubscribe_comms_clicked',
        exclusions: []
    },
    'PAYWALL_PLAN_DURATION_CHANGED': {
        real_event_name: 'paywall_plan_length_changed',
        exclusions: []
    },
    'LEARNER_TYPE_VIEWED': {
        real_event_name: 'learner_type_chooser_viewed',
        exclusions: []
    },
    'CHOOSE_YOUR_PLAN_VIEWED': {
        real_event_name: 'post_register_premium_offering_viewed',
        exclusions: []
    },
    'CHOOSE_PLAN_SELECTED_FREE': {
        real_event_name: 'post_register_premium_offering_free_clicked',
        exclusions: []
    },
    'CHOOSE_PLAN_SELECTED_PREMIUM': {
        real_event_name: 'post_register_premium_offering_premium_clicked',
        exclusions: []
    },
    'REFERRAL_LANDING_PAGE_VIEWED': {
        real_event_name: 'referral_landing_page_viewed',
        exclusions: []
    },
    'REFERRAL_LANDING_PAGE_CTA_SELECTED': {
        real_event_name: 'referral_landing_page_cta_selected',
        exclusions: []
    },
    'APP_STORE_BADGE_SELECTED': {
        real_event_name: 'app_store_badge_selected',
        exclusions: []
    },
    'LIVE_MARKETING_PAGE_VIEWED': {
        real_event_name: 'live_marketing_page_viewed',
        exclusions: []
    },
    'CJ_AFFILIATE_COOKIE': {
        real_event_name: 'cj_afilliate_cookie_received',
        exclusions: []
    },
    'LIVE_VIEW_PRICING_CLICKED': {
        real_event_name: 'live_view_pricing_clicked',
        exclusions: []
    },
    'LIVE_HEADER_LANG_TOGGLED': {
        real_event_name: 'busuu_homepage:live_header_lang_toggled',
        exclusions: []
    },
    'LIVE_CTA_LANDING_CLICKED': {
        real_event_name: 'live_cta_landing_clicked',
        exclusions: []
    },
    'CANCEL_FREE_TRIAL_CLICKED': {
        real_event_name: 'free_trial_cancelled',
        exclusions: []
    },
    'FREE_TRIAL_STARTED': {
        real_event_name: 'busuu_web:free_trial_started',
        exclusions: ['Optimizely', 'Adjust']
    },
    'COOKIES_OPTED_OUT': {
        real_event_name: 'cookies_opted_out',
        exclusions: []
    },
    'COOKIES_OPTED_IN': {
        real_event_name: 'cookies_opted_in',
        exclusions: []
    },
    'PLAN_SELECTED': {
        real_event_name: 'plan_selected',
        exclusions: []
    },
    'CART_CTA_CLICKED': {
        real_event_name: 'cart_cta_clicked',
        exclusions: []
    },
    'FAQ_SELECTED': {
        real_event_name: 'faq_selected',
        exclusions: []
    },
    'NAVBAR_GROUP_LINK_SELECTED': {
        real_event_name: 'busuu_homepage:navbar_group_link_selected',
        exclusions: []
    }
};
export default TrackingEventsConstants;
