import Utils from 'core-helpers/utils.js';
import ConsoleHelper from 'core-helpers/console-helper.js';
import minBy from 'lodash/minBy';

// Private vars
const filename = 'plans-helper.js';

/**
 * List of country codes that
 * should be using 1 month plan product.
 * @type {Array}
 */
const ONE_MONTH_PLAN_COUNTRIES = ['US', 'RU', 'BR', 'TR'];

const logError = (message, data) => {
    ConsoleHelper.log(filename, message, data);
};

/**
 * Returns whether or not the storage plan
 * has the right properties and types
 * before getting stored in the session.
 * @param  {Object} storagePlan
 * @return {Boolean}
 * @public
 */
const isStoragePlanValid = (storagePlan) => {

    const shallow_check = {
        country: 'type_string',
        uid: 'type_number',
        plan: 'type_object'
    };

    const deep_check = {
        months: 'type_number',
        planID: 'type_number_or_string',
        monthlyPrice: 'type_string',
        totalPrice: 'type_string',
        hasDiscount: 'type_boolean',
        hasFreeTrial: 'type_boolean',
        hasLiveLessons: 'type_boolean',
        tierType: 'type_string',
        vendors: 'type_object'
    };

    if (storagePlan.plan.hasDiscount) {
        deep_check.discountPercent = 'type_number';
        deep_check.monthlyTotalSavings = 'type_string';
        deep_check.totalSavings = 'type_string';
        deep_check.monthlyPriceAfterDiscount = 'type_string';
        deep_check.totalPriceAfterDiscount = 'type_string';
    }

    if (storagePlan.plan.hasFreeTrial) {
        deep_check.freeTrialStrategy = 'type_number';
        deep_check.freeTrialDays = 'type_number';
        deep_check.monthlyPriceFreeTrial = 'type_string';
        deep_check.totalPriceFreeTrial = 'type_string';
    }


    if (storagePlan.plan.hasLiveLessons) {
        deep_check.lessons = 'type_number';
    }

    const shallow_valid = Utils.verifyPropTypes(storagePlan, shallow_check, true);
    const deep_valid = Utils.verifyPropTypes(storagePlan.plan, deep_check, true);

    return Boolean(shallow_valid && deep_valid);
};

/**
 * This function verifies if all the
 * properties values are truthy within a given object.
 * Returns the undefined properties if found.
 * @param {Object}
 * @return {Array}
 * @private
 */
const getUndefinedProperties = (object) => {
    const isDefined = (val) => {
        return Boolean((val !== undefined) && (val !== null));
    };
    return Object.entries(object).reduce((seed, [key, value]) => {
        const pass = Boolean(object.hasOwnProperty(key) && isDefined(value));
        if (!pass) {
            seed.push(key);
        }
        return seed;
    }, []);
};

/**
 * Returns all discount data if a discount has been found
 * on a given plan.
 * @param  {Object} plan
 * @return {Object || null}
 * @private
 */
const getDiscountInfo = (originalPlan) => {

    // Key/value should always be present and '0' if no discount (falsy)
    if (!originalPlan.discount_percent) {
        return null;
    }

    try {
        return {
            hasDiscount: true,
            discountPercent: originalPlan.discount_percent,
            monthlyTotalSavings: originalPlan.monthly.price_saving_human,
            totalSavings: originalPlan.total.price_saving_human,
            monthlyPriceAfterDiscount: originalPlan.monthly.price_discounted_human,
            totalPriceAfterDiscount: originalPlan.total.price_discounted_human
        };
    } catch (e) {
        throw new Error(`Discount data is not properly formatted: ${e}`);
    }
};

/**
 * Returns all discount data if a free trial has been found
 * on a given plan.
 * @param  {Object} plan
 * @return {Object || null}
 * @private
 */
const getFreeTrialInfo = (originalPlan) => {
    if (!isFreeTrialPlan(originalPlan)) {
        return null;
    }

    return {
        hasFreeTrial: true,
        freeTrialStrategy: originalPlan.free_trial_strategy,
        freeTrialDays: originalPlan.free_trial_strategy_days,
        monthlyPriceFreeTrial: originalPlan.monthly.price_human_ft,
        totalPriceFreeTrial: originalPlan.total.price_human_ft
    };
};

/**
 * Returns all Live data if a lessons have been found
 * on a given plan.
 * @param  {Object} plan
 * @return {Object || null}
 * @private
 */
const getLiveLessonsInfo = (originalPlan) => {
    if (!isLiveLessonsPlan(originalPlan)) {
        return null;
    }

    return {
        hasLiveLessons: true,
        lessons: originalPlan.lessons
    };
};

/**
 * Returns a formatted plan Object from the Original Backend plan.
 * This is all the needed information to display each price plan.
 * This is a middleware between the Backend API and the Front-end.
 * It also checks if all values are defined and truthy.
 * @param {Object} originalPlan
 * @return {Object}
 * @public
 */
const getFormattedPlan = (originalPlan) => {

    let formattedPlan = null;
    try {

        formattedPlan = {
            hasDiscount: false,
            hasFreeTrial: false,
            hasLiveLessons: false,
            planID: originalPlan.id,
            months: originalPlan.months,
            monthlyPrice: originalPlan.monthly.price_human,
            totalPrice: originalPlan.total.price_human,
            tierType: originalPlan.tier,
            vendors: originalPlan.vendors
        };

        const discounts = getDiscountInfo(originalPlan);
        const freeTrial = getFreeTrialInfo(originalPlan);
        const liveLessons = getLiveLessonsInfo(originalPlan);

        if (discounts) {
            formattedPlan = Object.assign(formattedPlan, discounts);
        }

        if (freeTrial) {
            formattedPlan = Object.assign(formattedPlan, freeTrial);
        }

        if (liveLessons) {
            formattedPlan = Object.assign(formattedPlan, liveLessons);
        }

        if (discounts || freeTrial || liveLessons) {
            formattedPlan = Utils.deepClone(formattedPlan);
        }

        const undefinedProps = getUndefinedProperties(formattedPlan);

        if (undefinedProps.length) {
            logError('getFormattedPlan() - data invalid: ', undefinedProps.join(', '));
            throw new Error(`${filename} - ${ undefinedProps.join(', ') } prop(s) are missing.`);
        }

    } catch (e) {
        throw new Error(`${filename} - Plan data is incorrect: ${e}`);
    }

    return formattedPlan;
};

/**
 * Returns the cheapest plan from a list of plans
 * @param {Array} plans
 * @return {Object}
 * @public
*/
const getCheapestPlan = (plans) => {
    if (!Utils.isArray(plans)) {
        throw new Error(`[${filename}]: getCheapestPlan() - Array expected.`);
    }
    return minBy(plans, (planItem) => {
        if (!planItem) {
            return null;
        }
        // If discount, return discounted amount
        if (planItem.discount_percent) {
            return Number(planItem.monthly.price_discounted);
        }
        // Otherwise return normal amount
        return Number(planItem.monthly.price);
    });
};

/**
 * Returns the highest discount from a list of plans
 * @param {Array} plans
 * @return {Number}
 * @public
*/
const getHighestDiscount = (plans) => {
    const cheapestPlan = getCheapestPlan(plans);
    return Number(cheapestPlan.discount_percent);
};

/**
 * Finds a given plan within a list of plans
 * based on a duration (in months)
 * @param  {Array} plans
 * @param  {Number} monthDuration
 * @return {Object || null}
 * @public
 */
const findPlanByMonth = (plans, monthDuration) => {
    return plans.find((item) => {
        if (Utils.isObject(item)) {
            return Boolean(item.months === monthDuration);
        }
    }) || null;
};

/**
 * Filters out a given list of plans
 * based on a month duration condition
 * @param  {Array} plans
 * @param  {Number} monthDuration
 * @return {Array} plans
 * @public
 */
const removePlanByMonth = (plans, monthDuration) => {
    const plansCopy = Utils.deepClone(plans);
    const getPlanPosition = (plansCopy, monthDuration) => {
        const index = plansCopy.findIndex((item) => item.months === monthDuration);
        return index !== -1 ? index : null;
    };
    const position = getPlanPosition(plansCopy, monthDuration);
    if (position !== null) {
        plansCopy.splice(position, 1);
    }
    return plansCopy;
};

/**
 * Filters out a given list of plans
 * based on a country condition
 * @param {Array} plans
 * @param {String} country
 * @public
 */
const removePlanByCountry = (plans, country) => {
    const plansCopy = Utils.deepClone(plans);
    const monthToRemove = Boolean(ONE_MONTH_PLAN_COUNTRIES.includes(country)) ? 3 : 1;
    return removePlanByMonth(plansCopy, monthToRemove);
};

/**
 * Indicates whether or not a user is recognised as authenticated
 * within the app. (this can be fully or semi authenticated)
 * It's based on the data the backend is returning when we call the plans endpoint.
 * @param  {Object} plansData
 * @return {Boolean}
 * @public
 */
const isUserAuthenticated = (plansData) => {
    const { can_pay, user_id, country } = plansData.user;
    return Boolean(can_pay && user_id && country);
};

/**
 * Creates a very specific Object structure
 * to be stored in the session (HP)
 * that allows the `/purchase` page to be accessed.
 * This Object is meant to contain all the available
 * data to create a payment afterwards.
 * @param {Object} plansData - Backend endpoint info
 * @param {Object} selectedPlan - Chosen plan info
 * @return {Object}
 * @public
 */
const getValidStoragePlan = (plansData, selectedPlan) => {
    const data = {
        country: plansData.user.country,
        uid: plansData.user.user_id,
        plan: Utils.deepClone(selectedPlan)
    };

    if (!isStoragePlanValid(data)) {
        const message = 'getValidStoragePlan() - Invalid storage plan structure.';
        logError(message, data);
        throw new Error(`${filename} - ${message}`);
    }

    return data;
};

/**
 * Returns an Array with all the plans
 * found within each products.
 * @param {Object} plansData
 * @return {Array}
 */
const getAllProductsPlans = (plansData) => {
    const plans = Utils.deepClone(plansData.plans);
    try {
        return [...plans.premium_plus, ...plans.premium_standard];
    } catch (e) {
        throw new Error(`${filename} - getAllProductsPlans() Data structure incorrect.`);
    }
};

/**
 * Returns if a plan is valid for a free trial
 * @param {Object} plan
 * @returns {Boolean}
 * @public
 */
const isFreeTrialPlan = (plan) => {
    if (plan.hasOwnProperty('free_trial_strategy')) {
        return plan.free_trial_strategy_days > 0;
    }

    return plan.free_trial_days > 0;
};

/**
 * Returns if a plan is has Live lessons included
 * @param {Object} plan
 * @returns {Boolean}
 * @public
 */
const isLiveLessonsPlan = (plan) => Boolean(plan.lessons);

/**
 * Returns the cheapest plan available for a free trial
 * @param {Array} plans
 * @return {Object}
 * @public
*/
const getCheapestFreeTrialPlan = (plans) => {
    if (!Utils.isArray(plans)) {
        throw new Error(`[${filename}]: getCheapestFreeTrialPlan() - Array expected.`);
    }

    return minBy(plans, (planItem) => {
        if (!planItem || !isFreeTrialPlan(planItem)) {
            return null;
        }
        // If discount, return discounted amount
        if (planItem.discount_percent) {
            return Number(planItem.monthly.price_discounted);
        }
        // Otherwise return normal amount
        return Number(planItem.monthly.price);
    });
};

/**
 * Finds a given plan within a list of plans that are eligible for free trial
 * based on a duration (in months)
 * @param  {Array} plans
 * @param  {Number} monthDuration
 * @return {Object || null}
 * @public
 */
const findFreeTrialPlanByMonth = (plans, monthDuration) => {
    return findPlanByMonth(plans.filter(isFreeTrialPlan), monthDuration);
};

export default {
    findPlanByMonth,
    getFormattedPlan,
    getCheapestPlan,
    getHighestDiscount,
    getValidStoragePlan,
    removePlanByMonth,
    removePlanByCountry,
    isUserAuthenticated,
    getAllProductsPlans,
    getCheapestFreeTrialPlan,
    isFreeTrialPlan,
    findFreeTrialPlanByMonth
};
