import _ from 'underscore';
import Utils from 'core-helpers/utils.js';

/**
 * Class definition for the glide navigation
 * that includes a slider between the tabs
 * to define which element is active.
 *
 * Usage: `const instance = new NavGlide(element, options);`
 *
 * Options:
 * - onItemClick(element, event): Function callback when item is clicked
 *
 */
const NavGlideClass = (function(root, doc, undefined) {

    const NAV_DISABLED_CLASSNAME = 'nav-glide--disabled';
    const NAV_RESIZE_CLASSNAME = 'nav-glide--on-resize';
    const NAV_ITEM_CLASSNAME = 'js-navglide-item';
    const NAV_ITEM_ACTIVE_CLASSNAME = 'nav-glide__item--active';
    const NAV_SLIDER_CLASSNAME = 'js-navglide-slider';

    class NavGlideClass {

        /**
         * Initialization
         * @param {Element} navElement
         * @param {Object} options
         * @param {Function} options.onItemClick
         */
        constructor(navElement, options = {}) {

            this.nav = navElement;
            this.options = options;
            this.slider = Utils.getElementByClass(NAV_SLIDER_CLASSNAME, this.nav);
            this.navItems = Utils.getElementsByClass(NAV_ITEM_CLASSNAME, this.nav);
            this.activeItem = this.getActiveItem();

            this.attachItemsHandler();
            this.setActiveItem(this.activeItem);
            this.initUIListeners();
            this.enableNav();

        }

        enableNav() {
            Utils.removeClass(this.nav, NAV_DISABLED_CLASSNAME);
        }

        onItemClickHandler(navItem, event) {
            event.preventDefault();
            this.goToNextElement(navItem);
        }

        attachItemsHandler() {
            const context = this;
            Utils.delegate(context.nav, NAV_ITEM_CLASSNAME, 'click', function(event) {
                context.onItemClickHandler(this, event);
                if (context.options.onItemClick) {
                    context.options.onItemClick(this, event);
                }
            });
        }

        getActiveItem() {
            const item = Utils.getElementByClass(NAV_ITEM_ACTIVE_CLASSNAME, this.nav);
            return item ? item : this.navItems[0];
        }

        /**
         * Defines a new item element as active
         * and updates the slider.
         * @param {Element} navItem
         */
        setActiveItem(navItem) {
            this.activeItem = navItem;
            Utils.addClass(this.activeItem, NAV_ITEM_ACTIVE_CLASSNAME);
            this.updateSlider();
        }

        /**
         * Updates the slider position based on the
         * current active element.
         */
        updateSlider() {

            const posX = this.activeItem.offsetLeft;
            const posY = this.activeItem.offsetTop;
            const width = this.activeItem.offsetWidth;
            const height = this.activeItem.offsetHeight;

            this.slider.style.transform = `translate3d(${posX}px, ${posY}px, 0)`;
            this.slider.style.width = `${width}px`;
            this.slider.style.height = `${height}px`;

        }

        /**
         * Replace active element by a given
         * item element.
         * @param {Element} nextElement
         */
        goToNextElement(nextElement) {
            if (nextElement === this.activeItem) {
                return false;
            }
            Utils.removeClass(this.activeItem, NAV_ITEM_ACTIVE_CLASSNAME);
            this.setActiveItem(nextElement);
        }

        onResizeHandler() {
            Utils.addClass(this.nav, NAV_RESIZE_CLASSNAME);
        }

        /**
         * After resize, we update the slider
         * with his new position.
         */
        postResizeHandler() {
            this.updateSlider();
            Utils.removeClass(this.nav, NAV_RESIZE_CLASSNAME);
        }

        onFontLoadListener() {
            this.updateSlider();
        }

        /**
         * Resize handlers ensure the slider
         * is always at the right position if the viewport
         * dimensions change.
         */
        initUIListeners() {
            root.addEventListener('resize', this.onResizeHandler.bind(this));
            root.addEventListener('resize', _.debounce(this.postResizeHandler, 250).bind(this));
            doc.addEventListener('FONT_LOAD_COMPLETE', this.onFontLoadListener.bind(this));
        }

    }

    return NavGlideClass;

}(window, document));

export default NavGlideClass;
