import Utils from 'core-helpers/utils.js';
import _ from 'underscore';
import PubSub from 'pubsub-js/src/pubsub.js';
import ExceptionService from 'core-services/exceptions/exception-service.js';

const BreakPointDetection = (function(root, doc, undefined) {

    /**
     * Return current breakpoint state
     * @return {Integer}
     * states are:
     *     small:  1
     *     medium: 2
     *     large:  3
     *     above:  4
     * @public
     */
    const getState = () => {
        return currentState;
    };

    /**
     * Return breakpoint state
     * @return {Integer}
     * @private
     */
    const getCurrentState = () => {

        if (!breakpointIndicator) {
            ExceptionService.handle('warning', {
                filename: filename,
                message: 'DOM Breakpoint Indicator is not present on current page.'
            });
            return false;
        }

        const index = parseInt(root.getComputedStyle(breakpointIndicator).getPropertyValue('z-index'), 10);
        return index || null;

    };

    /**
     * Attach a resize event on the window to store the
     * current breakpoint state and fire an event if the state has changed
     * @private
     */
    const onResize = () => {
        const newState = getCurrentState();
        if (currentState !== newState) {
            currentState = newState;
            PubSub.publish('BREAKPOINT_DETECTION__CHANGED', currentState);
        }
    };

    /**
     * Initialization
     * @private
     */
    const init = () => {

        /**
         * On load, store the current state
         */
        currentState = getCurrentState();

        /**
         * Listen for window resize events
         */
        root.addEventListener('resize', _.debounce(onResize, 300));

    };

    /**
     * Private vars
     */
    const filename = 'breakpoint-detection.js';
    const breakpointIndicator = Utils.getById('breakpoint-indicator');
    let currentState = null;

    init(); // start!

    return {
        getState: getState
    };

}(window, document));

export default BreakPointDetection;
