/**
 *
 * This constant file is used to share keys across
 * our Front-end apps (HP, MP and Webapp).
 * This is pretty useful to avoid naming issues
 * between those apps when accessing
 * or storing browser storage keys.
 *
 * Each key has a name, a type and a value associated.
 * Some keys doesn't need any value the goal
 * is just to check if the key is present.
 *
 * 'LS' stands for window.localStorage
 * 'ST' stand for sessionStorage
 *
 */

/**
 * @type {String} key
 * @type {String} type
 * @type {Boolean} mandatoryValue
 * @type {Boolean} clearOnLogout
 * @type {Boolean} clearOnLogin
 * @type {Boolean} clearOnRegister
 */
const BrowserStorageConstant = {
    'PAGE_VIEWED_PREMIUM': {
        key: 'PAGE_VIEWED_PREMIUM',
        type: 'LS',
        mandatoryValue: false,
        clearOnLogout: false,
        clearOnLogin: false,
        clearOnRegister: true
    },
    'PAGE_VIEWED_PURCHASE': {
        key: 'PAGE_VIEWED_PURCHASE',
        type: 'LS',
        mandatoryValue: false,
        clearOnLogout: false,
        clearOnLogin: false,
        clearOnRegister: true
    },
    'DISCOUNT_COUPON': {
        key: 'DISCOUNT_COUPON',
        type: 'LS',
        mandatoryValue: true,
        clearOnLogout: false,
        clearOnLogin: false,
        clearOnRegister: true
    },
    'BUSUU_PROMO_POPUP': {
        key: 'BUSUU_PROMO_POPUP',
        type: 'LS',
        mandatoryValue: true,
        clearOnLogout: false,
        clearOnLogin: false,
        clearOnRegister: true
    },
    'SELECTED_PLAN': {
        key: 'SELECTED_PLAN',
        type: 'ST',
        mandatoryValue: true,
        clearOnLogout: true,
        clearOnLogin: true,
        clearOnRegister: true
    },
    'MARKETING_UTM': {
        key: 'MARKETING_UTM',
        type: 'ST',
        mandatoryValue: true,
        clearOnLogout: false,
        clearOnLogin: false,
        clearOnRegister: false
    },
    'REGISTRATION_ONBOARDING': {
        key: 'REGISTRATION_ONBOARDING',
        type: 'ST',
        mandatoryValue: false,
        clearOnLogout: true,
        clearOnLogin: true,
        clearOnRegister: false
    },
    'POST_PREMIUM_USER_DATA': {
        key: 'POST_PREMIUM_USER_DATA',
        type: 'ST',
        mandatoryValue: true,
        clearOnLogout: true,
        clearOnLogin: true,
        clearOnRegister: true
    },
    'KEYBOARD_SHORTCUT_STATUS': {
        key: 'KEYBOARD_SHORTCUT_STATUS',
        type: 'LS',
        mandatoryValue: true,
        clearOnLogout: false,
        clearOnLogin: false,
        clearOnRegister: true
    },
    'HIGHLIGHT_FEATURE_KEYBOARD_VIEWED': {
        key: 'HIGHLIGHT_FEATURE_KEYBOARD_VIEWED',
        type: 'LS',
        mandatoryValue: false,
        clearOnLogout: false,
        clearOnLogin: false,
        clearOnRegister: true
    },
    'STUDY_PLAN_VIEWED_THIS_SESSION': {
        key: 'STUDY_PLAN_VIEWED_THIS_SESSION',
        type: 'ST',
        mandatoryValue: false,
        clearOnLogout: true,
        clearOnLogin: true,
        clearOnRegister: true
    },
    'STUDY_PLAN_TOTAL_VIEWS': {
        key: 'STUDY_PLAN_TOTAL_VIEWS',
        type: 'LS',
        mandatoryValue: true,
        clearOnLogout: false,
        clearOnLogin: false,
        clearOnRegister: true
    },
    'STUDY_PLAN_ACTIVATION_DATA': {
        key: 'STUDY_PLAN_ACTIVATION_DATA',
        type: 'ST',
        mandatoryValue: true,
        clearOnLogout: false,
        clearOnLogin: false,
        clearOnRegister: true
    },
    'STUDY_PLAN_FORCE_DROPDOWN': {
        key: 'STUDY_PLAN_FORCE_DROPDOWN',
        type: 'ST',
        mandatoryValue: false,
        clearOnLogout: true,
        clearOnLogin: false,
        clearOnRegister: true
    },
    'STUDY_PLAN_GOAL_VIEWED_THIS_SESSION': {
        key: 'STUDY_PLAN_GOAL_VIEWED_THIS_SESSION',
        type: 'ST',
        mandatoryValue: true,
        clearOnLogout: true,
        clearOnLogin: true,
        clearOnRegister: true
    },
    'ECOMMERCE_ORIGIN': {
        key: 'ECOMMERCE_ORIGIN',
        type: 'LS',
        mandatoryValue: true,
        clearOnLogout: false,
        clearOnLogin: false,
        clearOnRegister: true
    },

    /**
     * Used for admin tool to identify which
     * content branch the app is fetching.
     */
    'CONTENT_BRANCH': {
        key: 'CONTENT_BRANCH',
        type: 'LS',
        mandatoryValue: true,
        clearOnLogout: false,
        clearOnLogin: false,
        clearOnRegister: true
    },

    /*
        * When we send a deeplink to a non-premium user for a premium course
        * store upsel course data to use for the paywall
    */
    'COURSES_OVERVIEW_FORCE_UPSELL': {
        key: 'COURSES_OVERVIEW_FORCE_UPSELL',
        type: 'ST',
        mandatoryValue: true,
        clearOnLogout: true,
        clearOnLogin: true,
        clearOnRegister: true
    },
    /**
     * Used for admin tool to show current content
     * and non ready content as well.
     */
    'SHOW_ALL_CONTENT': {
        key: 'SHOW_ALL_CONTENT',
        type: 'LS',
        mandatoryValue: false,
        clearOnLogout: false,
        clearOnLogin: false,
        clearOnRegister: true
    },

    /**
     * Used for admin tool to disable
     * the cache on the content.
     */
    'DISABLE_CONTENT_CACHE': {
        key: 'DISABLE_CONTENT_CACHE',
        type: 'LS',
        mandatoryValue: false,
        clearOnLogout: false,
        clearOnLogin: false,
        clearOnRegister: true
    },

    /**
     * Used to clear data in media player library
     * We clear this as the library stores things like mute, volume etc...
     * and if the controls are disabled, the user ends up with a non-usable
     * player that they just assume is broken.
     */
    'PLYR_STORAGE': {
        key: 'plyr',
        type: 'LS',
        mandatoryValue: false,
        clearOnLogout: true,
        clearOnLogin: true,
        clearOnRegister: true
    },

    /**
     * Tracks the count of the onboarding screens
     * for each activity. See activity-onboarding.service.js
     * file in webapp to see how this is used.
     */
    'ACTIVITY_ONBOARDING_COUNT': {
        key: 'ACTIVITY_ONBOARDING_COUNT',
        type: 'LS',
        mandatoryValue: true,
        clearOnLogout: false,
        clearOnLogin: false,
        clearOnRegister: true
    },

    /**
     * Handles the display of the smart-review banner on the timeline
     * It's dependant on the course language
     * See shared-data.service.js (smartReviewSetStatus) for more info
     */
    'SMART_REVIEW_STATUS': {
        key: 'SMART_REVIEW_STATUS',
        type: 'ST',
        mandatoryValue: true,
        clearOnLogout: true,
        clearOnLogin: true,
        clearOnRegister: true
    },

    /**
     * Count the number of Free exercise a user did in smart-review
     * So we can show the paywall
     */
    'SMART_REVIEW_NB_EXERCISES_DONE': {
        key: 'SMART_REVIEW_NB_EXERCISES_DONE',
        type: 'LS',
        mandatoryValue: true,
        clearOnLogout: false,
        clearOnLogin: false,
        clearOnRegister: true
    },

    /**
     * Object describing the trigger status for referral signposts
     */
    'REFERRAL_TRIGGERED_STATUS': {
        key: 'REFERRAL_TRIGGERED_STATUS',
        type: 'ST',
        mandatoryValue: true,
        clearOnLogout: true,
        clearOnLogin: true,
        clearOnRegister: true
    },

    /**
     * Object describing dismissal state and expiry date to reset to default
     */
    'REFERRAL_DISMISSED_STATUS': {
        key: 'REFERRAL_DISMISSED_STATUS',
        type: 'LS',
        mandatoryValue: true,
        clearOnLogout: false,
        clearOnLogin: false,
        clearOnRegister: true
    },

    /**
     * Saves the unique user referral code
     */
    'REFERRAL_USER_CODE': {
        key: 'REFERRAL_USER_CODE',
        type: 'ST',
        mandatoryValue: true,
        clearOnLogout: true,
        clearOnLogin: true,
        clearOnRegister: false
    },

    /**
     * Whether or not the user has used their free language swap
     */
    'USED_SINGLE_FREE_LANGUAGE_SWITCH': {
        key: 'USED_SINGLE_FREE_LANGUAGE_SWITCH',
        type: 'LS',
        mandatoryValue: true,
        clearOnLogout: false,
        clearOnLogin: false,
        clearOnRegister: true
    },

    /**
     * Whether or not the user has interacted with a Live CTA
     * LIVE_ADD_ON_LESSONS
     */
    'LIVE_ADD_ON_INTENT': {
        key: 'LIVE_ADD_ON_INTENT',
        type: 'ST',
        mandatoryValue: false,
        clearOnLogout: true,
        clearOnLogin: true,
        clearOnRegister: true
    },

    /**
     * When a Live merch item is dismissed,
     * we store a key that has a date value in it.
     * This is used to determine whether it should be
     * shown again based on a certain timeframe.
     */
    'LIVE_MERCHANDISING_DISMISSED': {
        key: 'LIVE_MERCHANDISING_DISMISSED',
        type: 'LS',
        mandatoryValue: true,
        clearOnLogout: true,
        clearOnLogin: false,
        clearOnRegister: false
    },

    /**
     * Set after a successful payment
     * We can use this key on the Webapp
     * to trigger certain actions post-payment
     */
    'POST_PAYMENT_TRIGGER': {
        key: 'POST_PAYMENT_TRIGGER',
        type: 'LS',
        mandatoryValue: true,
        clearOnLogout: false,
        clearOnLogin: false,
        clearOnRegister: true
    },

    /**
     * Sets the live source value
     * to local storage
     */
    'LIVE_SOURCE': {
        key: 'LIVE_SOURCE',
        type: 'LS',
        mandatoryValue: true,
        clearOnLogout: false,
        clearOnLogin: false,
        clearOnRegister: false
    },

    /**
     * Store the state of the live cards
     * to local storage
     */
    'LIVE_CARDS_STATE': {
        key: 'LIVE_CARDS_STATE',
        type: 'LS',
        mandatoryValue: true,
        clearOnLogout: false,
        clearOnLogin: false,
        clearOnRegister: false
    },

    /**
     * Store all the users IDs
     * who have finished the tour.
     */
    'TOUR_FINISHED': {
        key: 'TOUR_FINISHED',
        type: 'LS',
        mandatoryValue: true,
        clearOnLogout: false,
        clearOnLogin: false,
        clearOnRegister: false
    },

    /**
     * Stores captcha token used by QA
     * to bypass captcha verification
     */
    'CAPTCHA_TOKEN': {
        key: 'CAPTCHA_TOKEN',
        type: 'ST',
        mandatoryValue: true,
        clearOnLogout: true,
        clearOnLogin: true,
        clearOnRegister: true
    },

    /**
     * Stores the number of completed lessons
     *
     */
    'COMPLETED_LESSONS': {
        key: 'COMPLETED_LESSONS',
        type: 'LS',
        mandatoryValue: true,
        clearOnLogout: true,
        clearOnLogin: true,
        clearOnRegister: true
    },

    /**
     * Stores if we have to show the incentivise completion modal
     *
     */
    'INCENTIVISE_LESSONS_MODAL': {
        key: 'INCENTIVISE_LESSONS_MODAL',
        type: 'LS',
        mandatoryValue: true,
        clearOnLogout: false,
        clearOnLogin: false,
        clearOnRegister: false
    },

    /**
     * Stores the level the user selected
     *
     */
    'LEVEL_SELECTION': {
        key: 'LEVEL_SELECTION',
        type: 'LS',
        mandatoryValue: true,
        clearOnLogout: true,
        clearOnLogin: true,
        clearOnRegister: true
    }
};

export default BrowserStorageConstant;
