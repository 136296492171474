import Utils from 'core-helpers/utils.js';
import PlansService from 'premium/plans-service.js';
import PlansHelper from 'core-helpers/plans-helper.js';
import PromotionBanner from 'promotion/promotion-banner.js';

const BANNER_ID = 'promotion-banner';

const onGetTieredPlansSuccess = (promotionBanner, response) => {
    const allPlans = PlansHelper.getAllProductsPlans(response);
    const cheapestPlan = PlansHelper.getCheapestPlan(allPlans);

    new PromotionBanner(promotionBanner, cheapestPlan.months);
};

const init = () => {
    const promotionBanner = Utils.getById(BANNER_ID);

    if (promotionBanner) {
        PlansService.getTieredPlans()
            .then(onGetTieredPlansSuccess.bind(null, promotionBanner))
            .catch(() => {});
    }
};

init();
