import Utils from 'core-helpers/utils.js';
import PlansHelper from 'core-helpers/plans-helper.js';

/**
 * Returns the plan duration from a nav item
 * @param {Element} element
 * @return {Number}
 */
const getPlanDuration = (element) => {
    const months = element.getAttribute('data-month-plan');
    return Number(months);
};

/**
 * Returns the relevant cart URL
 * @return {String}
 */
const getCartURL = () => {
    const tieredPlans = Utils.getById('tiered-plans');
    return tieredPlans.getAttribute('data-success');
};

/**
 * @return {Array}
 */
const getNavigationItems = () => {
    const tieredPlansNav = Utils.getById('tiered-plans-nav');
    return Utils.getElementsByClass('js-navglide-item', tieredPlansNav);
};

/**
 * Validate each plan within a tier,
 * getFormattedPlan() will shortcut and throw an error
 * if the plan is not well formatted.
 * @param {Array} tier
 * @return Boolean
 * @private
 */
const validateTierData = (tier) => {
    return tier.every((plan) => Boolean(PlansHelper.getFormattedPlan(plan)));
};

/**
 * Ensure the plans data is correct where
 * each plan within each tier is being checked.
 * @param {Object} plansData
 * @return Boolean
 */
const isPlansStructureCorrect = (plansData) => {
    const casualPlans = plansData.plans.premium_standard;
    const seriousPlans = plansData.plans.premium_plus;

    if (!casualPlans || !seriousPlans) {
        return false;
    }

    const allTiersValid = [casualPlans, seriousPlans].every(validateTierData);
    return Boolean(allTiersValid);

};

/**
 * @param {Array} properties
 * @param {Object} source
 * @param {Object} target
 * @private
 */
const addNewProperties = (properties, source, target) => {
    properties.forEach((prop) => {
        target[prop] = source[prop];
    });
};

/**
 * Returns a ready-to-be-stored plan
 * from a given tiered plan.
 * @param {Object} tieredPlan
 * @return {Object}
 */
const getFormattedPlanBeforeStorage = (plan) => {
    const cleanPlan = {};
    const tieredPlan = Utils.deepClone(plan);
    const requiredProps = ['planID', 'monthlyPrice', 'totalPrice', 'months', 'hasDiscount', 'tierType', 'vendors', 'hasFreeTrial', 'hasLiveLessons'];
    const optionalDiscountProps = ['discountPercent', 'monthlyTotalSavings', 'totalSavings', 'monthlyPriceAfterDiscount', 'totalPriceAfterDiscount'];
    const optionalFreeTrialProps = ['freeTrialStrategy', 'freeTrialDays', 'monthlyPriceFreeTrial', 'totalPriceFreeTrial'];
    const optionalLiveProps = ['lessons'];

    addNewProperties(requiredProps, tieredPlan, cleanPlan);

    if (tieredPlan.hasDiscount) {
        addNewProperties(optionalDiscountProps, tieredPlan, cleanPlan);
    }

    if (tieredPlan.hasFreeTrial) {
        addNewProperties(optionalFreeTrialProps, tieredPlan, cleanPlan);
    }

    if (tieredPlan.hasLiveLessons) {
        addNewProperties(optionalLiveProps, tieredPlan, cleanPlan);
    }

    return cleanPlan;
};

const TieredPlansHelper = {
    getPlanDuration,
    getCartURL,
    getNavigationItems,
    isPlansStructureCorrect,
    getFormattedPlanBeforeStorage
};

export default TieredPlansHelper;
