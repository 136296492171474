
import ConsoleHelper from 'core-helpers/console-helper.js';
import Utils from 'core-helpers/utils.js';

const filename = 'snowplow-script.js';
const SP_SDK_LOCATION = 'https://cdn.busuu.com/busuu-v2.12.0.js';
const SP_ENDPOINT_PROD = 'events.busuu.com';
const SP_ENDPOINT_INTEGRATION = 'events-integration.busuu.com';

const appConfig = {
    homepage: {
        trackerName: 'busuu_homepage_tracker',
        appId: 'busuu_homepage'
    },
    webapp: {
        trackerName: 'busuu_webapp_tracker',
        appId: 'busuu_webapp'
    },
    org: {
        trackerName: 'busuu_mp_tracker',
        appId: 'busuu_mp'
    }
};

/**
 * @return {String}
 * @private
 */
const getEndpoint = () => {
    return Utils.isProduction() ? SP_ENDPOINT_PROD : SP_ENDPOINT_INTEGRATION;
};

/**
 * Snowplow setup
 * @param {String} appType
 * @return {Object}
 * @private
 */
const getSnowplowSetup = (appType) => {
    return {
        namespace: 'newTracker',
        trackerName: appConfig[appType].trackerName,
        endpoint: getEndpoint()
    };
};

/**
 * Snowplow settings
 * @param {String} appType
 * @return {Object}
 * @private
 */
const getSnowplowSettings = (appType) => {
    return {
        forceSecureTracker: true,
        discoverRootDomain: true,
        userFingerprint: true,
        appId: appConfig[appType].appId
    };
};

/**
 * Snowplow setup
 * source: https://github.com/snowplow/snowplow/wiki/1-General-parameters-for-the-Javascript-tracker
 * @param {String} appType 
 */
const loadSnowplow = (appType) => {

    (function(p,l,o,w,i,n,g){if(!p[i]){p.GlobalSnowplowNamespace=p.GlobalSnowplowNamespace||[];
    p.GlobalSnowplowNamespace.push(i);p[i]=function(){(p[i].q=p[i].q||[]).push(arguments)
    };p[i].q=p[i].q||[];n=l.createElement(o);g=l.getElementsByTagName(o)[0];n.async=1;
    n.src=w;g.parentNode.insertBefore(n,g)}}(window,document,'script',SP_SDK_LOCATION,'snowplow'));

    const snowplowSetup = getSnowplowSetup(appType);
    const snowplowSettings = getSnowplowSettings(appType);
    window.snowplow(snowplowSetup.namespace, snowplowSetup.trackerName, snowplowSetup.endpoint, snowplowSettings);

};

/**
 * Their documentation recommend asyncronous integration over syncronous
 * https://github.com/snowplow/snowplow/wiki/integrating-javascript-tags-onto-your-website
 * We initially tried splitting their script so that we could queue events and wait until
 * DOM load before injecting their script. However this caused errors and prevented the
 * custom event tracker from occasionally being fired so we opted to go with their
 * recommended initialisation script.
 * See: https://github.com/snowplow/snowplow/issues/3169
 */
const init = (appType) => {

    if (!appType || !appConfig[appType]) {
        ConsoleHelper.log(filename, 'init() - missing param `appType`, cannot load Snowplow script');
        return false;
    }

    loadSnowplow(appType);

};

export default  {
    init
};
